import React from 'react';
import styled from 'styled-components';

const Faqs = styled.div`
`
const Question = styled.h3`
`
const Answer = styled.div`
`
const P = styled.p``

export const en = <Faqs>
    <Question>What is the fee structure?</Question>
    <Answer>
        <P>Transaction fees are paid in Gas on the Ethereum blockchain and depending on the network or the speed level you choose, the fees might differ. On average the fees should be around 0.001 USD or 0.0005ETH per transaction.<br /></P>
        <P>Later some trading fees might be added depending on the 0x platform. (E.g 0.10% for maker and taker)<br /></P>
        <P>Maker fees are paid when you add liquidity to our order book by placing a limit order below the market price for buy, and above the market price for sell.<br /></P>
        <P>Taker fees are paid when you remove liquidity from our order book by placing any order that is executed against an order on the order book.<br /></P>
    </Answer>
</Faqs>
