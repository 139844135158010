import React from 'react';
import ReactSVG from 'react-svg';
import styled, { withTheme } from 'styled-components';
import { isMobileOnly } from "react-device-detect";

import { Theme } from '../../../themes/commons';

interface Props {
    symbol: string;
    primaryColor?: string;
    isInline?: boolean;
    icon?: string;
    theme: Theme;
}

const IconContainer = styled.div<{ color: string; isInline?: boolean }>`
    align-items: center;
    background-color: ${props => (props.color ? props.color : 'transparent')};
    border-radius: 50%;
    display: ${props => (props.isInline ? 'inline-flex' : 'flex')};
    height: ${isMobileOnly ? '15px' : '26px'};
    justify-content: center;
    width: ${isMobileOnly ? '15px' : '26px'};
    margin: 0 5px 0 0;
`;

const Label = styled.label`
    color: #fff;
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
`;
const Img = styled.img`
    height: ${isMobileOnly ? '15px' : '20px'};
    width: ${isMobileOnly ? '15px' : '20px'};
`
const TokenIconContainer = (props: Props) => {
    const { symbol, primaryColor, theme, icon, ...restProps } = props;
    const fallBack = <Label>{symbol && symbol.toUpperCase()}</Label>;
    const width = isMobileOnly ? '15' : '26';
    let Icon =
        // tslint:disable-next-line:jsx-no-lambda
        icon ? <ReactSVG src={icon as string} fallback={() => fallBack} /> : fallBack;
    Icon = <Img width={width} src={(icon as string)} />
    if ((icon as string).indexOf('wwx.svg') > -1) {
        Icon = <Img width={width} src={require('../../../assets/icons/wwx.svg')} />
    }
    else if ((icon as string).indexOf('wbtc.svg') > -1) {
        Icon = <Img width={width} src={require('../../../assets/icons/wbtc.svg')} />
    }
    else if ((icon as string).indexOf('usdt.svg') > -1) {
        Icon = <Img width={width} src={require('../../../assets/icons/usdt.svg')} />
    }
    return (
        <IconContainer color={primaryColor || theme.componentsTheme.gray} {...restProps}>
            {Icon}
        </IconContainer>
    );
};

const TokenIcon = withTheme(TokenIconContainer);

export { TokenIcon };
