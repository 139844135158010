import React, { HTMLAttributes } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getEthAccount, getWeb3State } from '../../../store/selectors';
import { StoreState } from '../../../util/types';
import { goToMarketErc20 } from '../../../store/router/actions';
import { METAMASK_EXTENSION_URL, NETWORK_NAME } from '../../../common/constants';
import { LocalStorage } from '../../../services/local_storage';
import { initWallet } from '../../../store/actions';
import { themeBreakPoints, themeDimensions } from '../../../themes/commons';

const localStorage = new LocalStorage(window.localStorage);
const I18n = require('i18n-js');
const NotInstalled = 'NotInstalled';
const Locked = 'Locked';
const Error = 'Error';

interface OwnProps extends HTMLAttributes<HTMLSpanElement> {
}

interface StateProps {
    ethAccount: string;
    web3State: string;
}

interface DispatchProps {
    onGoToMarketErc20Page: () => any;
    onConnectWallet: () => any;
}

type Props = StateProps & DispatchProps;

const News = styled.div`
    text-align: center;
    margin: 0;
    color: #1068B4; /* ${props => props.theme.componentsTheme.textColorCommon}; */
    padding: 50px 5% 50px 5%; 
    max-width: ${themeBreakPoints.xxl};
    margin: 0 auto;
`;
const Max = styled.div`
    max-width: ${themeBreakPoints.xxl};
    margin: 0 auto;
`;
const H1 = styled.h1`
    font-size: 36px;
    margin: 0 0 50px 0;
    text-transform: uppercase;
    line-height: 49px;
    letter-spacing: 2px;
    font-weight: 300;
    color: ${props => props.theme.componentsTheme.cardTitleColor};
`;

const P = styled.p`
    color:#546497;
    font-size: 18px;
    letter-spacing: 2px;
    padding: 0 30px 10px 30px;
    margin: 0;
    min-height: 50px;
`;
const Button = styled.button`
    padding: 10px 50px 10px 20px;
    border-radius: 5px;
    background: #1068B4;
    margin: 0 0;
    cursor: pointer;
    border: none;
`;
const Span = styled.span`
    font-size: 14px;
    text-transform: uppercase;
    margin: 0 0 0 20px;
    color: ${props => props.theme.componentsTheme.buttonTextColor};
`;
const How = styled.span`
    color: #1068B4;
`;
const Ul = styled.ul`
    display: flex;
    justify-content: space-between;
    margin: 0;
    color: #1068B4;
    @media (max-width: ${themeBreakPoints.md}) {
        display: block;
    }
`;
const Li = styled.li`
    background: ${props => props.theme.componentsTheme.cardBackgroundColor};
    box-shadow: ${props => props.theme.componentsTheme.boxShadow};
    width: 300px;
    height: 300px;
    margin: 0 15px 0 15px;
    border-radius: ${themeDimensions.borderRadius};
    @media (max-width: ${themeBreakPoints.md}) {
        width: 100%;
        margin: 0 0 20px 0;
    }
    @media (min-width: ${themeBreakPoints.lg}) {
        width: 30%;
    }
    transition: transform .1s;
    &:hover {
        transform: scale(1.02);
    }
`;
const Number = styled.p`
    opacity: 0.5;
    font-size: 150px;
    margin: 5px 0;
`;

class App extends React.Component<Props> {

    public onConnect = (web3State: string) => {
        if (web3State === I18n.t('getChromeExtension')) {
            const win = window.open(METAMASK_EXTENSION_URL, '_blank');
            if (win) {
                win.focus();
            }
        } else if (web3State === I18n.t('connectYourWallet')) {
            this.props.onConnectWallet();
            localStorage.saveWalletConnected(true);
        } else if (web3State === I18n.t('trade')) {
            this.props.onGoToMarketErc20Page();
        }
    };

    public render = () => {
        const { ethAccount, web3State } = this.props;
        // const { ethereum } = window;
        let notInstalled: string = '';
        let connectYourWallet: string = '...';
        if (web3State === NotInstalled) {
            notInstalled = I18n.t('getChromeExtension');
        } else if (web3State === Locked) {
            connectYourWallet = I18n.t('connectYourWallet');
            notInstalled = '';
        } else if (web3State === Error) {
            connectYourWallet = I18n.t('wrongNetWork', { NETWORK_NAME });
            notInstalled = '';
        } else if (ethAccount) {
            connectYourWallet = '';
            notInstalled = '';
        }
        return (
            <News>
                <H1><How>{I18n.t('howTo')}</How><span>{I18n.t('getStarted')}</span></H1>
                <Ul>
                    <Li className={notInstalled ? '' : 'li_active'}>
                        <Number className={notInstalled ? 'opp' : ''}>1</Number>
                        <P>{I18n.t('installMetamask')}</P>
                        {notInstalled && <Button onClick={() => this.onConnect(notInstalled)}>
                            <Span>{I18n.t('getExtension')}</Span>
                        </Button>}
                    </Li>
                    <Li className={connectYourWallet ? '' : 'li_active'}>
                        <Number className={connectYourWallet ? 'opp' : ''}>2</Number>
                        <P>{I18n.t('connectMetamaskWallet')}</P>
                        {!notInstalled && connectYourWallet &&
                        <Button onClick={() => this.onConnect(connectYourWallet)}>
                            <Span>{I18n.t('connect')}</Span>
                        </Button>}
                    </Li>
                    <Li className={connectYourWallet ? '' : 'li_active'}>
                        <Number className={connectYourWallet ? 'opp' : ''}>3</Number>
                        <P>{I18n.t('startTrading')}</P>
                        {<Button disabled={connectYourWallet !== ''} className={connectYourWallet ? 'disabled' : ''}
                                 onClick={() => this.onConnect(I18n.t('trade'))}>
                            <Span>{I18n.t('trade')}</Span>
                        </Button>}
                    </Li>
                </Ul>
            </News>
        );
    };
}

const mapStateToProps = (state: StoreState): StateProps => {
    return {
        ethAccount: getEthAccount(state),
        web3State: getWeb3State(state),
    };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
    return {
        onGoToMarketErc20Page: () => dispatch(goToMarketErc20()),
        onConnectWallet: () => dispatch(initWallet()),
    };
};

const GetStated = connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);

export { GetStated };
