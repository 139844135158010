import React from 'react';
import BuySellTabs from '../marketplace/buy_sell_tabs';
import { BuySellContainer } from '../marketplace/buy_sell';
import { OrderBookTableContainer } from '../marketplace/order_book';
import { OrderHistoryContainer } from '../marketplace/order_history_tab';
import { TradeHistoryContainer } from '../marketplace/trade_history';
import { WalletBalanceContainer } from '../marketplace/wallet_balance';
import Chart from '../marketplace/chart';
import HiLoPrice from '../marketplace/hilo_price';
import { MarketsPair } from '../common/markets_pairs';
import HiLoPriceMb from '../marketplace/hilo_price_mb';

import styled from 'styled-components';
import { Card } from '../../common/card';
import { themeBreakPoints, themeDimensions } from '../../../../src/themes/commons';
import { isMobileOnly } from 'react-device-detect';


const I18n = require('i18n-js');
const ColumnNarrow = styled.div`
    max-width: 100%;
    width: 100%;
    margin: 0 0 10px 0;
    @media (min-width: ${themeBreakPoints.md}) {
        width: 260px;
        float: left;
        margin: 5px;
    }
    @media (min-width: ${themeBreakPoints.xxl}) {
        width: 360px;
        float: left;
        margin: 0 5px 20px 5px;
    }
    @media (max-width: ${themeBreakPoints.md}) {
        width: 100%;
        margin: 10px 0;
    }
    margin-top: 0 !important;
`;

const Column = styled.div`
    max-width: 50%;
    width: 50%;
    margin: 0;
    &:first-child {
        border-right: 1px solid ${props => props.theme.componentsTheme.cardBorderColor};
        padding: 10px 0 0 0;
    }
`;

const Content = styled.div`
    margin: 'initial';
    min-height: 800px;
`;
const ColumnWide = styled.div`
    max-width: 100%;
    width: 100%;
    margin: 0 0 10px 0;
    @media (min-width: ${themeBreakPoints.md}) {
        float: left;
        width: calc(100% - 540px);
        margin: 10px 0;
    }
    @media (max-width: ${themeBreakPoints.lg}) {
        float: left;
        width: calc(100% - 280px);
        margin: 10px 0;
    }
    @media (min-width: ${themeBreakPoints.xxl}) {
        float: left;
        width: calc(100% - 740px);
        margin: 10px 0;
    }
    @media (max-width: ${themeBreakPoints.md}) {
        width: 100%;
        margin: 10px 0;
    }
    margin-top: 0 !important;
`;
const ColumnWideMb = styled(ColumnWide)`
    float: none !important;
    clear: both;
    background: ${props => props.theme.componentsTheme.cardBackgroundColor};
    display: flex;
    margin: 10px 0 !important;
    border: 1px solid ${props => props.theme.componentsTheme.cardBorderColor};
    min-height: 100px;
`;
const ColumnRight = styled.div`
    max-width: 100%;
    width: 100%;
    margin: 10px 0;
    @media (max-width: ${themeBreakPoints.lg}) {
        width: 100%;
        clear:both;
    }
    @media (min-width: ${themeBreakPoints.lg}) {
        width: 260px;
        float: left;
        margin: 5px;
    }
    @media (min-width: ${themeBreakPoints.xxl}) {
        width: 360px;
        float: left;
        margin: 5px;
    }
    margin-top: 0 !important;
`;
// const MarketsDropdownHeader = styled<any>(MarketsDropdownContainer)`
//     align-items: center;
//     display: flex;
//     background: ${props => props.theme.componentsTheme.cardBackgroundColor};;
//     margin: 0 0 10px 0;
//     padding: 10px;
//     border: 1px solid ${props => props.theme.componentsTheme.dropdownBorderColor};
// `;

const ColumnWides = styled.div`
    margin: 0;
    clear: both;
    @media (max-width: ${themeBreakPoints.md}) {
        margin: 0;
    }
`;
const TabsContainer = styled.div`
    align-items: center;
    display: flex;
    background-color:#F8F9FB;
    position:fixed;
    bottom:0px;
    z-index: 999;
    width:100%;
    justify-content: center;
`;

const TabButton = styled.div<{ isSelected: boolean; showBalanceTab: boolean }>`
    align-items: center;
    background-color: ${props =>
    props.isSelected ? props.theme.componentsTheme.inactiveTabBackgroundColor : 'transparent'};
    border-bottom-color: ${props => (props.isSelected ? '#3FD3B8' : 'transparent')};
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-right-color: ${props => (props.isSelected ? props.theme.componentsTheme.cardBorderColor : 'transparent')};
    border-right-style: solid;
    border-right-width: 1px;
    cursor: ${props => (props.isSelected ? 'default' : 'pointer')};
    display: flex;
    height: 47px;
    justify-content: center;
    font-size:14px;
    padding: 10px;
    width:${props => (props.showBalanceTab === true ? '33%' : '50%')};;
    /* border-bottom: ${props => props.isSelected ? '2px' : '0px'} solid #3CB34F; */
    &:first-child {
        border-top-left-radius: ${themeDimensions.borderRadius};
    }

    &:last-child {
        border-left-color: ${props => (props.isSelected ? props.theme.componentsTheme.cardBorderColor : 'transparent')};
        border-left-style: solid;
        border-left-width: 1px;
        border-right: none;
        border-top-right-radius: ${themeDimensions.borderRadius};
    }
    color: ${props => (props.isSelected ? props.theme.componentsTheme.textColorCommon : props.theme.componentsTheme.modalSearchFieldTextColor)};
`;

const Img = styled.img`
width:20px;
margin-right:5px;
background-color: white;
`;

const OrderbookCard = styled(Card)`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 100%;
    > div:first-child {
        flex-grow: 0;
        flex-shrink: 0;
    }

    > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: hidden;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
    }
`;

enum TabType {
    Trade = 'Trade',
    Chart = 'Chart',
    Balance = 'Balance'
}

interface State {
    tab: TabType;
    tabType: TabType;
}

class Marketplace extends React.Component<State> {
    public state = {
        tab: TabType.Trade,
        tabType: TabType.Trade,
    };

    public changeTab = (tab: TabType) => () => this.setState({ tab, tabType: tab });

    public render = () => {
        const { infinitowallet } = window;
        const showBalanceTab = infinitowallet ? true : false;
        let tabContent: React.ReactNode = (
            <TabsContainer>
                <TabButton
                    isSelected={this.state.tab === TabType.Trade}
                    showBalanceTab={showBalanceTab}
                    onClick={this.changeTab(TabType.Trade)}
                >
                    <Img src={require('../../../assets/icons/trade.svg')} />
                    {I18n.t('Trade')}
                </TabButton>
                <TabButton
                    isSelected={this.state.tab === TabType.Chart}
                    showBalanceTab={showBalanceTab}
                    onClick={this.changeTab(TabType.Chart)}
                >
                    <Img src={require('../../../assets/icons/noun_chart.svg')} />
                    {I18n.t('Chart')}
                </TabButton>
                {showBalanceTab && <TabButton
                    isSelected={this.state.tab === TabType.Balance}
                    showBalanceTab={showBalanceTab}
                    onClick={this.changeTab(TabType.Balance)}
                >
                    <Img src={require('../../../assets/icons/Wallet_trade.svg')} />
                    {I18n.t('Balance')}
                </TabButton>}
            </TabsContainer>
        );
        let content: React.ReactNode;

        if (isMobileOnly) {
            content = <>
                {
                    <Content>
                        {tabContent}
                        {this.state.tabType === TabType.Trade && (
                            <>
                                <ColumnWides>
                                    <WalletBalanceContainer chide={true} />
                                </ColumnWides>
                                <HiLoPriceMb />
                                <BuySellContainer />
                                <OrderBookTableContainer />
                            </>
                        )}
                        {this.state.tabType === TabType.Chart && (
                            <>
                                <ColumnWide>
                                    <Chart />
                                    <TradeHistoryContainer />
                                </ColumnWide>
                            </>
                        )}
                        {this.state.tabType === TabType.Balance && (
                            <>
                                <ColumnWides>
                                    <WalletBalanceContainer />
                                </ColumnWides>
                                <ColumnWides>
                                    <OrderHistoryContainer />
                                </ColumnWides>
                            </>
                        )}
                        <>
                            <div style={{ height: '40px', clear: 'both' }}></div>
                        </>
                    </Content>
                }
            </>;
        } else {
            content = <>
                {
                    <Content>
                        <ColumnWides>
                            <HiLoPrice />
                        </ColumnWides>
                        <ColumnNarrow>
                            {/* <MarketsDropdownHeader shouldCloseDropdownBodyOnClick={false} /> */}
                            <WalletBalanceContainer />
                            <OrderbookCard minHeightBody={'600px'} heightBody={'600px'} title={I18n.t('orderBook')}>
                                <OrderBookTableContainer /></OrderbookCard>
                        </ColumnNarrow>
                        <ColumnWide>
                            <Chart />
                            <BuySellTabs />
                        </ColumnWide>
                        <ColumnRight>
                            <MarketsPair />
                            <TradeHistoryContainer />
                        </ColumnRight>
                        {/* <CheckMetamaskStateModalContainer /> */}
                        <ColumnWides>
                            <OrderHistoryContainer />
                        </ColumnWides>
                        {/* <CheckMetamaskStateModalContainer /> */}
                    </Content>
                }
            </>;
        }
        return (
            content
        );
    };
}

export { Marketplace };
