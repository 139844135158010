import React, { HTMLAttributes } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
// import { assetDataUtils } from '0x.js';
import { UI_DECIMALS_DISPLAYED_PRICE_ETH } from '../../../common/constants';
import { marketFilters } from '../../../common/markets';
import {
    changeMarket,
    get24hVolumeAction,
    getExchangeChartAction,
    goToHome,
    goToMarketErc20,
} from '../../../store/actions';
import {
    getBaseToken,
    getCurrencyPair,
    getCurrentRoutePath,
    getLanguage,
    getMarkets,
    getQuoteToken,
    getVolume,
} from '../../../store/selectors';
import { themeBreakPoints, themeDimensions } from '../../../themes/commons';
import { getKnownTokens } from '../../../util/known_tokens';
import { filterMarketsByString, filterMarketsByTokenSymbol } from '../../../util/markets';
import { CurrencyPair, Filter, Market, StoreState, Token, Volume } from '../../../util/types';
// import { CardBase } from '../../common/card_base';
import { Dropdown, DropdownPositions } from '../../common/dropdown';
import { ChevronDownIcon } from '../../common/icons/chevron_down_icon';
import { MagnifierIcon } from '../../common/icons/magnifier_icon';
import { TokenIcon } from '../../common/icons/token_icon';
import { CustomTDFirst, Table, TBody, THead, TR } from '../../common/table';
import { BigNumber } from '@0x/utils';
// import { tokenAmountInUnits } from '../../../util/tokens';
import { isMobile } from 'react-device-detect';
import { LoadingWrapper } from '../../common/loading';
import { assetDataUtils } from '@0x/order-utils';
import { fixBigNumber } from '../../../util/number_utils';

const I18n = require('i18n-js');
const config = require('../../../config.json');

interface PropsDivElement extends HTMLAttributes<HTMLDivElement> {
}

interface DispatchProps {
    changeMarket: (currencyPair: CurrencyPair) => any;
    goToHome: () => any;
    goToMarketErc20: () => any;
    get24hVolume: (base: string, quote: string) => any;
    getExchangeChart: (pair: string, time: string, page: string) => any;
}

interface PropsToken {
    baseToken: Token | null;
    currencyPair: CurrencyPair;
    markets: Market[] | null;
    quoteToken: Token | null;
    path: string;
    language: string;
    volume: Volume | null,

}

type Props = PropsDivElement & PropsToken & DispatchProps;

interface State {
    selectedFilter: Filter;
    search: string;
    isUserOnDropdown: boolean;
}

interface TokenFiltersTabProps {
    active: boolean;
    onClick: number;
}

interface MarketRowProps {
    active: boolean;
}

const rowHeight = '48px';
const widthScreen = isMobile && window ? `${window.innerWidth}px` : '430px';

const MarketsDropdownWrapper = styled(Dropdown)``;

const MarketsDropdownHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    height: ${themeDimensions.toolbarHeight};
    min-width: 60px;
    padding 0 10px;
    @media (max-width: ${themeBreakPoints.md}) {
        margin: 0;
        flex-direction: column;
        padding: 0
    }

`;

const MarketsDropdownHeaderText = styled.span`
    color: ${props => props.theme.componentsTheme.textColorCommon};
    font-size: ${isMobile ? '10px' : '14px'};
    font-weight: 600;
    line-height: 26px;
    margin-right: 10px;
    @media (max-width: ${themeBreakPoints.md}) {
        margin: 0;
    }
`;

const MarketsDropdownBody = styled.div`
    box-shadow: ${props => props.theme.componentsTheme.boxShadow};
    max-height: 100%;
    max-width: 100%;
    width: ${widthScreen};
    text-transform: uppercase;
    background: ${props => props.theme.componentsTheme.cardBackgroundColor};
    position: ${isMobile ? 'fixed' : 'initial'};
    top: ${isMobile ? '75px' : 'initial'};
    left: ${isMobile ? '0' : 'initial'};
    @media (max-width: ${themeBreakPoints.md}) {
        position: ${isMobile ? 'fixed' : 'relative'};
        left: ${isMobile ? '0' : '-61px'};
    }
`;

const MarketsFilters = styled.div`
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.componentsTheme.dropdownBorderColor};
    display: flex;
    justify-content: space-between;
    min-height: ${rowHeight};
    padding: 8px 8px 8px ${themeDimensions.horizontalPadding};
`;

const MarketsFiltersLabel = styled.h2`
    color: ${props => props.theme.componentsTheme.notificationIconColor};
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin: 0 auto 0 0;
    white-space: pre;
`;

const TokenFiltersTabs = styled.div`
    align-items: center;
    display: flex;
    margin-right: 10px;
`;

const TokenFiltersTab = styled.span<TokenFiltersTabProps>`
    color: ${props =>
    props.active ? props.theme.componentsTheme.textColorCommon : props.theme.componentsTheme.lightGray};
    cursor: pointer;
    font-size:${isMobile ? '12px' : '12px'} ;
    font-weight: bolder;
    line-height: 1.2;
    user-select: none;
    padding: 5px;
    background: #F8F9FB;
    border-radius: 5px;
    margin: 0 5px;
    &:last-child:after {
        display: none;
    }
    background: ${props => props.active ? '#3FD3B8' : '#f1f2f5'};
    white-space: pre;
`;

const searchFieldHeight = '32px';
const searchFieldWidth = '142px';

const SearchWrapper = styled.div`
    height: ${searchFieldHeight};
    position: relative;
    width: ${searchFieldWidth};
`;

const SearchField = styled.input`
    background: ${props => props.theme.componentsTheme.marketsSearchFieldBackgroundColor};
    border-radius: ${themeDimensions.borderRadius};
    border: 1px solid ${props => props.theme.componentsTheme.marketsSearchFieldBorderColor};
    color: ${props => props.theme.componentsTheme.marketsSearchFieldTextColor};
    font-size: 13px;
    height: ${searchFieldHeight};
    left: 0;
    outline: none;
    padding: 0 15px 0 30px;
    position: absolute;
    top: 0;
    width: ${searchFieldWidth};
    z-index: 1;

    &:focus {
        border-color: ${props => props.theme.componentsTheme.marketsSearchFieldBorderColor};
    }
`;

const MagnifierIconWrapper = styled.div`
    line-height: 30px;
    height: 100%;
    left: 11px;
    position: absolute;
    top: 0;
    width: 14px;
    z-index: 12;
`;

const TableWrapper = styled.div`
    max-height: 420px;
    overflow: auto;
    position: relative;
`;

const verticalCellPadding = `
    padding-bottom: 10px;
    padding-top: 10px;
`;

// const tableHeaderFontWeight = `
//     font-weight: 700;
// `;

const TRStyled = styled(TR) <MarketRowProps>`
    background-color: ${props => (props.active ? props.theme.componentsTheme.rowActive : 'transparent')};
    cursor: ${props => (props.active ? 'default' : 'pointer')};

    &:hover {
        background-color: ${props => props.theme.componentsTheme.rowActive};
    }

    &:last-child > td {
        border-bottom-left-radius: ${themeDimensions.borderRadius};
        border-bottom-right-radius: ${themeDimensions.borderRadius};
        border-bottom: none;
    }
    border-bottom: 1px solid ${props => props.theme.componentsTheme.tableBorderColor};

    &:nth-child(odd) {background: ${props => props.theme.componentsTheme.background};}
    &:nth-child(even) {background: transparent}
`;

// Has a special left-padding: needs a specific selector to override the theme
// const THFirstStyled = styled(THFirst)`
//     ${verticalCellPadding}
//     ${tableHeaderFontWeight}

//     &, &:last-child {
//         padding-left: 21.6px;
//     }
// `;

// const THLastStyled = styled(THLast)`
//     ${verticalCellPadding};
//     ${tableHeaderFontWeight}
// `;

const CustomTDFirstStyled = styled(CustomTDFirst)`
    ${verticalCellPadding};
`;

// const CustomTDLastStyled = styled(CustomTDLast)`
//     ${verticalCellPadding};
// `;

const TokenIconAndLabel = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
`;

const TokenLabel = styled.div`
    color: ${props => props.theme.componentsTheme.textColorCommon};
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 0 12px;
`;
// const Trade = styled.div`
//     @media (max-width: ${themeBreakPoints.md}) {
//         margin: 0;
//         flex-direction: column;
//         text-transform: initial;
//     }
// `
// const SelectAMarket = styled.span`
//     margin: 0 10px 0 0;
//     color: ${props => props.theme.componentsTheme.textColorCommon};
//     @media (max-width: ${themeBreakPoints.md}) {
//         display: none;
//         margin: 0;
//     }
//     position: relative;
//     top: -2px;
// `
const TradeText = styled.span`
    text-transform: uppercase;
    color: ${props => props.theme.componentsTheme.textColorCommon};
    margin: 0 10px 0 0;
    @media (max-width: ${themeBreakPoints.md}) {
        margin: 0 ;
        text-transform: initial;
        
    }
`;
const TD = styled.td`
    text-align: center;
    padding: 14px 0;
    color: ${props => props.theme.componentsTheme.textColorCommon};
    min-width: 30px;
`;
const ChevronDownIcons = styled.span`
    width: 20px;
    @media (max-width: ${themeBreakPoints.md}) {
        display: none;
    }
    svg{
        path{
          fill: ${props => props.theme.componentsTheme.dropdownTextColor};
        }
    }
`;
const TokenType = styled.span`
    font-size: 10px;
    text-transform: capitalize;
    color: #aaa;
`;

class MarketsDropdown extends React.Component<Props, State> {
    public readonly state: State = {
        selectedFilter: marketFilters[0],
        search: '',
        isUserOnDropdown: false,
    };

    private readonly _dropdown = React.createRef<Dropdown>();

    public render = () => {
        const { currencyPair, baseToken, path, ...restProps } = this.props;
        const market = path === '/market' ? true : false;
        const header = (
            <MarketsDropdownHeader>
                {/* <Trade>
                    <Img src={require('../../../assets/icons/stock-line.png')} />
                </Trade> */}
                {!market && <TradeText>{I18n.t('market')}</TradeText>}
                {/* {home && <SelectAMarket>{I18n.t('SelectAMarket')}</SelectAMarket>} */}
                {market && <MarketsDropdownHeaderText>
                    {/* {baseToken ? (
                        <DropdownTokenIcon
                            symbol={baseToken.symbol}
                            primaryColor={baseToken.primaryColor}
                            isInline={true}
                            icon={baseToken.icon}
                        />
                    ) : null} */}
                    {currencyPair.base.toUpperCase()}/{currencyPair.quote.toUpperCase()}
                </MarketsDropdownHeaderText>}
                <ChevronDownIcons>
                    <ChevronDownIcon />
                </ChevronDownIcons>
            </MarketsDropdownHeader>
        );

        const body = (
            <MarketsDropdownBody>
                <MarketsFilters onMouseOver={this._setUserOnDropdown} onMouseOut={this._removeUserOnDropdown}>
                    <MarketsFiltersLabel>{I18n.t('market')}</MarketsFiltersLabel>
                    {this._getTokensFilterTabs()}
                    {this._getSearchField()}
                </MarketsFilters>
                <TableWrapper>{this._getMarkets()}</TableWrapper>
            </MarketsDropdownBody>
        );
        return (
            <MarketsDropdownWrapper
                body={body}
                header={header}
                ref={this._dropdown}
                shouldCloseDropdownOnClickOutside={!this.state.isUserOnDropdown}
                {...restProps}
                horizontalPosition={DropdownPositions.Left}
            />
        );
    };

    private readonly _setUserOnDropdown = () => {
        this.setState({ isUserOnDropdown: true });
    };

    private readonly _removeUserOnDropdown = () => {
        this.setState({ isUserOnDropdown: false });
    };

    private readonly _getTokensFilterTabs = () => {
        return (
            <TokenFiltersTabs>
                {marketFilters.map((filter: Filter, index) => {
                    return (
                        <TokenFiltersTab
                            active={filter === this.state.selectedFilter}
                            key={index}
                            onClick={this._setTokensFilterTab.bind(this, filter)}
                        >
                            {filter.text}
                        </TokenFiltersTab>
                    );
                })}
            </TokenFiltersTabs>
        );
    };

    private readonly _setTokensFilterTab: any = (filter: Filter) => {
        this.setState({ selectedFilter: filter });
    };

    private readonly _getSearchField = () => {
        return (
            <SearchWrapper>
                <MagnifierIconWrapper>{MagnifierIcon()}</MagnifierIconWrapper>
                <SearchField onChange={this._handleChange} value={this.state.search} />
            </SearchWrapper>
        );
    };

    private readonly _handleChange = (e: any) => {
        const search = e.currentTarget.value;

        this.setState({
            search,
        });
    };
    public getTypeByToken = (token: string): string => {
        const { tokens } = config;
        for (let i = 0; i < tokens.length; i++) {
            if (tokens[i] && tokens[i]['symbol'].toLowerCase() === token.toLowerCase())
                return tokens[i]['type'];
        }
        return '';
    };

    private readonly _getMarkets = () => {
        const { baseToken, currencyPair, markets, volume } = this.props;
        const { search, selectedFilter } = this.state;
        if (!baseToken || !markets) {
            return <LoadingWrapper />;
        }

        const filteredMarkets =
            selectedFilter == null || selectedFilter.value === null
                ? markets
                : filterMarketsByTokenSymbol(markets, selectedFilter.value);
        const searchedMarkets = filterMarketsByString(filteredMarkets, search);

        return (
            <Table>
                <THead>
                    <TR>
                        {/* <THFirstStyled styles={{ textAlign: 'left' }}>{I18n.t('market')}</THFirstStyled>
                        <THLastStyled styles={{ textAlign: 'center' }}>{I18n.t('price')}</THLastStyled> */}
                    </TR>
                </THead>
                <TBody>
                    {searchedMarkets.map((market, index) => {
                        const isActive =
                            market.currencyPair.base === currencyPair.base &&
                            market.currencyPair.quote === currencyPair.quote;
                        const setSelectedMarket = () => this._setSelectedMarket(market.currencyPair);

                        const token = getKnownTokens().getTokenBySymbol(market.currencyPair.base);
                        const baseSymbol = market.currencyPair.base.toUpperCase();
                        const quoteSymbol = market.currencyPair.quote.toUpperCase();
                        const key = baseSymbol + '_' + quoteSymbol;
                        const data = volume ? volume[key.toLowerCase()] : null;
                        // const change24h = data && data.change24h.toString() ? data.change24h.toFixed(2).toString() : '0';
                        // const decimals = token ? token.decimals : 18;
                        // const displayDecimals = token ? token.displayDecimals : 2;
                        // const _volume = data && data.volume ? data.volume : 0;
                        // const amount = tokenAmountInUnits(new BigNumber(_volume), decimals, displayDecimals);
                        const endPrice = data && data.endPrice ? data.endPrice : market.price;
                        let _endPrice = endPrice ? fixBigNumber(+new BigNumber(endPrice).toFixed(UI_DECIMALS_DISPLAYED_PRICE_ETH)) : '-';
                        if (endPrice)
                            _endPrice = +_endPrice === 0 ? fixBigNumber(new BigNumber(endPrice).toString()) : fixBigNumber(new BigNumber(endPrice).toFixed(UI_DECIMALS_DISPLAYED_PRICE_ETH));

                        return (
                            <TRStyled active={isActive} key={index} onClick={setSelectedMarket}>
                                <CustomTDFirstStyled styles={{ textAlign: 'left', borderBottom: true }}>
                                    <TokenIconAndLabel>
                                        <TokenIcon
                                            symbol={token.symbol}
                                            primaryColor={token.primaryColor}
                                            icon={token.icon}
                                        />
                                        <TokenLabel>
                                            {baseSymbol}/{quoteSymbol} <br />
                                            <TokenType>{this.getTypeByToken(baseSymbol)}</TokenType>
                                        </TokenLabel>
                                    </TokenIconAndLabel>

                                </CustomTDFirstStyled>
                                <TD>{_endPrice}</TD>
                                {/* <CustomTDLastStyled styles={{ textAlign: 'center', borderBottom: true, tabular: true }}>
                                    {this._getPrice(market)}
                                </CustomTDLastStyled> */}
                            </TRStyled>
                        );
                    })}
                </TBody>
            </Table>
        );
    };

    private readonly _setSelectedMarket: any = (currencyPair: CurrencyPair) => {
        const { baseToken, quoteToken, get24hVolume } = this.props;
        this.props.changeMarket(currencyPair);
        this.props.goToMarketErc20();
        if (this._dropdown.current) {
            this._dropdown.current.closeDropdown();
        }
        if (baseToken && quoteToken) {
            const baseAssetData = assetDataUtils.encodeERC20AssetData(baseToken.address);
            const quoteAssetData = assetDataUtils.encodeERC20AssetData(quoteToken.address);
            get24hVolume(baseAssetData, quoteAssetData);
            // const pair = baseToken.symbol.toLocaleLowerCase() + '_' + quoteToken.symbol.toLocaleLowerCase();
            // getExchangeChart(pair, '6h', '20');
        }
    };

    private readonly _getPrice: any = (market: Market) => {
        if (market.price) {
            return +market.price.toFixed(UI_DECIMALS_DISPLAYED_PRICE_ETH);
        }

        return '-';
    };
}

const mapStateToProps = (state: StoreState): PropsToken => {
    return {
        baseToken: getBaseToken(state),
        currencyPair: getCurrencyPair(state),
        markets: getMarkets(state),
        quoteToken: getQuoteToken(state),
        path: getCurrentRoutePath(state),
        language: getLanguage(state),
        volume: getVolume(state),
    };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
    return {
        changeMarket: (currencyPair: CurrencyPair) => dispatch(changeMarket(currencyPair)),
        goToHome: () => dispatch(goToHome()),
        goToMarketErc20: () => dispatch(goToMarketErc20()),
        get24hVolume: (base: string = '', quote: string = '') => dispatch(get24hVolumeAction(base, quote)),
        getExchangeChart: (pair: string, time: string, page: string) => dispatch(getExchangeChartAction(pair, time, page)),
    };
};

const MarketsDropdownContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MarketsDropdown);

export { MarketsDropdown, MarketsDropdownContainer };
