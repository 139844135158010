import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div``
const Div = styled.div``
const Head = styled.h3``
const Bdy = styled.div``

export const jp = <Wrap>
    <Div>
        <Head>クッキーとは何ですか？</Head>
        <Bdy>
        Cookieは、少量のデータを含むテキストファイルであり、Webサイトにアクセスするとブラウザにダウンロードされます。 Cookieは、CookieをダウンロードしたWebサイトで認識され、これにより、Webサイトが閲覧デバイスが以前にそれらを訪問したことがあるかどうかを確認できます。</Bdy>
    </Div>
    <Div>
        <Head>機能的なクッキー</Head>
        <Bdy>
        機能的なCookieは、当社のサービスを提供するために不可欠です。 これらは、当社のWebサイトでの設定を記憶し、強化されたパーソナライズされたエクスペリエンスを提供するために使用されます。 これらのCookieによって収集される情報は通常匿名化されているため、個人を特定することはできません。 機能Cookieは、インターネットの使用状況を追跡したり、広告の販売に使用できる情報を収集したりしませんが、広告コンテンツの提供には役立ちます。 これらのCookieは通常、セッションCookieであり、閲覧セッションを閉じると期限切れになります。</Bdy>
    </Div>
    <Div>
        <Head>広告クッキー</Head>
        <Bdy>
        広告Cookieは、ユーザーがWebサイトにアクセスしたことを記憶し、そのデータを使用して、ユーザーの興味に合わせた広告を提供します。 また、広告が表示される回数を制限したり、広告キャンペーンの効果を測定したりするためにも使用されます。 これらのCookieによって収集された情報は、広告主などの信頼できるサードパーティパートナーと共有される場合があります。</Bdy>
    </Div>
    <Div>
        <Head>必須のクッキー</Head>
        <Bdy>
        必須のCookieは、当社のサービスを提供するために不可欠です。 これらのCookieがないと、当社のWebサイトの一部が機能しません。 これらのCookieは、インターネット上の場所を追跡したり、現在のアクセス以外の設定を覚えたり、マーケティング目的で使用される可能性のあるあなたに関する情報を収集したりしません。 これらのCookieは通常、セッションCookieであり、閲覧セッションを閉じると期限切れになります。
</Bdy>
    </Div>
    <Div>
        <Head>分析パフォーマンスCookie</Head>
        <Bdy>
        分析パフォーマンスCookieは、Webサイトのパフォーマンスを監視して、ページビュー数とWebサイトのユニークユーザー数を決定するために使用されます。 Web分析サービスは、サードパーティによって設計および運用される場合があります。 これらのCookieによって提供される情報により、ユーザーの行動パターンを分析し、その情報を使用してユーザーエクスペリエンスを強化したり、メンテナンスが必要なWebサイトの領域を特定したりできます。 情報は匿名であり、個人を特定するために使用することはできません。個人情報は含まれず、統計目的でのみ使用されます。
</Bdy>
    </Div>
</Wrap>
