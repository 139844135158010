import React from 'react';
import styled from 'styled-components';
import { Dropdown, DropdownPositions } from '../../common/dropdown';
import { CardBase } from '../../common/card_base';
import { ChevronDownIcon } from '../../common/icons/chevron_down_icon';
import { themeBreakPoints } from '../../../themes/commons';
import { isMobileOnly } from 'react-device-detect';
import { ERC20_APP_BASE_PATH } from '../../../common/constants';
// const COMMINGSOON = `#${ERC20_APP_BASE_PATH}/comming-soon`;
const Faq = `#${ERC20_APP_BASE_PATH}/faq`;
const About = `#${ERC20_APP_BASE_PATH}/contact-us`;
const Tutorial = `#${ERC20_APP_BASE_PATH}/tutorial`;
const Fees = `#${ERC20_APP_BASE_PATH}/fees`;
const Term = `#${ERC20_APP_BASE_PATH}/terms`;
const Features = `#${ERC20_APP_BASE_PATH}/features`;
const AboutUs = `#`;

const I18n = require('i18n-js');

// const widthScreen = isMobile && window ? `${window.innerWidth}px` : '350px';
const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px 0 0;
    min-width: 70px;
    color: ${props => props.theme.componentsTheme.textColorCommon};
    @media (max-width: ${themeBreakPoints.md}) {
        flex-direction: column;
        padding: 0;
    }
    text-align: center;
    text-transform: uppercase;
    height: 60px;
`;
const DropdownItems = styled(CardBase)`
    box-shadow: ${props => props.theme.componentsTheme.boxShadow};
    min-width: 200px;
    @media (max-width: ${themeBreakPoints.sm}) {
        position: fixed;
        top: 75px;
        left: 0;
    }
    position: ${isMobileOnly ? 'fixed' : 'initial'};
    top: ${isMobileOnly ? '75px' : '0'};
    left: ${isMobileOnly ? '0' : '01'};
    background: ${props => props.theme.componentsTheme.cardBackgroundColor};
    color: ${props => props.theme.componentsTheme.textColorCommon};
`;

const ChevronDownIconW = styled.div`
    margin: 0 0 0 5px;
    display: ${isMobileOnly ? 'none' : 'initial'};
    @media (max-width: ${themeBreakPoints.md}) {
        margin: 0;
        display: none;
    }
    svg{
        path{
          fill: ${props => props.theme.componentsTheme.dropdownTextColor};
        }
    }
`;

const TradeText = styled.p`
    @media (max-width: ${themeBreakPoints.md}) {
        display: block;
    }
    font-size: 14px;
    margin: 0 10px;
`;
const Link = styled.a`
    display: block;
    padding: 10px;
    :hover {
        background: ${props => props.theme.componentsTheme.backgroundColorMarketOdd};
    }
`;

class SupportDropdown extends React.Component {

    public handleMyWalletClick = (path: number) => {
    };

    public render = () => {
        const body = (
            <DropdownItems>
                <Link onClick={scrollTop} href={Fees}>
                    {I18n.t('fees')}
                </Link>
                <Link onClick={scrollTop} href={Faq}>
                    {I18n.t('faq')}
                </Link>
                <Link onClick={scrollTop} href={Tutorial}>
                    {I18n.t('tutorial')}
                </Link>
                {!isMobileOnly && <Link target="_blank" onClick={scrollTop} href='https://developers.ondex.io/'>
                    {I18n.t('apiDocument')}
                </Link>}
                {/*<Link onClick={scrollTop} href={Term}>*/}
                {/*    {I18n.t('termConditions')}*/}
                {/*</Link>*/}
                {/*<Link onClick={scrollTop} href={Features}>*/}
                {/*    {I18n.t('features')}*/}
                {/*</Link>*/}
                {/*<Link onClick={scrollTop} href={AboutUs}>*/}
                {/*    {I18n.t('aboutUs')}*/}
                {/*</Link>*/}
            </DropdownItems>
        );
        const header = (
            <Header>
                <TradeText>{I18n.t('support')}</TradeText>
                <ChevronDownIconW><ChevronDownIcon /></ChevronDownIconW>
            </Header>
        );
        return (
            <Dropdown
                body={body}
                header={header}
                horizontalPosition={DropdownPositions.Right}
                shouldCloseDropdownOnClickOutside={true}
                shouldCloseDropdownOnClickInside={true}
            />
        );
    };
}

const scrollTop = () => {
    window.scrollTo(0, 0);
};

export { SupportDropdown };
