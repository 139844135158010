import { LocalStorage } from './services/local_storage';

const configs = require('./config.json');
const I18n = require('i18n-js')
const en = require('./assets/languages/en.json');
const ja = require('./assets/languages/ja.json');
const LocalStorages = new LocalStorage();
const DEFAULT_LANGUAGE = 'en';

class LanguageService {
    private language: any;
    constructor() {
        const lang = LocalStorages.getlang();
        this.language = lang ? lang : DEFAULT_LANGUAGE;
        I18n.defaultLocale = this.language
        I18n.locale = this.language;
        I18n.translations = {
            en,
            ja
        }
        const languages = configs.languages;
        for (const key in languages) {
            try {
                const _lang = require('./assets/languages/' + key + '.json');
                I18n.translations[key] = _lang;
            } catch (error) { }
        }
        LocalStorages.saveLang(this.language);
    }

    settranslations(lang: string, translations: any) {
        I18n.translations[lang] = translations;
    }

    getLanguage(): string {
        return I18n.locale || this.language;
    }

    setLanguage(language: string) {
        if (!I18n.translations[language]) {
            try {
                const _lang = require('./assets/languages/' + language + '.json');
                I18n.translations[language] = _lang;
            } catch (error) { }
        }
        if (I18n.translations[language]) {
            this.language = language
            I18n.locale = this.language;
            LocalStorages.saveLang(language);
        }
    }
}

export default new LanguageService();
