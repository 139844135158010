import React from 'react';

import { UserHistoryContainer } from '../marketplace/user_history';
import { CheckMetamaskStateModalContainer } from '../../common/check_metamask_state_modal_container';
import { ColumnWide } from '../../common/column_wide';
import { Content } from '../common/content_wrapper';


export const UserHistory = () => (
    <Content>
        <CheckMetamaskStateModalContainer>
            <ColumnWide>
                <UserHistoryContainer />
            </ColumnWide>
        </CheckMetamaskStateModalContainer>
    </Content>
);
