
import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
`
const Faqs = styled.div``
const Head = styled.h3``
const Question = styled.h3``
const Answer = styled.p``

export const en =
    <Wrap>
        <Head>Setting up  the wallet</Head>
        <Faqs>
            <Question>1.1 Create a new metamask account</Question>
            <Answer>Go to the Metamask website.<br />
        - Click “Get Chrome Extension” to install Metamask.<br />
        - Click “Add to Chrome” in the upper right.<br />
        - Click “Add Extension” to complete the installation.<br />
            </Answer>
        </Faqs>
        <Faqs>
            <Question>1.2  Using Metamask </Question>
            <Answer>
                Click on the Metamask logo in the upper right hand corner of your Google chrome browser.<br />
        Read and agree to the terms and conditions. You may have to agree to 2 to 3 pages worth of terms.<br />
        Enter a password and click “Create” to create your wallet.<br />
        You will see a set of 12 “seed words” for your vault. Click “Save Seed Words as File” and copy the “MetaMask Seed Words” file that is downloaded to a safe place. You will need it to access your vault.<br />
        Click “I’ve Copied It Somewhere Safe” once your seed words file has been secured. You’ll be taken into your Metamask wallet!<br />
            </Answer>
        </Faqs>
        <Faqs>
            <Question>1.3 Import a wallet</Question>
            <Answer>
                - If you already have an ETH wallet, you can just import it to Metamask.<br />
        - Click the” import” tab <br />
        - Copy and paste your wallet private key<br />
        - Click “import” button<br />

            </Answer>
        </Faqs>
        <Faqs>
            <Question>1.4 Connect the wallet to the DEX</Question>
            <Answer>
                - Clicking on a pair will lead you to the exchange window<br />
        - At the top-left corner, you can click on “Connect Metamask”<br />
        - Type your password<br />
        - Click “Log In”<br />
        - Connect your account by clicking “connect”<br />
        - The green light indicates that your wallet is connected<br />
        - You can now see your wallet balance for the selected pair at the top-left corner.<br />

            </Answer>
        </Faqs>
        <Head>2. Unlock tokens</Head>
        <Faqs>
            <Question>In order to trade tokens you need to unlock them.</Question>
            <Answer>
                - Go to “My wallet” and click on the padlock icon<br />
        - Confirm Unlock in Metamask<br />
        - The token is now unlocked and can be traded (ETH)<br />
        - Do the same to unlock the token you want to trade against (ZRX) in this case.<br />
            </Answer>
        </Faqs>
        <Faqs>
            <Question></Question>
            <Answer></Answer>
        </Faqs>
        <Faqs>
            <Question></Question>
            <Answer></Answer>
        </Faqs>
    </Wrap>
