import { BigNumber } from '@0x/utils';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { MAX_AMOUNT, MAX_LENGTH, MULTIPLES, UI_DECIMALS_DISPLAYED_PRICE_ETH, ZERO } from '../../../common/constants';
import { initWallet, startBuySellLimitSteps, startBuySellMarketSteps } from '../../../store/actions';
import { fetchTakerAndMakerFee } from '../../../store/relayer/actions';
import { setModal } from '../../../store/ui/actions';

import {
    getBaseToken,
    getBaseTokenBalance,
    getCurrencyPair,
    getOrderPriceSelected,
    getOrderSelected,
    getQuoteToken,
    getQuoteTokenBalance,
    getTotalEthBalance,
    getWeb3State,
} from '../../../store/selectors';
import { themeDimensions } from '../../../themes/commons';
import { getKnownTokens, isWeth } from '../../../util/known_tokens';
import {
    tokenAmountInUnits,
    tokenAmountInUnitsToBigNumber,
    tokenSymbolToDisplayString,
    unitsInTokenAmount,
} from '../../../util/tokens';
import {
    ButtonIcons,
    ButtonVariant,
    CurrencyPair,
    ModalState,
    OrderFeeData,
    OrderSide,
    OrderType,
    StoreState,
    Token,
    TokenBalance,
    Web3State,
} from '../../../util/types';
import { BigNumberInput } from '../../common/big_number_input';
import { Button } from '../../common/button';
import { CardBase } from '../../common/card_base';
import { CardTabSelector } from '../../common/card_tab_selector';
import { ErrorCard, ErrorIcons, FontSize } from '../../common/error_card';
import { isMobile } from 'react-device-detect';
import QrReader from 'react-qr-reader';
import { OrderDetailsContainer } from './order_details';
import { fixBigNumber } from '../../../util/number_utils';

const I18n = require('i18n-js');

interface Prop {
    orderType?: OrderType | null;
    orderSide?: OrderSide | null;
}

interface StateProps {
    web3State: Web3State;
    currencyPair: CurrencyPair;
    orderPriceSelected: BigNumber | null;
    orderSelected: BigNumber[] | null;
    baseToken: Token | null;
    quoteToken: Token | null;
    baseTokenBalance: TokenBalance | null;
    quoteTokenBalance: TokenBalance | null;
    totalEthBalance: BigNumber;
}

interface DispatchProps {
    onSubmitLimitOrder: (
        amount: BigNumber,
        price: BigNumber,
        side: OrderSide,
        orderFeeData: OrderFeeData,
        expire: number,
        takerAddress?: string,
    ) => Promise<any>;
    onSubmitMarketOrder: (amount: BigNumber, side: OrderSide, orderFeeData: OrderFeeData) => Promise<any>;
    onConnectWallet: () => any;
    onFetchTakerAndMakerFee: (amount: BigNumber, price: BigNumber, side: OrderSide) => Promise<OrderFeeData>;
    onSetModal: (data: ModalState) => {}
}

type Props = Prop & StateProps & DispatchProps;

interface State {
    makerAmount: BigNumber | null;
    orderType: OrderType;
    price: BigNumber | null;
    orderSide: OrderSide;
    error: {
        btnMsg: string | null;
        cardMsg: string | null;
    };
    qrCode: string;
    isShowQrCode: boolean;
    takerAddress: string | null,
    expireAmount: number | string,
    expireType: string,
    total: BigNumber | null,
    showAddress: boolean | false,
}

const BuySellWrapper = styled(CardBase)`
    margin-bottom: ${themeDimensions.verticalSeparationSm};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px ${themeDimensions.horizontalPadding};
`;

const TabsContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

const TabButton = styled.div<{ isSelected: boolean; side: OrderSide }>`
    align-items: center;
    background-color: ${props =>
    props.isSelected ? 'transparent' : props.theme.componentsTheme.inactiveTabBackgroundColor};
    border-bottom-color: ${props => (props.isSelected ? 'transparent' : props.theme.componentsTheme.cardBorderColor)};
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-right-color: ${props => (props.isSelected ? props.theme.componentsTheme.cardBorderColor : 'transparent')};
    border-right-style: solid;
    border-right-width: 1px;
    color: ${props =>
    props.isSelected
        ? props.side === OrderSide.Buy
        ? props.theme.componentsTheme.green
        : props.theme.componentsTheme.red
        : props.theme.componentsTheme.textLight};
    cursor: ${props => (props.isSelected ? 'default' : 'pointer')};
    display: flex;
    font-weight: 600;
    height: 47px;
    justify-content: center;
    width: 50%;

    &:first-child {
        border-top-left-radius: ${themeDimensions.borderRadius};
    }

    &:last-child {
        border-left-color: ${props => (props.isSelected ? props.theme.componentsTheme.cardBorderColor : 'transparent')};
        border-left-style: solid;
        border-left-width: 1px;
        border-right: none;
        border-top-right-radius: ${themeDimensions.borderRadius};
    }
`;

const LabelContainer = styled.div`
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const Label = styled.label<{ color?: string }>`
    color: ${props => props.color || props.theme.componentsTheme.textColorCommon};
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
`;

const InnerTabs = styled(CardTabSelector)`
    font-size: 14px;
`;

const FieldContainer = styled.div`
    height: ${themeDimensions.fieldHeight};
    margin-bottom: 2px;
    position: relative;
`;

const BigInputNumberStyled = styled<any>(BigNumberInput)`
    background-color: ${props => props.theme.componentsTheme.textInputBackgroundColor};
    border-radius: ${themeDimensions.borderRadius};
    border: 1px solid ${props => props.theme.componentsTheme.textInputBorderColor};
    color: ${props => props.theme.componentsTheme.textInputTextColor};
    font-feature-settings: 'tnum' 1;
    padding: 5px 60px 5px 10px;
    position: absolute;
    width: 80%;
    right:0px;
    z-index: 1;
`;

const TokenContainer = styled.div`
    display: flex;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 12;
`;

const TokenText = styled.span`
    color: ${props => props.theme.componentsTheme.textInputTextColor};
    font-size: 14px;
    font-weight: normal;
    line-height: 21px;
    text-align: right;
`;
const CheckboxAdress = styled.input`
    position: absolute;
    right: 0;
    width: 20px;
    height: 20px;

`;
const BigInputNumberTokenLabel = (props: { tokenSymbol: string }) => (
    <TokenContainer>
        <TokenText>{tokenSymbolToDisplayString(props.tokenSymbol)}</TokenText>
    </TokenContainer>
);

const TIMEOUT_BTN_ERROR = 2000;
const TIMEOUT_CARD_ERROR = 4000;

const selectData = {
    minutes: 'minutes',
    hours: 'hours',
    days: 'days',
    months: 'months',
    years: 'years',
};

class BuySell extends React.Component<Props, State> {
    public state: State = {
        makerAmount: null,
        price: null,
        orderType: OrderType.Limit,
        orderSide: OrderSide.Buy,
        error: {
            btnMsg: '',
            cardMsg: null,
        },
        qrCode: '',
        isShowQrCode: false,
        takerAddress: null,
        expireAmount: 1,
        expireType: 'days',
        total: null,
        showAddress: false,
    };

    public componentDidUpdate = async (prevProps: Readonly<Props>) => {
        const newProps = this.props;
        // const  orderSide = this.props.orderSide || OrderSide.Sell;

        if (newProps && newProps.currencyPair && prevProps && prevProps.currencyPair && newProps.currencyPair !== prevProps.currencyPair) {
            return this.setState({
                price: null,
                makerAmount: null,
            });
        }

        const orderType = this.props.orderType || OrderType.Limit;
        if (newProps.orderSelected && newProps.orderSelected.length > 0
            && newProps.orderPriceSelected && prevProps.orderSelected && newProps.orderSelected[0] !== prevProps.orderSelected[0]
            && orderType === OrderType.Limit) {
            const _makerAmount = new BigNumber(fixBigNumber(newProps.orderSelected[0].toFixed(UI_DECIMALS_DISPLAYED_PRICE_ETH)));
            const _price = new BigNumber(fixBigNumber(newProps.orderSelected[1].toFixed(UI_DECIMALS_DISPLAYED_PRICE_ETH)));
            this.setState({
                price: _price,
                makerAmount: _makerAmount,
            });
        }
    };


    public handleChangeTakerAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValueStr = e.currentTarget.value;
        this.setState({ qrCode: newValueStr.toLowerCase() });
    };

    public handleScan = (data: any) => {
        if (data) {
            this.setState({
                qrCode: data,
                isShowQrCode: false,
            });
        }
    };

    public handleError = (err: any) => {
        this.setState({ isShowQrCode: false });
        alert(I18n.t('notAccessCamera'));
    };

    public onToggleQrCode = () => {
        if (isMobile) {
            try {
                const { infinitowallet } = window;
                if (infinitowallet) {
                    infinitowallet.utils.scanQRCode((error: any, result: any) => {
                        if (result && result.qrcode) {
                            this.setState({
                                qrCode: result.qrcode,
                            });
                        }
                    });

                }
            } catch (error) {
            }
        } else {
            this.setState({ isShowQrCode: !this.state.isShowQrCode });
        }
    };
    public updateExpireAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValueStr = e.currentTarget.value;
        if (newValueStr.length > 2) {
            e.currentTarget.value = '1';
        }
        ;
        let newValue = parseFloat(newValueStr);
        if (isNaN(newValue)) {
            this.setState({
                expireAmount: '',
            });
        } else {
            this.setState({ expireAmount: newValue });
        }
    };

    public updateExpireType = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue: string = e.currentTarget.value;
        this.setState({ expireType: newValue });
    };

    public expirationTimeSeconds(): number {
        const { expireAmount, expireType } = this.state;
        const now = Math.floor(new Date().getTime() / 1000);
        // const oneYearFromNow = Math.floor(new Date().valueOf() / 1000) + 3600 * 24 * 365;
        if (expireAmount) {
            let amount = parseFloat(expireAmount.toString());
            switch (expireType) {
                case selectData.minutes:
                    return now + amount * 60;
                case selectData.hours:
                    return now + amount * 3600;
                case selectData.days:
                    return now + amount * 3600 * 24;
                case selectData.months:
                    return now + amount * 3600 * 24 * 30;
            }
        }
        return 0;
    }

    public setTotal(total: BigNumber) {
        if (this && total !== null) {
            this.setState({ total });
        }
    }


    public hideMesage = () => {
        setTimeout(() => {
            this.setState({
                error: {
                    btnMsg: '',
                    cardMsg: null,
                },
            });
        }, TIMEOUT_BTN_ERROR);
    };

    public render = () => {
        const { currencyPair, web3State, quoteToken, baseToken, quoteTokenBalance, baseTokenBalance, totalEthBalance } = this.props;
        const { makerAmount, price, error, qrCode, isShowQrCode, showAddress } = this.state;
        const orderSide = (isMobile ? this.state.orderSide : this.props.orderSide) || OrderSide.Sell;
        const orderType = (isMobile ? this.state.orderType : this.props.orderType) || OrderType.Limit;
        const buySellInnerTabs = [
            {
                active: orderType === OrderType.Market,
                onClick: this._switchToMarket,
                text: 'Market',
            },
            {
                active: orderType === OrderType.Limit,
                onClick: this._switchToLimit,
                text: 'Limit',
            },
        ];

        const isMakerAmountEmpty = makerAmount === null || makerAmount.isZero();
        const isPriceEmpty = price === null || price.isZero();

        const orderTypeLimitIsEmpty = orderType === OrderType.Limit && (isMakerAmountEmpty || isPriceEmpty);
        const orderTypeMarketIsEmpty = orderType === OrderType.Market && isMakerAmountEmpty;

        const btnPrefix = orderSide === OrderSide.Buy ? 'Buy ' : 'Sell ';
        const btnText = error && error.btnMsg ? 'Error' : btnPrefix + tokenSymbolToDisplayString(currencyPair.base);

        const decimals = getKnownTokens().getTokenBySymbol(currencyPair.base).decimals;


        let quoteBalanceString = '0';
        let baseBalanceString = '0';
        let quoteTokenLabel = ((quoteToken && isWeth(quoteToken.symbol)) ? 'ETH' : tokenSymbolToDisplayString(currencyPair.quote)) || 'ETH';
        if (quoteToken && baseTokenBalance && quoteTokenBalance) {
            const quoteTokenBalanceAmount = isWeth(quoteToken.symbol) ? totalEthBalance : quoteTokenBalance.balance;
            quoteBalanceString = tokenAmountInUnits(
                quoteTokenBalanceAmount,
                quoteToken.decimals,
                quoteToken.displayDecimals,
            );
            baseBalanceString = tokenAmountInUnits(
                baseTokenBalance.balance,
                baseTokenBalance.token.decimals,
                baseTokenBalance.token.displayDecimals,
            );
            // quoteTokenLabel = isWeth(quoteToken.symbol) ? 'ETH' : tokenSymbolToDisplayString(currencyPair.quote);
        }
        const imgWallet = <img alt="" width={20} src={require('../../../assets/icons/Wallet_trade.svg')} />;
        let isUsdtWWX = false;
        let pair = '';
        if (quoteToken && baseToken && quoteToken.symbol && baseToken.symbol) {
            const _symbol = pair = quoteToken.symbol + baseToken.symbol;
            if (_symbol === 'wwxusdt' || _symbol === 'usdtwwx') isUsdtWWX = true;
        }
        const { infinitowallet } = window;
        const showQRCode = isMobile && !infinitowallet ? false : true;

        return (
            <>
                <BuySellWrapper>
                    {isMobile && <TabsContainer>
                        <TabButton
                            isSelected={orderSide === OrderSide.Buy}
                            onClick={this.changeTab(OrderSide.Buy)}
                            side={OrderSide.Buy}
                        >
                            {I18n.t('buy')}
                        </TabButton>
                        <TabButton
                            isSelected={orderSide === OrderSide.Sell}
                            onClick={this.changeTab(OrderSide.Sell)}
                            side={OrderSide.Sell}
                        >
                            {I18n.t('sell')}
                        </TabButton>
                    </TabsContainer>}
                    <Content>
                        {isMobile && <LabelContainer>
                            <InnerTabs tabs={buySellInnerTabs} />
                        </LabelContainer>}
                        <LabelContainer>
                            <LabelTitle>{orderSide === OrderSide.Buy ? I18n.t('buy') : I18n.t('sell')}</LabelTitle>
                            <DivLeftBalance>
                                {orderSide === OrderSide.Buy && quoteBalanceString && <> {imgWallet} {' '}{quoteBalanceString}{' '}{quoteTokenLabel}</>}
                                {orderSide === OrderSide.Sell && baseBalanceString && <> {imgWallet} {' '}{baseBalanceString}{' '}{tokenSymbolToDisplayString(currencyPair.base)}</>}
                            </DivLeftBalance>
                        </LabelContainer>
                        {orderType === OrderType.Limit && (
                            <>
                                <FieldContainer>
                                    <LabelCustom>{I18n.t('addressOptional')}</LabelCustom>
                                    <CheckboxAdress onChange={(event) => this.setState({
                                        showAddress: event.target.checked || false,
                                        isShowQrCode: false,
                                    })} type="checkbox" value="" name="show" />
                                </FieldContainer>
                                {showAddress && <FieldContainer>
                                    <FieldContainerWrap>
                                        <ContentCodeWrap>
                                            <ContentCode>
                                                <ContentCodeInput
                                                    placeholder={orderSide === OrderSide.Buy ? I18n.t('enterSellerAddress') : I18n.t('enterBuyerAddress')}
                                                    onChange={e => this.handleChangeTakerAddress(e)}
                                                    min="0" max="100"
                                                    value={qrCode}
                                                    title={qrCode}
                                                />
                                                {showQRCode &&
                                                <BtQRCode onClick={() => this.onToggleQrCode()}><ImgQRCode alt=''
                                                                                                           src={require('../../../assets/images/qr-code.png')} /></BtQRCode>}
                                            </ContentCode>
                                        </ContentCodeWrap>
                                    </FieldContainerWrap>
                                    {!isMobile && isShowQrCode && <QrReaderWrap
                                        delay={300}
                                        onError={this.handleError}
                                        onScan={(e: any) => this.handleScan(e)}
                                        style={{ width: '100%' }}
                                    />}
                                </FieldContainer>}
                            </>
                        )}

                        <FieldContainer>
                            <LabelCustom>{I18n.t('amount')}</LabelCustom>
                            <BigInputNumberStyled
                                decimals={decimals}
                                min={ZERO}
                                onChange={this.updateMakerAmount}
                                value={makerAmount}
                                placeholder={'0.00'}
                                pair={pair}
                            />
                            <BigInputNumberTokenLabel tokenSymbol={currencyPair.base} />
                        </FieldContainer>

                        {orderType === OrderType.Limit && (
                            <>
                                <FieldContainer>
                                    <LabelCustom>{I18n.t('price')}</LabelCustom>
                                    <BigInputNumberStyled
                                        decimals={0}
                                        min={new BigNumber(0)}
                                        onChange={this.updatePrice}
                                        value={price}
                                        placeholder={'0.00'}
                                        maxLength={6}
                                    />
                                    <BigInputNumberTokenLabel tokenSymbol={currencyPair.quote} />
                                </FieldContainer>
                            </>
                        )}

                        <OrderDetailsContainer
                            orderType={orderType}
                            orderSide={orderSide}
                            tokenAmount={makerAmount || new BigNumber(0)}
                            tokenPrice={price || new BigNumber(0)}
                            currencyPair={currencyPair}
                            setTotal={this.setTotal}
                        />
                        {orderType === OrderType.Limit && (
                            <ExpireDateWrap>
                                <Label>{I18n.t('expires')}</Label>
                                <ExpireDate>
                                    <InputDate
                                        placeholder={'...'}
                                        maxLength={2}
                                        onChange={(e) => this.updateExpireAmount(e)}
                                        type="number"
                                        min="0" max="100"
                                        defaultValue={'1'}
                                    />
                                    <DatePicker
                                        onChange={(e) => this.updateExpireType(e)} defaultValue={selectData.days}
                                    >
                                        <option value={selectData.minutes}>{I18n.t('minutes')}</option>
                                        <option value={selectData.hours}>{I18n.t('hours')}</option>
                                        <option value={selectData.days}>{I18n.t('days')}</option>
                                        <option value={selectData.months}>{I18n.t('months')}</option>
                                    </DatePicker>
                                </ExpireDate>
                            </ExpireDateWrap>
                        )}
                        <Button
                            disabled={web3State !== Web3State.Done || orderTypeLimitIsEmpty || orderTypeMarketIsEmpty || error.btnMsg !== ''}
                            icon={error && error.btnMsg ? ButtonIcons.Warning : undefined}
                            onClick={this.submit}
                            style={{ 'textTransform': 'uppercase', 'margin': '20px 0 0 0' }}
                            variant={
                                error && error.btnMsg
                                    ? ButtonVariant.Error
                                    : orderSide === OrderSide.Buy
                                    ? ButtonVariant.Buy
                                    : ButtonVariant.Sell
                            }
                        >
                            {btnText}
                        </Button>
                    </Content>
                </BuySellWrapper>
                {error && error.cardMsg ? (
                    <ErrorCard fontSize={FontSize.Large} text={error.cardMsg} icon={ErrorIcons.Sad} />
                ) : null}
            </>
        );
    };

    public changeTab = (orderSide: OrderSide) => () => this.setState({ orderSide });

    public updateMakerAmount = (newValue: BigNumber) => {
        this.setState({
            makerAmount: newValue,
            error: {
                btnMsg: '',
                cardMsg: null,
            },
        });
        // const { currencyPair } = this.props;
        // const decimals = getKnownTokens().getTokenBySymbol(currencyPair.base).decimals;
        // const _max = unitsInTokenAmount(MAX_AMOUNT.toString(), decimals)
        // if (newValue.isLessThanOrEqualTo(_max)) {
        //     this.setState({
        //         makerAmount: newValue,
        //         error: {
        //             btnMsg: '',
        //             cardMsg: null,
        //         },
        //     });
        // }
        // else {
        //     this.setState({
        //         error: {
        //             btnMsg: I18n.t('error'),
        //             cardMsg: 'Min: ' + MAX_AMOUNT.toString(),// I18n.t('maximumAmount', { number: MAX_AMOUNT.toString() }),
        //         },
        //     })
        // }
    };

    public updatePrice = (price: BigNumber) => {
        const { quoteToken, baseToken } = this.props;
        if (quoteToken && baseToken && quoteToken.symbol && baseToken.symbol) {
            const _symbol = quoteToken.symbol + baseToken.symbol;
            if (_symbol === 'wwxusdt' || _symbol === 'usdtwwx') {
                const _p = price.isInteger() ? price : new BigNumber(+price.toFixed(MAX_LENGTH, BigNumber.ROUND_FLOOR));
                // if (price === _p)
                this.setPrice(_p);
            } else {
                this.setPrice(price);
            }
        } else
            this.setPrice(price);
    };

    public setPrice(price: BigNumber) {
        this.setState({
            price,
            error: {
                btnMsg: '',
                cardMsg: null,
            },
        });
    }

    public submit = async () => {
        const { quoteToken, baseToken, onSetModal, currencyPair } = this.props;
        const orderSide = (isMobile ? this.state.orderSide : this.props.orderSide) || OrderSide.Sell;
        const orderType = (isMobile ? this.state.orderType : this.props.orderType) || OrderType.Limit;
        const makerAmount = this.state.makerAmount || ZERO;
        const price = this.state.price || ZERO;
        const takerAddress = this.state.qrCode;
        const expire = this.expirationTimeSeconds();
        let orderFeeData = null;
        const decimals = getKnownTokens().getTokenBySymbol(currencyPair.base).decimals;
        const _makerAmount = tokenAmountInUnitsToBigNumber(makerAmount, decimals);

        if (quoteToken && baseToken && quoteToken.symbol && baseToken.symbol) {
            const _symbol = quoteToken.symbol + baseToken.symbol;
            if (_symbol === 'wwxusdt' || _symbol === 'usdtwwx') {
                const _max = unitsInTokenAmount(MAX_AMOUNT.toString(), decimals);
                const mul = _makerAmount.div(MULTIPLES);
                if (!mul.isInteger()) {
                    return onSetModal({ title: I18n.t('error'), body: I18n.t('multiple100') });
                    // return this.setState({
                    //     error: {
                    //         btnMsg: I18n.t('error'),
                    //         cardMsg: 'MULTIPLES: ' + MULTIPLES.toString(),
                    //     }
                    // }, () => { this.hideMesage(); });
                }
                if (makerAmount.isLessThan(_max)) {
                    return this.setState({
                        error: {
                            btnMsg: I18n.t('error'),
                            cardMsg: 'Min: ' + MAX_AMOUNT.toString(),
                        },
                    }, () => {
                        this.hideMesage();
                    });
                    // return this.hideMesage();
                }
            }
        }
        try {
            orderFeeData = await this.props.onFetchTakerAndMakerFee(makerAmount, price, orderSide);
        } catch (error) {
            return onSetModal({ title: I18n.t('error'), body: I18n.t('adjustYourOrder') });
            // return this.setState(
            //     {
            //         error: {
            //             btnMsg: I18n.t('error'),
            //             cardMsg: I18n.t('notCreateOrder'),
            //         },
            //     }, () => { this.hideMesage(); })
            // return this.hideMesage();
        }
        if (!orderFeeData) return;
        try {
            if (orderType === OrderType.Limit) {
                if (takerAddress != null)
                    await this.props.onSubmitLimitOrder(makerAmount, price, orderSide, orderFeeData, expire, takerAddress);
                else await this.props.onSubmitLimitOrder(makerAmount, price, orderSide, orderFeeData, expire);
            } else {
                await this.props.onSubmitMarketOrder(makerAmount, orderSide, orderFeeData);
            }
        } catch (error) {
            this.setState(
                {
                    error: {
                        btnMsg: I18n.t('error'),
                        cardMsg: error.message,
                    },
                },
                () => {
                    // After a timeout both error message and button gets cleared
                    setTimeout(() => {
                        this.setState({
                            error: {
                                ...this.state.error,
                                btnMsg: '',
                            },
                        });
                    }, TIMEOUT_BTN_ERROR);
                    setTimeout(() => {
                        this.setState({
                            error: {
                                ...this.state.error,
                                cardMsg: null,
                            },
                        });
                    }, TIMEOUT_CARD_ERROR);
                },
            );
        }
        this._reset();
    };

    private readonly _reset = () => {
        this.setState({
            makerAmount: null,
            price: null,
        });
    };

    private readonly _switchToMarket = () => {
        this.setState({
            orderType: OrderType.Market,
        });
    };

    private readonly _switchToLimit = () => {
        this.setState({
            orderType: OrderType.Limit,
        });
    };
}

const mapStateToProps = (state: StoreState): StateProps => {
    return {
        web3State: getWeb3State(state),
        currencyPair: getCurrencyPair(state),
        orderPriceSelected: getOrderPriceSelected(state),
        orderSelected: getOrderSelected(state),
        baseToken: getBaseToken(state),
        quoteToken: getQuoteToken(state),
        quoteTokenBalance: getQuoteTokenBalance(state),
        baseTokenBalance: getBaseTokenBalance(state),
        totalEthBalance: getTotalEthBalance(state),
    };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
    return {
        onSubmitLimitOrder: (amount: BigNumber, price: BigNumber, side: OrderSide, orderFeeData: OrderFeeData, expire: number, takerAddress?: string) =>
            dispatch(startBuySellLimitSteps(amount, price, side, orderFeeData, expire, takerAddress)),
        onSubmitMarketOrder: (amount: BigNumber, side: OrderSide, orderFeeData: OrderFeeData) =>
            dispatch(startBuySellMarketSteps(amount, side, orderFeeData)),
        onConnectWallet: () => dispatch(initWallet()),
        onFetchTakerAndMakerFee: (amount: BigNumber, price: BigNumber, side: OrderSide) =>
            dispatch(fetchTakerAndMakerFee(amount, price, side)),
        onSetModal: (data: ModalState) => dispatch(setModal(data)),
    };
};

const BuySellContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(BuySell);

export { BuySell, BuySellContainer };

const DivLeftBalance = styled.div`
    float:right;
    color: ${props => props.theme.componentsTheme.textColorCommon};
`;

const LabelTitle = styled.label<{ color?: string }>`
    color: ${props => props.color || props.theme.componentsTheme.textColorCommon};
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    margin: 0;
`;

const FieldContainerWrap = styled(FieldContainer)`
    display: flex;
`;

const ContentCodeWrap = styled.div`
    margin: 0 0 10px 0;
`;
const ContentCodeInput = styled.input`
    font-feature-settings: 'tnum' 1;
    background-color: ${props => props.theme.componentsTheme.textInputBackgroundColor};
    border-radius: ${themeDimensions.borderRadius};
    border: 1px solid ${props => props.theme.componentsTheme.textInputBorderColor};
    color: ${props => props.theme.componentsTheme.textInputTextColor};
    font-feature-settings: 'tnum' 1;
    padding: 5px 30px 5px 14px;
    position: absolute;
    width: 80%;
    right:0px;
    z-index: 1;
`;

const ContentCode = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const QrReaderWrap = styled(QrReader)`
    position: absolute;
    z-index: 999;
`;
const BtQRCode = styled.button`
    align-items: center;
    position: absolute;
    right: 0;
    z-index: 2;
    background: transparent;
    border: none;
    top: 1px;
    padding: 2px;
`;
const ImgQRCode = styled.img`
    width: 25px;
    background: #fff;
`;

const LabelCustom = styled.label<{ color?: string }>`
    color: ${props => props.color || props.theme.componentsTheme.textColorCommon};
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`;

const ExpireDateWrap = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ExpireDate = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 80%;
`;
const DatePicker = styled.select`
    background-color: ${props => props.theme.componentsTheme.textInputBackgroundColor};
    border-radius: ${themeDimensions.borderRadius};
    border: 1px solid ${props => props.theme.componentsTheme.textInputBorderColor};
    color: ${props => props.theme.componentsTheme.textInputTextColor};
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    width: 30%;
    max-width: 150px;
`;

const InputDate = styled.input`
    background-color: ${props => props.theme.componentsTheme.textInputBackgroundColor};
    border-radius: ${themeDimensions.borderRadius};
    border: 1px solid ${props => props.theme.componentsTheme.textInputBorderColor};
    color: ${props => props.theme.componentsTheme.textInputTextColor};
    padding: 5px 10px;
    width: 30%;
    max-width: 100px;
    margin: 0 10px 0 0;
    height: fit-content;
    text-align: center;
`;
