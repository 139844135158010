import React from 'react';
import styled from 'styled-components';

const Faqs = styled.div`
`
const Question = styled.h3`
`
const Answer = styled.div`
`
const P = styled.p``

export const jp = <Faqs>
    <Question>料金体系とは？</Question>
    <Answer>
        <P>取引手数料は、EthereumブロックチェーンのGasで支払われ、ネットワークまたは選択した速度レベルによって、手数料は異なる場合があります。 平均すると、手数料はトランザクションあたり約0.001 USDまたは0.0005ETHになります。<br /></P>
        <P>その後、0xプラットフォームによっては、取引手数料が追加される場合があります。 （例えば、メーカーとテイカーは0.10％）<br /></P>
        <P>メーカー手数料は、買いの市場価格より低く、売りの市場価格より上に指値注文を出すことにより、注文書に流動性を追加するときに支払われます。<br /></P>
        <P>注文に対して実行された注文を売買注文リストから発行することにより、売買注文リストから流動性を削除すると、テイカー料金が支払われます。<br /></P>
    </Answer>
</Faqs>
