import React from 'react';
import styled from 'styled-components';
import { Dropdown, DropdownPositions } from '../../common/dropdown';
import { CardBase } from '../../common/card_base';
import { ChevronDownIcon } from '../../common/icons/chevron_down_icon';
import { themeBreakPoints } from '../../../themes/commons';
import { isMobileOnly } from "react-device-detect";
import { ERC20_APP_BASE_PATH } from '../../../common/constants';
// const COMMINGSOON = `#${ERC20_APP_BASE_PATH}/comming-soon`;
const Tutorial = `#${ERC20_APP_BASE_PATH}/tutorial`;
const Fees = `#${ERC20_APP_BASE_PATH}/fees`;
const Term = `#${ERC20_APP_BASE_PATH}/terms`;
const Features = `#${ERC20_APP_BASE_PATH}/features`;
const Faq = `#${ERC20_APP_BASE_PATH}/faq`;
const About = `#${ERC20_APP_BASE_PATH}/contact-us`;
const COMMINGSOON = `#${ERC20_APP_BASE_PATH}/comming-soon`;

const I18n = require('i18n-js');

const widthScreen = isMobileOnly && window ? `${window.innerWidth}px` : '250px';
const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px 0 0;
    min-width: 40px;
    color: ${props => props.theme.componentsTheme.textColorCommon};
    @media (max-width: ${themeBreakPoints.md}) {
        flex-direction: column;
        padding: 0;
    }
    text-align: center;
    text-transform: uppercase;
    height: 60px;
`;
const DropdownItems = styled(CardBase)`
    box-shadow: ${props => props.theme.componentsTheme.boxShadow};
    min-width: ${widthScreen};
    @media (max-width: ${themeBreakPoints.sm}) {
        position: fixed;
        top: 59px;
        left: 0;
    }
    position: ${ isMobileOnly ? 'fixed' : 'initial'};
    top: ${ isMobileOnly ? '59px' : '0'};
    left: ${ isMobileOnly ? '0' : '01'};
    background: ${props => props.theme.componentsTheme.cardBackgroundColor};
    color: ${props => props.theme.componentsTheme.textColorCommon};
    padding: 0;
    right: 0;
`;

const ChevronDownIconW = styled.div`
    margin: 0 0 0 5px;
    display: ${isMobileOnly ? 'none' : 'initial'};
    @media (max-width: ${themeBreakPoints.md}) {
        margin: 0;
        display: none;
    }
`;

const TradeText = styled.p`
    @media (max-width: ${themeBreakPoints.md}) {
        margin: 0;
        display: block;
    }
    font-size: 30px;
`
const Link = styled.a`
    display: block;
    padding: 15px 5px;
    :hover {
        background: ${props => props.theme.componentsTheme.backgroundColorMarketOdd};
    }
`
const Hd = styled.h2`
    text-transform: uppercase;
    font-size: 19px;
    margin: 10px;
`
class MbDropdown extends React.Component {

    public handleMyWalletClick = (path: number) => {
    };

    public render = () => {
        const { infinitowallet } = window;
        const body = (
            <DropdownItems>
                <Hd>{I18n.t('support')}</Hd>
                {/* <Link onClick={scrollTop} href={COMMINGSOON}>
                    {I18n.t('earn')}
                </Link> */}
                <Link onClick={scrollTop} href={Fees}>
                    {I18n.t('fees')}
                </Link>
                <Link onClick={scrollTop} href={Faq}>
                    {I18n.t('faq')}
                </Link>
                <Link onClick={scrollTop} href={Tutorial}>
                    {I18n.t('tutorial')}
                </Link>
                {!isMobileOnly && <Link target="_blank" onClick={scrollTop} href='https://developers.ondex.io/'>
                    {I18n.t('apiDocument')}
                </Link>}
                <Link onClick={scrollTop} href={Term}>
                    {I18n.t('termConditions')}
                </Link>
                <Link onClick={scrollTop} href={Features}>
                    {I18n.t('features')}
                </Link>
                {infinitowallet && <>
                    <Hd>{I18n.t('balances')}</Hd>
                    <Link onClick={scrollTop} href={`#${ERC20_APP_BASE_PATH}/my-wallet`}>
                        {I18n.t('myWallet')}
                    </Link>
                    <Link onClick={scrollTop} href={`#${ERC20_APP_BASE_PATH}/user-history`}>
                        {I18n.t('myHistory')}
                    </Link>
                </>}
            </DropdownItems>
        );
        const header = (
            <Header>
                <TradeText>=</TradeText>
                <ChevronDownIconW><ChevronDownIcon /></ChevronDownIconW>
            </Header >
        )
        return (
            <Dropdown
                body={body}
                header={header}
                horizontalPosition={DropdownPositions.Right}
                shouldCloseDropdownOnClickOutside={true}
                shouldCloseDropdownOnClickInside={true}
            />
        );
    };
}

const scrollTop = () => {
    window.scrollTo(0, 0);
}

export { MbDropdown };
