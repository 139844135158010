import React, { HTMLAttributes } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { getEthAccount, getWeb3State } from '../../../store/selectors';
import { StoreState } from '../../../util/types';
import { goToHome, goToWallet } from '../../../store/router/actions';
import { METAMASK_EXTENSION_URL } from '../../../common/constants';
import { LocalStorage } from '../../../services/local_storage';
import { initWallet } from '../../../store/actions';
import { themeBreakPoints } from '../../../themes/commons';
import { isMobileOnly } from 'react-device-detect';

const Bgg = require('../../../assets/images/bg.svg');
const WelcomeImage = require('../../../assets/images/1.png');

const localStorage = new LocalStorage(window.localStorage);
const I18n = require('i18n-js');

interface OwnProps extends HTMLAttributes<HTMLSpanElement> {
}

interface StateProps {
    ethAccount: string;
    web3State: string;
}

interface DispatchProps {
    goToHomePage: () => any;
    onGoToWalletPage: () => any;
    onConnectWallet: () => any;
}

type Props = StateProps & DispatchProps;

const Wrapp = styled.div`
    background: ${props => props.theme.componentsTheme.cardBackgroundColor} url(${Bgg}) no-repeat center bottom;
`;
const Max = styled.div`
    max-width: ${themeBreakPoints.xxl}
    text-align: left;
    color: ${props => props.theme.componentsTheme.textColorCommon};
    background: ${props => props.theme.componentsTheme.cardBackgroundColor} url(${Bgg}) no-repeat center bottom;
    padding: 100px 5%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    @media (max-width: ${themeBreakPoints.md}) {
        flex-direction: column;
    }
    margin: 0 auto;
`;

const H1 = styled.h2`
    font-size: ${isMobileOnly ? '25px' : '35px'};
    margin: 15px 0 70px 0;
`;
const News = styled.div`
    @media (min-width: ${themeBreakPoints.md}) {
      max-width: 500px;
    }
`;

const P = styled.p`
    color: ${props => props.theme.componentsTheme.textColorCommon};
    font-size: 14px;
`;

const Left = styled.div`
    @media (min-width: ${themeBreakPoints.md}) {
        max-width: 500px;
    }
`;

const Ima = styled.img`
    width: 100%;
`;

class App extends React.Component<Props> {

    public onConnect = (web3State: string) => {
        if (web3State === I18n.t('getChromeExtension')) {
            const win = window.open(METAMASK_EXTENSION_URL, '_blank');
            if (win) {
                win.focus();
            }
        } else if (web3State === I18n.t('connectYourWallet')) {
            this.props.onConnectWallet();
            localStorage.saveWalletConnected(true);
        }
    };

    public render = () => {
        return (
            <Wrapp>
                <Max>
                    <Left>
                        <Ima src={WelcomeImage} />
                    </Left>
                    <News>
                        <H1>{I18n.t('disruptiveInnovative')}</H1>
                        <h3>{I18n.t('welcomeToOnDex')}</h3>
                        <P>{I18n.t('buySellPlatfrom')}</P>
                        <P>{I18n.t('supportTokenTrading')}</P>
                    </News>
                </Max>
            </Wrapp>
        );
    };
}

const mapStateToProps = (state: StoreState): StateProps => {
    return {
        ethAccount: getEthAccount(state),
        web3State: getWeb3State(state),
    };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
    return {
        goToHomePage: () => dispatch(goToHome()),
        onGoToWalletPage: () => dispatch(goToWallet()),
        onConnectWallet: () => dispatch(initWallet()),
    };
};

const Welcome = connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);

export { Welcome };
