
import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div``
const Faqs = styled.div``
const Head = styled.h3``
const Question = styled.h3``
const Answer = styled.p``

export const vi =
    <Wrap>
        <Head>Tạo ví</Head>
        <Faqs>
            <Question>1.1 Tạo một tài khoản Metamask mới.</Question>
            <Answer>Vào trang web của Metamask.<br />
        - Nhấn vào “Get Chrome Extension” để cài Metamask.<br />
        - Nhấn vào “Add to Chrome” ở góc trên bên phải.<br />
        - Nhấn vào “Add Extension” để hoàn tất việc cài đặt.<br />
            </Answer>
        </Faqs>
        <Faqs>
            <Question>1.2  Sử dụng Metamask</Question>
            <Answer>
                Nhấp vào biểu tượng Metamask ở góc trên bên phải của trình duyệt Google Chrome của bạn.<br />
            Đọc và đồng ý với các điều khoản và điều kiện. Bạn có thể phải đồng ý với các điều khoản có giá trị từ 2 đến 3 trang.<br />
            Nhập mật khẩu và nhấp vào “Create” để tạo ví của bạn.<br />
            Bạn sẽ thấy một bộ 12 từ hạt giống cho ví của bạn. Nhấp vào “Save Seed Words as File” và sao chép tập tin “MetaMask Seed Words” được tải xuống một nơi an toàn. Bạn sẽ cần nó để truy cập vào kho tiền của bạn.<br />
            Nhấp vào “I’ve Copied It Somewhere Safe” khi một tập tin từ gốc của bạn đã được bảo mật. Bạn sẽ được đưa vào ví Metamask của bạn!<br />
            </Answer>
        </Faqs>
        <Faqs>
            <Question>1.3 Nhập ví có sẵn</Question>
            <Answer>
                - Nếu bạn đã có ví ETH, bạn chỉ cần nhập nó vào Metamask.<br />
            - Nhấp vào tab ” import”.<br />
            - Sao chép và dán khóa riêng của ví của bạn.<br />
            - Nhấp vào nút “import”.<br />
            </Answer>
        </Faqs>
        <Faqs>
            <Question>1.4 Kết nối ví với DEX</Question>
            <Answer>
                - Nhấp vào một cặp giao dịch bất kì sẽ dẫn bạn đến cửa sổ chính của sàn.<br />
            - Ở góc trên bên trái, bạn có thể bấm vào  “Kết nối Metamask”.
            <br />
            - Gõ mật khẩu của bạn.
            <br />
            Nhấn vào "Đăng nhập".
            <br />
            - Kết nối tài khoản của bạn bằng cách nhấp vào “Kết nối”.
            <br />
            - Màu xanh ánh sáng cho biết ví của bạn đã được kết nối.
            <br />
            - Bây giờ bạn có thể thấy số dư ví của mình cho cặp đã chọn ở góc trên cùng bên trái.
            <br />

            </Answer>
        </Faqs>
        <Head>2. Mở khóa token
        </Head>
        <Faqs>
            <Question>Để giao dịch, bạn cần mở khóa chúng.</Question>
            <Answer>
                Truy cập vào "Ví của tôi" và nhấp vào biểu tượng ổ khóa.
            <br />
            - Xác nhận Mở khóa trong Metamask.
            <br />
            - Token hiện đã được mở khóa và có thể được giao dịch (ETH).
            <br />
            - Thực hiện tương tự để mở khóa token bạn muốn giao dịch với (ZRX) trong trường hợp này.
            <br />
            </Answer>
        </Faqs>
        <Faqs>
            <Question></Question>
            <Answer></Answer>
        </Faqs>
        <Faqs>
            <Question></Question>
            <Answer></Answer>
        </Faqs>
    </Wrap>
