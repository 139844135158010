import React, { HTMLAttributes } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { getEthAccount, getWeb3State, getLanguage } from '../../../store/selectors';
// import { truncateAddress } from '../../../util/number_utils';
import { StoreState, ButtonVariant } from '../../../util/types';
import { WalletConnectionStatusContainer } from '../../account/wallet_connection_status';
import { CardBase } from '../../common/card_base';
// import { DropdownTextItemArrow } from '../../common/dropdown_text_item';
import LanguageService from '../../../i18n';
import { goToHome, goToWallet, goToUserHistory } from '../../../store/router/actions';
import { NETWORK_NAME, METAMASK_EXTENSION_URL, NotInstalled, Error, Locked, Loading, NETWORK_ID } from '../../../common/constants';
// import install_metamask from '../../../assets/icons/install_metamask.svg';
import { Button } from '../../common/button';
import { LocalStorage } from '../../../services/local_storage';
import { initWallet } from '../../../store/actions';
import { themeBreakPoints } from '../../../themes/commons';
import { isMobileOnly } from "react-device-detect";
import { ERC20_APP_BASE_PATH } from '../../../common/constants';

const localStorage = new LocalStorage(window.localStorage);
const I18n = require('i18n-js');

interface OwnProps extends HTMLAttributes<HTMLSpanElement> { }

interface StateProps {
    ethAccount: string;
    web3State: string;
    language: string;
}

interface DispatchProps {
    goToHomePage: () => any;
    onGoToWalletPage: () => any;
    goToUserHistoryPage: () => any;
    onConnectWallet: () => any;
}

type Props = StateProps & DispatchProps;

// const connectToWallet = () => {
//     alert('connect to another wallet');
// };

// const goToURL = () => {
//     alert('go to url');
// };
const widthScreen = isMobileOnly && window ? `${window.innerWidth}px` : '350px';
const innerWidth = isMobileOnly && window ? `${window.innerWidth}px` : 'auto';
const DropdownItems = styled(CardBase)`
    box-shadow: ${props => props.theme.componentsTheme.boxShadow};
    min-width: ${isMobileOnly ? innerWidth : '170px'};
    @media (max-width: ${themeBreakPoints.sm}) {
        position: fixed;
        top: ${isMobileOnly ? '59px' : '75px'};
        left: 0;
    }
    position: ${ isMobileOnly ? 'fixed' : 'initial'};
    top: ${ isMobileOnly ? '75px' : 'initial'};
    left: ${ isMobileOnly ? '0' : 'initial'};
    background: ${props => props.theme.componentsTheme.cardBackgroundColor};
    color: ${props => props.theme.componentsTheme.textColorCommon};
`;

// const Img = styled.img`
//     width: 20px;
//     margin: 0 10px 0 0;
// `;

const NoMetaMask = styled.div`
    width: ${widthScreen};
    text-align: center;
    background: ${props => props.theme.componentsTheme.cardBackgroundColor};
    padding: 0;
    box-sizing: content-box;
    border: 1px solid ${props => props.theme.componentsTheme.borderColor}
    @media (max-width: ${themeBreakPoints.sm}) {
        position: fixed;
        top: 75px;
        left: 0;
    }
    position: ${ isMobileOnly ? 'fixed' : 'initial'};
    top: ${ isMobileOnly ? '75px' : 'initial'};
    left: ${ isMobileOnly ? '0' : 'initial'};
`;

const ButtonStyled = styled(Button)`
    margin: 20px;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
`;

const P = styled.p`
    padding: 10px;
    font-size: 16px;
    color: ${props => props.theme.componentsTheme.textColorCommon};
`
// const Setting = styled.div`
//     padding: 20px 10px;
//     text-transform: uppercase;
//     color: ${props => props.theme.componentsTheme.thColor};
//     border-bottom: 1px solid ${props => props.theme.componentsTheme.tableBorderColor};
// `

const PHead = styled.p`
    padding: 20px;
    border-bottom: 1px solid ${props => props.theme.componentsTheme.tableBorderColor};
    color: ${props => props.theme.componentsTheme.thColor};
    margin: 0 0 20px 0;
    text-align: left;
    text-transform: uppercase;
`
const Link = styled.a`
    display: block;
    padding: 10px;
    :hover {
        background: ${props => props.theme.componentsTheme.backgroundColorMarketOdd};
    }
`
const ImgWallet = styled.img`
    margin: 0 10px 0 0 ;
`
class WalletConnectionContent extends React.Component<Props> {

    changeLanguage = (lang: string) => {
        LanguageService.setLanguage(lang);
        this.props.goToHomePage();
    }

    public handleMyWalletClick = (path: number) => {
        if (path === 1) this.props.onGoToWalletPage();
        else if (path === 2) this.props.goToUserHistoryPage();
    };

    public openMetaMask: React.EventHandler<React.MouseEvent> = e => {
        this.props.onConnectWallet();
        localStorage.saveWalletConnected(true);
    };

    public openMetamaskExtensionUrl = () => {
        const win = window.open(METAMASK_EXTENSION_URL, '_blank');
        if (win) {
            win.focus();
        }
    }
    public render = () => {
        const { ethAccount, web3State, ...restProps } = this.props;
        const ethAccountText = I18n.t('balances');//isMobile ? I18n.t('wallet') : (ethAccount ? <><Img src={install_metamask} /> {I18n.t('wallet')}</> : I18n.t('connectAWallet'));
        const { ethereum } = window;
        let networkVersion = NETWORK_ID;
        if (ethereum && ethereum.networkVersion) {
            networkVersion = ethereum.networkVersion
        }
        const content = (
            <DropdownItems>
                {/* <CopyToClipboard text={ethAccount ? ethAccount : ''}>
                    <DropdownTextItem text={I18n.t('copyAddressToClipboard')} />
                </CopyToClipboard> */}
                {/* <Setting>{I18n.t('wallet')}</Setting> */}
                {/* <DropdownTextItemArrow onClick={() => this.handleMyWalletClick(1)} text={I18n.t('myWallet')} /> */}
                {/* <DropdownTextItemArrow onClick={() => this.handleMyWalletClick(2)} text={I18n.t('myHistory')} /> */}
                <Link onClick={scrollTop} href={`#${ERC20_APP_BASE_PATH}/my-wallet`}>
                    <ImgWallet src={require('../../../assets/images/MyWallet.png')} />{I18n.t('myWallet')}
                </Link>
                <Link onClick={scrollTop} href={`#${ERC20_APP_BASE_PATH}/user-history`}>
                    <ImgWallet src={require('../../../assets/images/History.png')} />{I18n.t('myHistory')}
                </Link>
                {/* <DropdownTextItem onClick={connectToWallet} text={I18n.t('connectADifferentWallet')} /> */}
                {/* <DropdownTextItem onClick={goToURL} text={I18n.t('manageAccount')} /> */}
                {/* <DropdownTextItem onClick={() => this.changeLanguage('en')} text={I18n.t('en')} /> */}
                {/* <DropdownTextItem onClick={() => this.changeLanguage('ja')} text={I18n.t('ja')} /> */}
            </DropdownItems>
        );
        let notConnected = null;
        if (web3State === NotInstalled) {
            notConnected = (<NoMetaMask>
                <PHead>{I18n.t('wallet')}</PHead>
                <P>{I18n.t('notInstalledMetamask')}</P>
                <ButtonStyled variant={ButtonVariant.Tertiary} onClick={() => this.openMetamaskExtensionUrl()}>{I18n.t('getChromeExtension')}</ButtonStyled>
            </NoMetaMask>)
        }
        else if (web3State === Locked) {
            notConnected = (<NoMetaMask>
                <PHead>{I18n.t('wallet')}</PHead>
                <P>{I18n.t('unlockMetaMask')}</P>
                <ButtonStyled variant={ButtonVariant.Tertiary} onClick={this.openMetaMask}>{I18n.t('connectYourWallet')}</ButtonStyled>
            </NoMetaMask>)
        }
        else if (+networkVersion !== +NETWORK_ID) {
            notConnected = (<NoMetaMask>
                <PHead>{I18n.t('wallet')}</PHead>
                <P>{I18n.t('wrongNetWork', { NETWORK_NAME })}</P>
            </NoMetaMask>)
        }
        else if (web3State === Error || web3State === Loading) {
            notConnected = (<NoMetaMask>
                <PHead>{I18n.t('wallet')}</PHead>
                <P>{I18n.t('waitLoadWallet')}</P>
            </NoMetaMask>)
        }
        return (
            <WalletConnectionStatusContainer
                walletConnectionContent={notConnected ? notConnected : content}
                headerText={ethAccountText}
                ethAccount={ethAccount}
                {...restProps}
            />
        );
    };
}

const mapStateToProps = (state: StoreState): StateProps => {
    return {
        ethAccount: getEthAccount(state),
        web3State: getWeb3State(state),
        language: getLanguage(state)
    };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
    return {
        goToHomePage: () => dispatch(goToHome()),
        onGoToWalletPage: () => dispatch(goToWallet()),
        goToUserHistoryPage: () => dispatch(goToUserHistory()),
        onConnectWallet: () => dispatch(initWallet()),
    };
};

const WalletConnectionContentContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(WalletConnectionContent);

const scrollTop = () => {
    window.scrollTo(0, 0);
}
export { WalletConnectionContent, WalletConnectionContentContainer };
