import { SignedOrder } from '@0x/types';
import { BigNumber } from '@0x/utils';
import React from 'react';
import { connect } from 'react-redux';

import { InsufficientFeeBalanceException } from '../../../exceptions/insufficient_fee_balance_exception';
import { InsufficientTokenBalanceException } from '../../../exceptions/insufficient_token_balance_exception';
import { SignatureFailedException } from '../../../exceptions/signature_failed_exception';
import { createSignedOrder, submitLimitOrder } from '../../../store/actions';
import { getEstimatedTxTimeMs, getStepsModalCurrentStep } from '../../../store/selectors';
import { tokenSymbolToDisplayString } from '../../../util/tokens';
import { OrderSide, StepBuySellLimitOrder, StoreState } from '../../../util/types';

import { BaseStepModal } from './base_step_modal';
import { StepItem } from './steps_progress';
import { isMobile } from "react-device-detect";

const I18n = require('i18n-js');

interface OwnProps {
    buildStepsProgress: (currentStepItem: StepItem) => StepItem[];
}
interface StateProps {
    estimatedTxTimeMs: number;
    step: StepBuySellLimitOrder;
}

interface DispatchProps {
    createSignedOrder: (amount: BigNumber, price: BigNumber, side: OrderSide, expire: number, takerAddress?: string) => Promise<SignedOrder>;
    submitLimitOrder: (signedOrder: SignedOrder, amount: BigNumber, side: OrderSide) => Promise<any>;
}

interface State {
    errorMsg: string;
}

type Props = OwnProps & StateProps & DispatchProps;

class SignOrderStep extends React.Component<Props, State> {
    public state = {
        errorMsg: I18n.t('errorSunmitOrder'),
    };
    public render = () => {
        const { buildStepsProgress, estimatedTxTimeMs, step } = this.props;

        const isBuy = step.side === OrderSide.Buy;
        const meta = isMobile ? 'Metamask' : '...';
        const title = I18n.t('orderSetup');
        const confirmCaption = I18n.t('confirmSignatureMetamask').replace(meta, 'dApp');
        const loadingCaption = I18n.t('submitOrder');
        const tokenSymbol = tokenSymbolToDisplayString(step.token.symbol);
        const doneCaption = isBuy ? I18n.t('buyOrderTokenSymbol', { tokenSymbol }) : I18n.t('sellOrderTokenSymbol', { tokenSymbol });
        // const doneCaption = `${isBuy ? 'Buy' : 'Sell'} order for ${tokenSymbolToDisplayString(
        //     step.token.symbol,
        // )} placed! (may not be filled immediately)`;
        const errorCaption = this.state.errorMsg;
        const loadingFooterCaption = I18n.t('waitSignature');
        const doneFooterCaption = I18n.t('orderPlace');

        return (
            <BaseStepModal
                buildStepsProgress={buildStepsProgress}
                confirmCaption={confirmCaption}
                doneCaption={doneCaption}
                doneFooterCaption={doneFooterCaption}
                errorCaption={errorCaption}
                estimatedTxTimeMs={estimatedTxTimeMs}
                loadingCaption={loadingCaption}
                loadingFooterCaption={loadingFooterCaption}
                runAction={this._getSignedOrder}
                step={step}
                title={title}
            />
        );
    };

    private readonly _getSignedOrder = async ({ onLoading, onDone, onError }: any) => {
        const { step } = this.props;
        const { amount, price, side, expire, takerAddress } = step;
        try {
            const signedOrder = await this.props.createSignedOrder(amount, price, side, expire, takerAddress);
            onLoading();
            await this.props.submitLimitOrder(signedOrder, amount, side);
            onDone();
        } catch (error) {
            let errorException = error;
            if (error.message.toLowerCase() === I18n.t('insufficientMakerBalance').toLowerCase()) {
                // Maker balance not enough
                errorException = new InsufficientTokenBalanceException(step.token.symbol);
            } else if (error.message.toString().includes(I18n.t('insufficientMakerFeeBalance'))) {
                // Fee balance not enough
                errorException = new InsufficientFeeBalanceException();
            } else if (error.message.toString().includes(I18n.t('userDeniedMessageSignature'))) {
                // User denied signature
                errorException = new SignatureFailedException(error);
            }

            let errorMsg = errorException.message;
            if (errorException.code === 4001) {
                errorMsg = I18n.t('userDeniedMessageSignature');
            }
            this.setState(
                {
                    errorMsg,
                },
                () => onError(errorException),
            );
        }
    };
}

const mapStateToProps = (state: StoreState): StateProps => {
    return {
        estimatedTxTimeMs: getEstimatedTxTimeMs(state),
        step: getStepsModalCurrentStep(state) as StepBuySellLimitOrder,
    };
};

const SignOrderStepContainer = connect(
    mapStateToProps,
    (dispatch: any) => {
        return {
            submitLimitOrder: (signedOrder: SignedOrder, amount: BigNumber, side: OrderSide) =>
                dispatch(submitLimitOrder(signedOrder, amount, side)),
            createSignedOrder: (amount: BigNumber, price: BigNumber, side: OrderSide, expire: number, takerAddress?: string) =>
                dispatch(createSignedOrder(amount, price, side, expire, takerAddress)),
        };
    },
)(SignOrderStep);

export { SignOrderStep, SignOrderStepContainer };
