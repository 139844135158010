
import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';

const Wrap = styled.div``
const Faqs = styled.div``
const Question = styled.span``
const Answer = styled.div`
    background: #ddd9d9;
    padding: 10px;
    margin: 0;
    width: 100%
`

export const vi =
    <Wrap>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>1. W-DEX là gì?</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>W DEX là một trao đổi phi tập trung được xây dựng bởi hệ sinh thái Wowoo để bảo vệ người dùng bằng cách cho phép người dùng của chúng tôi giao dịch một cách tự tin, có toàn quyền kiểm soát tiền và dữ liệu cá nhân của họ giống như cách Satoshi Nakamoto hình dung về nó khi ban đầu xây dựng blockchain Bitcoin. Nó được xây dựng trên giao thức 0x, kết nối các nhà giao dịch từ khắp nơi trên thế giới. Các nhà giao dịch có thể trao đổi mã ETH hoặc token ERC20 một cách an toàn và liền mạch nhất. Người dùng có thể giữ khóa riêng của họ và giao dịch trực tiếp từ ví của họ. Đến bây giờ, DEX của chúng tôi hỗ trợ ví Metamask và Infinito. Số lượng các tính năng sẽ tiếp tục phát triển và có thể sẽ tích hợp các mã chứng khoáng trong tương lai gần.</Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>2. Sự khác nhau giữa sàn phi tập trung và sàn tập trung là gì?</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div>
                    <Answer>Sàn tập trung là các sàn giao dịch hoạt động như môi giới truyền thống hoặc thị trường chứng khoán. Một sàn giao dịch tiền điện tử tập trung được sở hữu và vận hành bởi một công ty có toàn quyền kiểm soát tất cả các giao dịch.</Answer>
                    <Answer>Người dùng sàn tiền điện tử tập trung không có quyền truy cập vào khóa riêng trong ví của tài khoản trao đổi của họ. Điều này mang lại tất cả niềm tin của người dùng trong tay các nhà điều hành của sàn, vì các giao dịch chỉ có thể được thực hiện thông qua các cơ chế được cung cấp và phê duyệt bởi một cơ quan trung ương.</Answer>
                    <Answer>Các sàn phi tập trung như W DEX sử dụng công nghệ blockchain (sổ cái phân tán) như tiền điện tử. Theo cách này, một sàn phi tập trung không nắm giữ tiền, vị trí hoặc thông tin của khách hàng và chỉ đóng vai trò là lớp khớp và định tuyến cho các lệnh giao dịch.</Answer>
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>3. Ví Metamask là gì?</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>MetaMask là một cây cầu cho phép bạn truy cập web phân tán của ngày mai trong trình duyệt của bạn ngày hôm nay. Nó cho phép bạn chạy ứng dụng phi tập trung trên blockchain Ethereum ngay trong trình duyệt của mình mà không cần chạy nút Ethereum đầy đủ để giúp Ethereum dễ sử dụng cho càng nhiều người càng tốt.</Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>4. Phí giao dịch trên W-DEX là gì?</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>Wowoo DEX tính phí 0,2% cho người khớp lệnh và 0,1% cho người tạo lệnh. Người dùng cũng được yêu cầu trả phí gas để đưa giao dịch của họ lên blockchain.</Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>5. Người tạo lệnh và khớp lệnh là gì?</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>Các nhà tạo lập lệnh đặt một giao dịch mới trên sách và chờ người dùng khác khớp chúng. Họ có thể là người mua hoặc người bán. Những người thực hiện khớp lệnh tìm và khớp vào các giao giao dịch hiện có trên sổ lệnh, do đó nhận giao dịch từ những cuốn sổ lệnh.</Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>6. Phí gas là gì?</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>Gas liên quan đến phí hoặc giá trị giá được yêu cầu trên nền tảng blockchain Ethereum để thực hiện thành công giao dịch hoặc thực hiện hợp đồng.</Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>7. Cần bao nhiêu ga để thực hiện một giao dịch?</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>Người dùng có trách nhiệm trả tất cả các khoản phí gas cần thiết để sử dụng sàn. Phí gas được thanh toán trực tiếp bởi người dùng. W DEX trả phí gas cho mạng cho tất cả các giao dịch và rút tiền khác, và khấu trừ khoản phí này từ giao dịch của người dùng. Điều này bao gồm cả phí gas mà người thực hiện phải trả cho các giao dịch và phí gas mà khách hàng phải trả khi rút tiền. Lệ phí được lấy ra khỏi tài sản được giao dịch / rút tiền cho các giao dịch và rút tiền. Khi rút tiền, số tiền phí được tính bằng tỷ giá hối đoái / ETH từ 10 giao dịch gần nhất.</Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <Faqs>
            <Question></Question>

        </Faqs>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>8. Tài sản số của tôi trên W-DEX có an toàn không?</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div>
                    <Answer>Có, tài sản kỹ thuật số của bạn an toàn trên W DEX vì tất cả các khoản tiền được giữ trong ví W DEX luôn nằm trong tầm kiểm soát của người dùng. Nếu không có quyền ký mật mã đầu tiên của bạn cho W DEX, W DEX không thể giao dịch, rút tiền hoặc thực hiện bất kỳ giao dịch nào khác bằng tiền của bạn. </Answer>
                    <Answer>Vì khóa W DEX là khóa duy nhất được phép gửi giao dịch rút tiền, chúng tôi đã bao gồm một cửa thoát cho phép người dùng rút tiền trực tiếp từ hợp đồng thông minh sau một thời gian không hoạt động. Điều này đảm bảo rằng người dùng vẫn có thể rút tiền từ hợp đồng mặc dù các máy chủ W DEX không khả dụng.</Answer>
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>9. Mã WWX là gì?</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>Mã WWX là mã tiện ích. Người nắm giữ sẽ được giảm giá đáng kể khi thanh toán phí giao dịch bằng cách sử dụng mã WWX. Mã sẽ hoạt động như một mã giảm giá trong suốt 5 năm sau khi phân phối ban đầu, với mức chiết khấu giảm dần sau mỗi năm. Chủ sở hữu mã WWX sẽ nhận được phản hồi ưu tiên từ các đại lý hỗ trợ khách hàng WWX. Chủ sở hữu mã WWX cũng sẽ có quyền biểu quyết đối với một số tài sản kỹ thuật số được liệt kê và các dự án để giới thiệu cho nền tảng Wowoo.</Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>10. W DEX có hỗ trợ đưa lên sàn các mã chứng khoáng</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>W DEX hiện tại hỗ trỡ các mã chứng khoáng theo tiêu chuần ST20</Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>

    </Wrap>
