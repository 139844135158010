import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getLanguage } from '../../../store/selectors';
import { StoreState } from '../../../util/types';
import { CONTACTUS } from '../../../common/constants';
import axios from 'axios';

const I18n = require('i18n-js');

interface Props {
    language: string;
}
interface State {
    error?: string;
    email?: string;
    subject?: string,
    description?: string
}

const Content = styled.div`
    margin: 0 auto;
    width: 90%;
`;

const News = styled.div`
    margin: 100px auto;
    color: ${props => props.theme.componentsTheme.myWalletLinkColor};
`

const H1 = styled.h1`
    text-align: center;
`

class _App extends React.Component<Props, State> {
    constructor(props: any) {
        super(props)
        this.state = {
            email: '',
            subject: '',
            description: '',
            error: ''
        }
    }
    public onSubmit = async () => {
        const url = CONTACTUS;
        const body = JSON.stringify({
            email: this.state.email,
            typeSupport: "typeSupport",
            ticketType: "ticketType",
            addressSupport: "addressSupport",
            subject: this.state.subject,
            description: this.state.description,
            attachments: "string"
        })
        const response = await axios.post(url, body, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (response && response.data === "") {
            this.setState({
                email: "",
                subject: "",
                description: ""
            });
            alert("Check your email: " + this.state.email);
        }
    }

    public render = () => {
        const { email, subject, description } = this.state;

        return (
            <Content>
                <News>
                    <H1>{I18n.t('support')}</H1>
                    <div>
                        <p>Title</p>
                        <input value={subject} onChange={(e) => this.setState({ subject: e.target.value })} />
                    </div>
                    <div>
                        <p>Email</p>
                        <input value={email} onChange={(e) => this.setState({ email: e.target.value })} />
                    </div>
                    <div>
                        <p>Description</p>
                        <textarea value={description} rows={4} cols={50} onChange={(e) => this.setState({ description: e.target.value })} >
                        </textarea>
                    </div>
                    <div>
                        <button onClick={() => this.onSubmit()}>Submit</button>
                    </div>
                </News>
            </Content>
        );
    };
}

const mapStateToProps = (state: StoreState): Props => {
    return {
        language: getLanguage(state)
    };
};

const App = connect(
    mapStateToProps,
    null,
)(_App);
export default App;