import React from 'react';
import { connect } from 'react-redux';
import { getLanguage } from '../../../store/selectors';
import { StoreState } from '../../../util/types';
import styled from 'styled-components';
const I18n = require('i18n-js');

interface Props {
    language: string
}

const Wrap = styled.div`
    margin: 20% 5% 20px 5%;
    text-align: center;
    color: ${props => props.theme.componentsTheme.textColorCommon};
`

const Head = styled.h1`
    text-align: center;
`
const Faqs = styled.div`
`
const Question = styled.h3`
`

class _App extends React.Component<Props> {

    public render = () => {

        return (
            <Wrap>
                <Head>404</Head>
                <Faqs>
                    <Question>{I18n.t('pageNotFound')}</Question>
                </Faqs>
            </Wrap>

        );
    };
}

const mapStateToProps = (state: StoreState): Props => {
    return {
        language: getLanguage(state)
    };
};

const App = connect(
    mapStateToProps,
    null,
)(_App);
export default App;
