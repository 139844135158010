import React from 'react';
import styled from 'styled-components';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Div = styled.div``
const H3 = styled.h3``
const P = styled.p``
const Wrap = styled.div``
const Answer = styled.div`
    background: #ddd9d9;
    padding: 10px;
    margin: 0;
    width: 100%
`
const Question = styled.span``

export const jp = <Wrap>
    <ExpansionPanel>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography className={'d'}>
                <Question>あなた自身での鍵の管理
                </Question>
            </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <Answer>
                <P>W-DEX側であなたの秘密鍵のアクセスを持つことはありません。 私たちは秘密鍵の概念がクリプトの核であることを十分に理解しています。 しかし、これらのコインとトークンは分散化されているため、秘密鍵を安全に保つことは不可欠です。 PINを紛失しても、銀行に新しいPINを要求するだけで済むので、被害を生むことありませんが、秘密鍵を置き忘れると、資金に永久にアクセスできなくなる可能性があります。
                </P>
            </Answer>
        </ExpansionPanelDetails>
    </ExpansionPanel>

    <ExpansionPanel>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography className={'d'}>
                <Question>透明性の高いコスト
                </Question>
            </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <Answer>
                <P>分散型取引所で取引する場合、それは基本的にピアツーピアで取引していることを意味し、お同時にW-DEXが競争力のあるレベルで手数料を維持できるようになります。 W-DEXは、分散型取引所テクノロジーの認知度を高め、大量の受容者を促進するために引き続き努力します。暗号化取引には隠れたコストが含まれないはずだからです。
                </P>
            </Answer>
        </ExpansionPanelDetails>
    </ExpansionPanel>

    <ExpansionPanel>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography className={'d'}>
                <Question>安全な取引
                </Question>
            </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <Answer>
                <P>W-DEXは、ユーザーが安全にピアツーピア（P2P）で取引できるようにすることで、集中交換を悩ませるセキュリティ上の欠陥を排除します。 仲介者は存在せず、ユーザーはデジタル資産の制御権を放棄する必要はありません。 実際、資産は取引所に保持されないため、単純な障害やトラブルは無くなります。
                </P>
            </Answer>
        </ExpansionPanelDetails>
    </ExpansionPanel>

    <ExpansionPanel>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography className={'d'}>
                <Question>業界をリードする分散型取引所
                </Question>
            </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <Answer>
                <P>W-DEX分散型取引所では、WWXトークン、USDT、および多くのERC-20を取引できます。 W-DEXは、業界をリードする分散型取引所として機能することを主な目的として、ますます多様なデジタル資産をW-DEXに上場し、将来のクロスチェーンソリューションを活用することにより幅広いトレーディングを可能とすることを目指しています。 また、W-DEXは資産管理者ではありません。つまり、ユーザーは常に自身の資産を管理できます。
                </P>
            </Answer>
        </ExpansionPanelDetails>
    </ExpansionPanel>
</Wrap>
