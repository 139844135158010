import { BigNumber } from '@0x/utils';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { METAMASK_EXTENSION_URL } from '../../../common/constants';
import { initWallet, goToHome } from '../../../store/actions';
import {
    getBaseToken,
    getBaseTokenBalance,
    getCurrencyPair,
    getEthAccount,
    getQuoteToken,
    getQuoteTokenBalance,
    getTotalEthBalance,
    getWeb3State,
} from '../../../store/selectors';
import { NETWORK_NAME } from '../../../common/constants';
import { isWeth } from '../../../util/known_tokens';
import { tokenAmountInUnits, tokenSymbolToDisplayString } from '../../../util/tokens';
import { ButtonVariant, CurrencyPair, StoreState, Token, TokenBalance, Web3State } from '../../../util/types';
import { Button } from '../../common/button';
import { Card } from '../../common/card';
import { ErrorCard, ErrorIcons, FontSize } from '../../common/error_card';
import { IconType, Tooltip } from '../../common/tooltip';
import { isMobile } from "react-device-detect";
import { Socket } from './../../../services/socket';
import { assetDataUtils } from '@0x/order-utils';

const config = require('../../../config.json');
const errorWarningLine = require('../../../assets/images/error-warning-line.png')

const I18n = require('i18n-js');
const Sockets = new Socket();

const LabelWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    padding: 8px 0;
`;

const Label = styled.span`
    align-items: center;
    color: ${props => props.theme.componentsTheme.textColorCommon};
    display: flex;
    flex-shrink: 0;
    font-size: 13px;
    line-height: 1.2;
`;

const Value = styled.span`
    color: ${props => props.theme.componentsTheme.textColorCommon};
    font-feature-settings: 'tnum' 1;
    flex-shrink: 0;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.2;
    text-align: right;
    white-space: nowrap;
`;

// const WalletStatusBadge = styled.div<{ web3State?: Web3State }>`
//     background-color: ${props =>
//         props.web3State === Web3State.Done
//             ? props.theme.componentsTheme.green
//             : props.theme.componentsTheme.errorButtonBackground};
//     border-radius: 50%;
//     height: 8px;
//     margin-right: 6px;
//     width: 8px;
// `;

// const WalletStatusTitle = styled.h3`
//     color: ${props => props.theme.componentsTheme.textLight};
//     font-size: 13px;
//     font-weight: 500;
//     line-height: 1.2;
//     margin: 0;
//     padding: 0;
//     text-align: right;
// `;

const WalletStatusContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

const TooltipStyled = styled(Tooltip)`
    margin-left: 10px;
`;

const TokenType = styled.span`
    font-size: 10px;
    text-transform: capitalize;
    color: #aaa;
`
interface ErrorCardStyledProps {
    cursor?: string;
}

const ErrorCardStyled = styled(ErrorCard) <ErrorCardStyledProps>`
    cursor: ${props => props.cursor};
    /* position: absolute;
    top: 50%;
    transform: translateY(-50%); */
    width: 100%;
    z-index: 5;
    background-color: transparent;
`;

ErrorCardStyled.defaultProps = {
    cursor: 'pointer',
};

const WalletErrorContainer = styled.div`
    height: 64px;
    position: relative;
`;

const WalletErrorText = styled.p`
    font-size: 13px;
    font-weight: normal;
    line-height: 23px;
    margin: 0;
    padding: 0;
`;

const SimplifiedTextBox = styled.div<{ top?: string; bottom?: string; left?: string; right?: string }>`
    ${props => (props.bottom ? `bottom: ${props.bottom};` : '')}
    ${props => (props.left ? `left: ${props.left};` : '')}
    ${props => (props.right ? `right: ${props.right};` : '')}
    ${props => (props.top ? `top: ${props.top};` : '')}
    position: absolute;
    z-index: 1;

    rect {
        fill: ${props => props.theme.componentsTheme.simplifiedTextBoxColor};
    }
`;

const ButtonStyled = styled(Button)`
    width: 100%;
`;
interface Prop {
    chide?: boolean;
}

interface StateProps {
    web3State: Web3State;
    currencyPair: CurrencyPair;
    baseToken: Token | null;
    quoteToken: Token | null;
    ethAccount: string;
    baseTokenBalance: TokenBalance | null;
    quoteTokenBalance: TokenBalance | null;
    totalEthBalance: BigNumber;
}

interface DispatchProps {
    onConnectWallet: () => any;
    OnPairSubcribe: (base: string, quote: string, address: string) => void;
    onGoToHome: () => any;
}

type Props = Prop & StateProps & DispatchProps;

interface State {
    quoteBalance: BigNumber;
    baseBalance: BigNumber;
}

const simplifiedTextBoxBig = () => {
    return (
        <svg width="67" height="14" viewBox="0 0 67 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="67" height="14" rx="4" />
        </svg>
    );
};

const simplifiedTextBoxSmall = () => {
    return (
        <svg width="56" height="14" viewBox="0 0 56 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="56" height="14" rx="4" />
        </svg>
    );
};

// const getWalletName = () => {
//     return isMobile ? '' : I18n.t('metamask');
// };

const getWallet = (web3State: Web3State) => {
    return (
        <WalletStatusContainer>
            {/* <WalletStatusBadge web3State={web3State} />
            <WalletStatusTitle>{getWalletName()}</WalletStatusTitle> */}
        </WalletStatusContainer>
    );
};

const getWalletTitle = (web3State: Web3State) => {
    let title = I18n.t('walletBalance');

    if (web3State === Web3State.NotInstalled) {
        title = I18n.t('noWallet');
    }

    return title;
};

const openMetamaskExtensionUrl = () => {
    const win = window.open(METAMASK_EXTENSION_URL, '_blank');
    if (win) {
        win.focus();
    }
};

class WalletBalance extends React.Component<Props, State> {
    public getTypeByToken = (token: string): string => {
        const { tokens } = config;
        for (let i = 0; i < tokens.length; i++) {
            if (tokens[i] && tokens[i]['symbol'].toLowerCase() === token.toLowerCase())
                return tokens[i]['type'];
        }
        return '';
    }

    public componentDidMount = () => {
        // Sockets.initSocket();
        const { onConnectWallet, baseToken, quoteToken, ethAccount, OnPairSubcribe } = this.props;
        if (baseToken && quoteToken && ethAccount) {
            const baseAssetData = assetDataUtils.encodeERC20AssetData(baseToken.address)
            const quoteAssetData = assetDataUtils.encodeERC20AssetData(quoteToken.address)
            OnPairSubcribe(baseAssetData, quoteAssetData, ethAccount);
            Sockets.subscribeHandle(ethAccount)
        }

        if (document.addEventListener) {
            document.addEventListener('accountsChanged', function (e) {
                onConnectWallet();
                // onGoToHome()
            }, false);
            document.addEventListener('networkChanged', function (e) {
                onConnectWallet();
                // onGoToHome()
            }, false);
        }
    }

    componentWillReceiveProps(nextProps: any) {
        const { baseToken, quoteToken, ethAccount, OnPairSubcribe } = this.props;
        if (baseToken && quoteToken) {
            if (ethAccount !== nextProps.ethAccount) {
                const baseAssetData = assetDataUtils.encodeERC20AssetData(baseToken.address)
                const quoteAssetData = assetDataUtils.encodeERC20AssetData(quoteToken.address)
                Sockets.subscribeHandle(nextProps.ethAccount)
                OnPairSubcribe(baseAssetData, quoteAssetData, nextProps.ethAccount);
            } else if (baseToken.symbol !== nextProps.baseToken.symbol || quoteToken.symbol !== nextProps.quoteToken.symbol) {
                const baseAssetData = assetDataUtils.encodeERC20AssetData(nextProps.baseToken.address)
                const quoteAssetData = assetDataUtils.encodeERC20AssetData(nextProps.quoteToken.address)
                OnPairSubcribe(baseAssetData, quoteAssetData, ethAccount);
            }
        }
    }

    public render = () => {
        const { web3State } = this.props;
        const walletContent = this._getWalletContent();
        const { infinitowallet, ethereum } = window;
        if (isMobile && (!ethereum || !infinitowallet)) return  null;
        // (
        //     <>
        //         <Card minHeightBody={'50'}>
        //             we are not supporting your browser
        //         </Card>
        //     </>
        // );
        return (
            <>
                {walletContent && <Card title={getWalletTitle(web3State)} action={getWallet(web3State)} minHeightBody={'0px'}>
                    {walletContent}
                </Card>}
            </>
        );
    };

    private readonly _getWalletContent = () => {
        let content: any = null;
        const {
            web3State,
            currencyPair,
            onConnectWallet,
            quoteToken,
            quoteTokenBalance,
            baseTokenBalance,
            totalEthBalance,
            chide
        } = this.props;

        if (quoteToken && baseTokenBalance && quoteTokenBalance) {
            const quoteTokenBalanceAmount = isWeth(quoteToken.symbol) ? totalEthBalance : quoteTokenBalance.balance;
            const quoteBalanceString = tokenAmountInUnits(
                quoteTokenBalanceAmount,
                quoteToken.decimals,
                quoteToken.displayDecimals,
            );
            const baseBalanceString = tokenAmountInUnits(
                baseTokenBalance.balance,
                baseTokenBalance.token.decimals,
                baseTokenBalance.token.displayDecimals,
            );
            const toolTip = isWeth(quoteToken.symbol) ? (
                <TooltipStyled description={I18n.t('showETHBalance')} iconType={IconType.Fill} />
            ) : null;
            const quoteTokenLabel = isWeth(quoteToken.symbol) ? 'ETH' : tokenSymbolToDisplayString(currencyPair.quote);
            content = (
                <>
                    <LabelWrapper>
                        <Label>{tokenSymbolToDisplayString(currencyPair.base)}{'  '} <TokenType>({this.getTypeByToken(currencyPair.base)})</TokenType></Label>
                        <Value>{+baseBalanceString}</Value>
                    </LabelWrapper>
                    <LabelWrapper>
                        <Label>
                            {quoteTokenLabel}
                            {toolTip}
                        </Label>
                        <Value>{+quoteBalanceString}</Value>
                    </LabelWrapper>
                </>
            );
            if (isMobile && chide === true) {
                content = null;
            }
        }

        if (web3State === Web3State.Locked) {
            content = (
                <WalletErrorContainer>
                    <ErrorCardStyled
                        fontSize={FontSize.Large}
                        icon={errorWarningLine}
                        onClick={onConnectWallet}
                        text={isMobile ? I18n.t('connectYourWallet') : I18n.t('clickConnectMetaMask')}
                        textAlign="center"
                    />
                    {/* <SimplifiedTextBox top="0" left="0">
                        {simplifiedTextBoxBig()}
                    </SimplifiedTextBox>
                    <SimplifiedTextBox top="0" right="0">
                        {simplifiedTextBoxBig()}
                    </SimplifiedTextBox>
                    <SimplifiedTextBox bottom="0" left="0">
                        {simplifiedTextBoxSmall()}
                    </SimplifiedTextBox>
                    <SimplifiedTextBox bottom="0" right="0">
                        {simplifiedTextBoxSmall()}
                    </SimplifiedTextBox> */}
                </WalletErrorContainer>
            );
        }

        if (web3State === Web3State.NotInstalled) {
            content = (
                <>
                    <WalletErrorText>{I18n.t('installMetaMask')}</WalletErrorText>
                    <ButtonStyled variant={ButtonVariant.Tertiary} onClick={openMetamaskExtensionUrl}>
                        {I18n.t('getChromeExtension')}
                    </ButtonStyled>
                </>
            );
        }

        if (web3State === Web3State.Loading) {
            content = (
                <>
                    <ButtonStyled variant={ButtonVariant.Tertiary}>{I18n.t('waitLoadWallet')}</ButtonStyled>
                </>
            );
        }

        if (web3State === Web3State.Error) {
            content = (
                <WalletErrorContainer>
                    <ErrorCardStyled
                        cursor={'default'}
                        fontSize={FontSize.Large}
                        icon={ErrorIcons.Warning}
                        text={I18n.t('wrongNetWork', { NETWORK_NAME })}
                        textAlign="center"
                    />
                    {/* <SimplifiedTextBox top="0" left="0">
                        {simplifiedTextBoxBig()}
                    </SimplifiedTextBox>
                    <SimplifiedTextBox top="0" right="0">
                        {simplifiedTextBoxBig()}
                    </SimplifiedTextBox>
                    <SimplifiedTextBox bottom="0" left="0">
                        {simplifiedTextBoxSmall()}
                    </SimplifiedTextBox>
                    <SimplifiedTextBox bottom="0" right="0">
                        {simplifiedTextBoxSmall()}
                    </SimplifiedTextBox> */}
                </WalletErrorContainer>
            );
        }

        return content;
    };
}

const mapStateToProps = (state: StoreState): StateProps => {
    return {
        web3State: getWeb3State(state),
        currencyPair: getCurrencyPair(state),
        baseToken: getBaseToken(state),
        quoteToken: getQuoteToken(state),
        ethAccount: getEthAccount(state),
        quoteTokenBalance: getQuoteTokenBalance(state),
        baseTokenBalance: getBaseTokenBalance(state),
        totalEthBalance: getTotalEthBalance(state),
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onConnectWallet: () => dispatch(initWallet()),
        OnPairSubcribe: (base: string, quote: string, address: string) => dispatch(Sockets.OnPairSubcribe(base, quote, address)),
        onGoToHome: () => dispatch(goToHome()),
    };
};

const WalletBalanceContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(WalletBalance);

export { WalletBalance, WalletBalanceContainer };
