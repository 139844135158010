import React, { HTMLAttributes } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { BigNumber } from '@0x/utils';
import { UI_DECIMALS_DISPLAYED_PRICE_ETH, UI_UPDATE_CHECK_INTERVAL } from '../../../common/constants';
import { tokenAmountInUnits } from '../../../util/tokens';
import {
    getBaseToken,
    getEstimatedTxTimeMs,
    getEthAccount,
    getQuoteToken,
    getTradeHistories,
    getWeb3State,
} from '../../../store/selectors';
import { Card } from '../../common/card';
import { EmptyContent } from '../../common/empty_content';
import { LoadingWrapper } from '../../common/loading';
import { CustomTD, Table, TH, THead, TR } from '../../common/table';
import { OrderSide, StoreState, Web3State } from '../../../util/types';
import { getTradeHistoryFromAPI } from '../../../store/ui/actions';
import { get24hVolumeAction } from '../../../store/actions';
import { assetDataUtils } from '@0x/order-utils';
import { fixBigNumber } from '../../../util/number_utils';

const I18n = require('i18n-js');

interface StateProps {
    estimatedTxTimeMs: number;
    tradeHistories: any;
    baseToken: any | null;
    quoteToken: any | null;
    web3State?: Web3State;
    ethAccount: string;
}

interface State {
    isApiLoading: boolean;
}

interface DispatchProps {
    getTradeHistoryFromAPI: (baseAssetData: string, quoteAssetData: string) => any;
    get24hVolume: (base: string, quote: string) => any,
}

type Props = HTMLAttributes<HTMLDivElement> & StateProps & DispatchProps;

const SideTD = styled(CustomTD) <{ side: OrderSide }>`
    color: ${props =>
    props.side === OrderSide.Buy ? props.theme.componentsTheme.green : props.theme.componentsTheme.red};
`;

const CustomTD1 = styled(CustomTD)`
    min-width: 85px;
`;
const orderToRow = (tradeHistories: any, index: number, baseToken: any, quoteToken: any) => {
    const filledAmount = new BigNumber(tradeHistories.filledAmount);
    const amount = tokenAmountInUnits(filledAmount, baseToken.decimals, baseToken.displayDecimals);
    let price = fixBigNumber(+new BigNumber(tradeHistories.price).toFixed(UI_DECIMALS_DISPLAYED_PRICE_ETH));
    price = +price === 0 ? fixBigNumber(new BigNumber(tradeHistories.price).toString()) : fixBigNumber(new BigNumber(tradeHistories.price).toFixed(UI_DECIMALS_DISPLAYED_PRICE_ETH));

    return (
        <TR key={index}>
            <SideTD styles={{ textAlign: 'left', tabular: true }} side={tradeHistories.side}>{+amount}</SideTD>
            <CustomTD styles={{ textAlign: 'right', tabular: true }}>{price}</CustomTD>
            <CustomTD1 styles={{
                textAlign: 'right',
                tabular: true,
            }}>{moment.unix(tradeHistories.orderedTime / 1000).format('HH:mm:ss')}</CustomTD1>
        </TR>
    );
};

class TradeHistoryList extends React.Component<Props, State, any> {
    private _updateTradeHistory: any | null;
    public state = {
        isApiLoading: false,
    };

    componentWillUnmount = () => {
        clearInterval(this._updateTradeHistory);
    };

    public componentDidMount = async () => {
        const { baseToken, quoteToken, getTradeHistoryFromAPI, get24hVolume } = this.props;
        if (baseToken || quoteToken) {
            const baseAssetData = assetDataUtils.encodeERC20AssetData(baseToken.address);
            const quoteAssetData = assetDataUtils.encodeERC20AssetData(quoteToken.address);
            this.setState({ isApiLoading: true });
            await getTradeHistoryFromAPI(baseAssetData, quoteAssetData);
            this.setState({ isApiLoading: false });
            if (UI_UPDATE_CHECK_INTERVAL !== 0) {
                this._updateTradeHistory = setInterval(async () => {
                    await getTradeHistoryFromAPI(baseAssetData, quoteAssetData);
                    await get24hVolume(baseAssetData, quoteAssetData);
                }, UI_UPDATE_CHECK_INTERVAL);
            }
        }
    };

    public componentWillReceiveProps = async (nextProps: any) => {
        const { baseToken, quoteToken, getTradeHistoryFromAPI, get24hVolume } = this.props;
        if ((nextProps.baseToken && nextProps.baseToken !== baseToken) || (nextProps.quoteToken && nextProps.quoteToken !== quoteToken)) {
            const baseAssetData = assetDataUtils.encodeERC20AssetData(nextProps.baseToken.address);
            const quoteAssetData = assetDataUtils.encodeERC20AssetData(nextProps.quoteToken.address);
            await getTradeHistoryFromAPI(baseAssetData, quoteAssetData);
            if (UI_UPDATE_CHECK_INTERVAL !== 0) {
                clearInterval(this._updateTradeHistory);
                this._updateTradeHistory = setInterval(async () => {
                    await getTradeHistoryFromAPI(baseAssetData, quoteAssetData);
                    await get24hVolume(baseAssetData, quoteAssetData);
                }, UI_UPDATE_CHECK_INTERVAL);
            }
        }
    };

    public render = () => {
        const { tradeHistories, baseToken, quoteToken } = this.props;
        let content: React.ReactNode;
        if (this.state.isApiLoading) {
            content = <LoadingWrapper minHeight="120px" />;
        } else if (!tradeHistories || tradeHistories.length < 1 || !baseToken || !quoteToken) {
            content = <EmptyContent alignAbsoluteCenter={true} text={I18n.t('noData')} />;
        } else if (tradeHistories.length > 0) {
            tradeHistories.sort((a: any, b: any) => {
                if (a.orderedTime > b.orderedTime) {
                    return -1;
                }
                if (a.orderedTime < b.orderedTime) {
                    return 1;
                }
                return 0;
            });
            content = (
                <Table isResponsive={true}>
                    <THead>
                        <TR>
                            <TH styles={{ textAlign: 'left' }}>{baseToken.symbol}</TH>
                            <TH styles={{ textAlign: 'right' }}> {quoteToken.symbol}</TH>
                            <TH styles={{ textAlign: 'right' }}>{I18n.t('time')}</TH>
                        </TR>
                    </THead>
                    <tbody>{tradeHistories.map((tradeHistories: any, index: number) => orderToRow(tradeHistories, index, baseToken, quoteToken))}</tbody>
                </Table>
            );
        }

        return <Card minHeightBody={'328px'} heightBody={'328px'} style={{ marginTop: '10px' }}
                     title={I18n.t('tradeHistory')}>{content}</Card>;
    };
}

const mapStateToProps = (state: StoreState): StateProps => {
    return {
        estimatedTxTimeMs: getEstimatedTxTimeMs(state),
        tradeHistories: getTradeHistories(state),
        baseToken: getBaseToken(state),
        quoteToken: getQuoteToken(state),
        web3State: getWeb3State(state),
        ethAccount: getEthAccount(state),
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getTradeHistoryFromAPI: (baseAssetData: string, quoteAssetData: string) =>
            dispatch(getTradeHistoryFromAPI(baseAssetData, quoteAssetData)),
        get24hVolume: (base: string = '', quote: string = '') => dispatch(get24hVolumeAction(base, quote)),
    };
};

const TradeHistoryContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TradeHistoryList);

export { TradeHistoryList, TradeHistoryContainer };
