import queryString from 'query-string';
import { getType } from 'typesafe-actions';

import { availableMarkets } from '../../common/markets';
import { MarketState } from '../../util/types';
import * as actions from '../actions';
import { RootAction } from '../reducers';
const config = require('../../config.json');

export const getPair = (): string => {
    const href = (window.location.hash || '/market?base=zrx&quote=dai').replace('/market?', '');
    const data = queryString.parse(href);
    if (data && data.base && data.quote) {
        return `${data.base}/${data.quote}`
    }
    return ''
}

export const getPara = (qr: string): string => {
    const href = (window.location.hash || '/market?base=zrx&quote=dai').replace('/market?', '');
    const data = queryString.parse(href);
    const { pairs } = config;
    if (pairs && pairs.length) {
        for (let i = 0; i < pairs.length; i++) {
            const p = pairs[i];
            if (p && data && p.base === data.base && p.quote === data.quote) {
                return data[qr] as string
            }
            else if (p && data && p.base === data.quote && p.quote === data.base) {
                const q = qr === 'base' ? 'quote' : 'base';
                return data[q] as string
            }
        }
    }
    return '';
}

const getMakerAddresses = () => {
    const makerAddressesString = queryString.parse(queryString.extract(window.location.hash)).makerAddresses as string;
    if (!makerAddressesString) {
        return null;
    }
    const makerAddresses = makerAddressesString.split(',');
    return makerAddresses.map(a => a.toLowerCase());
};

const initialMarketState: MarketState = {
    currencyPair: {
        base: getPara('base') || availableMarkets[0].base,
        quote: getPara('quote') || availableMarkets[0].quote,
    },
    baseToken: null,
    quoteToken: null,
    markets: null,
    ethInUsd: null,
    makerAddresses: getMakerAddresses(),
    volume: null,
    exchangeCharts: [],
    depthCharts: [],
    isRestrictionAmountBuy: true,
    isRestrictionAmountSell: true,
    isRestrictionAmount: true,
};

export function market(state: MarketState = initialMarketState, action: RootAction): MarketState {
    switch (action.type) {
        case getType(actions.setMarketTokens):
            return { ...state, baseToken: action.payload.baseToken, quoteToken: action.payload.quoteToken };
        case getType(actions.setCurrencyPair):
            return { ...state, currencyPair: action.payload };
        case getType(actions.setMarkets):
            return { ...state, markets: action.payload };
        case getType(actions.fetchMarketPriceEtherUpdate):
            return { ...state, ethInUsd: action.payload };
        case getType(actions.setIsRestriction):
            return { ...state, isRestrictionAmount: action.payload };
        case getType(actions.setIsRestrictionBuy):
            return { ...state, isRestrictionAmountBuy: action.payload };
        case getType(actions.setIsRestrictionSell):
            return { ...state, isRestrictionAmountSell: action.payload };
        case getType(actions.fetchMarketPriceEtherStart):
            return state;
        case getType(actions.fetchMarketPriceEtherError):
            return state;
        case getType(actions.setVolumeAction):
            if (action && action.payload) {
                const volume = { ...state.volume, ...action.payload }
                return { ...state, volume };
            }
            return { ...state };
        case getType(actions.setExchangeChartAction):
            if (action && action.payload) {
                return { ...state, exchangeCharts: action.payload };
            }
            return { ...state };
        case getType(actions.setDepthChartAction):
            if (action && action.payload) {
                return { ...state, depthCharts: action.payload };
            }
            return { ...state };
        default:
            return state;
    }
}
