import React from 'react';
import { connect } from 'react-redux';
import { getLanguage } from '../../../store/selectors';
import { StoreState } from '../../../util/types';
import styled from 'styled-components';

import { en } from './cookies/en';
import { vi } from './cookies/vi';
import { jp } from './cookies/jp';
const I18n = require('i18n-js');

interface Props {
    language: string
}
const Wrap = styled.div`
    margin: 20px 5%;
    text-align: left;
    color: ${props => props.theme.componentsTheme.textColorCommon};
`
const Title = styled.h1`
    text-align: center;
`

class _App extends React.Component<Props> {

    public render = () => {
        let lang = en;
        switch (this.props.language) {
            case 'ja':
                lang = jp;
                break;
            case 'vi':
                lang = vi;
                break;
        }
        return (
            <Wrap id="top">
                <Title>{I18n.t('cookiesPolicy')}</Title>
                {lang}
            </Wrap>
        );
    };
}

const mapStateToProps = (state: StoreState): Props => {
    return {
        language: getLanguage(state)
    };
};

const App = connect(
    mapStateToProps,
    null,
)(_App);
export default App;
