
import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';

const Wrap = styled.div``
const Faqs = styled.div``
const Question = styled.span``
const Answer = styled.div`
    background: #ddd9d9;
    padding: 10px;
    margin: 0;
    width: 100%
`

export const en =
    <Wrap>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>1. What is ONDEX?</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>ONDEX is a decentralized exchange built by the Wowoo ecosystem to protect users by allowing our users to trade with confidence, have full control of their funds and personal data just like how Satoshi Nakamoto envisioned it when he initially built the Bitcoin blockchain. It is built on top of the 0x protocol, that connects traders from around the world. Traders can exchange ETH or ERC20 tokens in the most secure and seamless way. Users can keep their private key and trade directly from their wallet. As of now, our DEX supports Metamask and Infinito wallet. The number of features will keep growing and will possibly integrate security tokens in the near future.</Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>2. What is the difference between a Decentralized Exchange and a Centralized Exchange?</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div>
                    <Answer>Centralized exchanges are trading platforms that operate like traditional brokerage or stock markets. A centralized crypto exchange is owned and operated by a company that has complete control over all transactions. </Answer>
                    <Answer>Centralized crypto exchange users don't have access to the private keys in the wallets of their exchange account. This gives all the confidence of the user in the hands of the exchange operators, since transactions can only be made through the mechanisms provided and approved by a central authority.</Answer>
                    <Answer> Decentralized exchanges such as ONDEX use blockchain (distributed ledger) technology like cryptocurrencies. In this way, a decentralized exchange does not hold the funds, positions, or information of customers and only serves as a matching and routing layer for trade orders.</Answer>
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>3. What is Metamask?</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>MetaMask is a bridge that allows you to visit the distributed web of tomorrow in your browser today. It allows you to run Ethereum dApps right in your browser without running a full Ethereum node to make Ethereum as easy to use for as many people as possible.</Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>4. What are the fees to trade on ONDEX?</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>Wowoo DEX charges 0.2% for the market taker and 0.1% for the market maker. Users are also required to pay gas fees to put their transactions on blockchain.</Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>5. What is a maker and taker?</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>Market makers place new orders on the books and wait for another user to match them. They can be buyers or sellers. Market takers find and fill existing orders on the books, thus taking orders off of the books.</Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>6. What are gas fees?</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>Gas refers to the fee, or pricing value, required on the Ethereum blockchain platform to successfully conduct a transaction or execute a contract.</Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>7. How much gas is needed to perform a trade?</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>Users are responsible for covering all gas fees required to use the exchange. Deposit gas fees are paid directly by the user. ONDEX pays the gas fee to the network for all other transactions and withdrawals, and deducts this fee from the user's transaction. This includes both the gas fees paid by the taker for trades and the gas fees paid by the customer for withdrawals.  The fee is taken out of the asset being traded /withdrawn for trades and withdrawals.  When withdrawing tokens, the fee amount is calculated using the Token/ETH exchange rate from the last 10 trades.</Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <Faqs>
            <Question></Question>

        </Faqs>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>8. Are my digital assets safe on ONDEX?</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div>
                    <Answer>Yes, your digital assets are safe on ONDEX as all funds held within the ONDEX wallet are always under the control of the user. Without your first cryptographically signing permission for ONDEX, ONDEX can not trade, withdraw or make any other transaction with your funds.</Answer>
                    <Answer>Since ONDEX keys are the only ones authorized to submit withdrawal transactions, we have included an escape hatch that allows users to withdraw directly from the contract after some inactivity period.  This ensures that users can still withdraw their funds from the contract even though the ONDEX servers are unavailable.</Answer>
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        {/* <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>9. What is the WWX token?</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>WWX token is a utility token. Holders will enjoy a significant discount when paying for trading fees using WWX tokens. The token will function as a discount token over the course of 5 years after the initial distribution, with the discount decreasing with each passing year. WWX token holders will receive priority responses from WWX customer support agents. WWX token holders will also have voting rights on certain digital assets to be listed and projects to introduce to the Wowoo platform.</Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel> */}
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>9. Does ONDEX support listing of Security Tokens?</Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>ONDEX currently supports the listing of Security Tokens of the ST20 Standard.  </Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>

    </Wrap>
