import React, { } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
// import { assetDataUtils } from '0x.js';

import { marketFilters } from '../../../common/markets';
import { getBaseToken, getCurrencyPair, getMarkets, getQuoteToken, getCurrentRoutePath, getLanguage } from '../../../store/selectors';
import { themeDimensions, themeBreakPoints } from '../../../themes/commons';
import { getKnownTokens } from '../../../util/known_tokens';
import { filterMarketsByString, filterMarketsByTokenSymbol } from '../../../util/markets';
import { CurrencyPair, Filter, Market, StoreState, Token } from '../../../util/types';
// import { CardBase } from '../../common/card_base';
import { Dropdown, DropdownPositions } from '../../common/dropdown';
import { MagnifierIcon } from '../../common/icons/magnifier_icon';
import { TokenIcon } from '../../common/icons/token_icon';
import { CustomTDFirst, Table, TBody, TR } from '../../common/table';
// import { BigNumber } from '0x.js';
// import { tokenAmountInUnits } from '../../../util/tokens';
import { isMobile } from "react-device-detect";
import { LoadingWrapper } from '../../common/loading';
import { assetDataUtils } from '@0x/order-utils';

// const I18n = require('i18n-js');

interface PropsCommon {
    getData: any;
    total: number;
}

interface PropsToken {
    baseToken: Token | null;
    currencyPair: CurrencyPair;
    markets: Market[] | null;
    quoteToken: Token | null;
    path: string;
    language: string;
}

type Props = PropsCommon & PropsToken;

interface State {
    selectedFilter: Filter;
    search: string;
    isUserOnDropdown: boolean;
    limit: number;
    currentPage: number;
    baseAssetData: string,
    quoteAssetData: string,
}

interface TokenFiltersTabProps {
    active: boolean;
    onClick: number;
}

interface MarketRowProps {
    active: boolean;
}

const widthScreen = '170px;';

const MarketsDropdownWrapper = styled(Dropdown)`
${isMobile ? `float:left;clear:both` : ''}
z-index:11
`;
const searchFieldHeight = '32px';
const searchFieldWidth = '142px';
const Select = styled.select`
    min-width: 100px;
    height: 40px;
    background: ${props => props.theme.componentsTheme.marketsSearchFieldBackgroundColor};
    border-radius: ${themeDimensions.borderRadius};
    border: 1px solid ${props => props.theme.componentsTheme.marketsSearchFieldBorderColor};
    color: ${props => props.theme.componentsTheme.marketsSearchFieldTextColor};
    font-size: 13px;
    height: ${searchFieldHeight};
    outline: none;
    padding: 0 15px 0 10px;
    top: 0;
    width: ${searchFieldWidth};
    z-index: 1;
    &:focus {
        border-color: ${props => props.theme.componentsTheme.marketsSearchFieldBorderColor};
    };
    margin-right: 10px;
    ${isMobile ? `float:left;clear:both;margin-bottom: 5px;` : ''}
`

const MarketsDropdownHeader = styled.div`
    float:right;
    @media (max-width: ${themeBreakPoints.md}) {
        margin: 0;
        flex-direction: column;
        padding: 0
    }
`;

const MarketsDropdownBody = styled.div`
    box-shadow: ${props => props.theme.componentsTheme.boxShadow};
    max-height: 100%;
    max-width: 100%;
    width: ${widthScreen};
    text-transform: uppercase;
    background: ${props => props.theme.componentsTheme.cardBackgroundColor};
    position: relative;
    top: ${ isMobile ? '' : ''};
    right: ${ isMobile ? '10px' : 'inital'};
`;

const SearchWrapper = styled.div`
    height: ${searchFieldHeight};
    position: relative;
    width: ${searchFieldWidth};
`;

const SearchField = styled.input`
    background: ${props => props.theme.componentsTheme.marketsSearchFieldBackgroundColor};
    border-radius: ${themeDimensions.borderRadius};
    border: 1px solid ${props => props.theme.componentsTheme.marketsSearchFieldBorderColor};
    color: ${props => props.theme.componentsTheme.marketsSearchFieldTextColor};
    font-size: 13px;
    height: ${searchFieldHeight};
    left: 0;
    outline: none;
    padding: 0 15px 0 30px;
    position: absolute;
    top: 0;
    width: ${searchFieldWidth};
    z-index: 1;

    &:focus {
        border-color: ${props => props.theme.componentsTheme.marketsSearchFieldBorderColor};
    }
`;

const MagnifierIconWrapper = styled.div`
    line-height: 30px;
    height: 100%;
    left: 11px;
    position: absolute;
    top: 0;
    width: 14px;
    z-index: 12;
`;

const TableWrapper = styled.div`
    max-height: 420px;
    overflow: auto;
    position: relative;
`;

const verticalCellPadding = `
    padding-bottom: 10px;
    padding-top: 10px;
`;


const TRStyled = styled(TR) <MarketRowProps>`
    background-color: ${props => (props.active ? props.theme.componentsTheme.rowActive : 'transparent')};
    cursor: ${props => (props.active ? 'default' : 'pointer')};

    &:hover {
        background-color: ${props => props.theme.componentsTheme.rowActive};
    }

    &:last-child > td {
        border-bottom-left-radius: ${themeDimensions.borderRadius};
        border-bottom-right-radius: ${themeDimensions.borderRadius};
        border-bottom: none;
    }
    border-bottom: 1px solid ${props => props.theme.componentsTheme.tableBorderColor};

    &:nth-child(odd) {background: ${props => props.theme.componentsTheme.background};}
    &:nth-child(even) {background: transparent}
`;

const CustomTDFirstStyled = styled(CustomTDFirst)`
    ${verticalCellPadding};
`;

const TokenIconAndLabel = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
`;

const TokenLabel = styled.div`
    color: ${props => props.theme.componentsTheme.textColorCommon};
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 0 12px;
`;

class FilterUserHistory extends React.Component<Props, State> {

    public readonly state: State = {
        selectedFilter: marketFilters[0],
        search: '',
        isUserOnDropdown: false,
        limit: 10,
        currentPage: 1,
        baseAssetData: '',
        quoteAssetData: '',
    };

    public oncChangePage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectPage = parseInt(event.target.value);
        this.setState({ limit: selectPage })
        this.getData(1, selectPage, this.state.baseAssetData, this.state.quoteAssetData)
    }

    private readonly _dropdown = React.createRef<Dropdown>();

    public getData(currentPage: number, limit: number, baseAssetData?: string, quoteAssetData?: string, ) {
        let { getData } = this.props;
        getData(currentPage, limit, baseAssetData, quoteAssetData);
    }

    public render = () => {
        const { total, currencyPair, baseToken, path, ...restProps } = this.props;
        const header = (
            <MarketsDropdownHeader>
                {this._getSearchField()}
            </MarketsDropdownHeader>
        );

        const body = (
            <MarketsDropdownBody>
                <TableWrapper>{this._getMarkets()}</TableWrapper>
            </MarketsDropdownBody>
        );
        return (
            <div style={{ display: isMobile ? '' : 'flex' }} >
                {total > 0  && <Select onChange={this.oncChangePage} defaultValue={'0'}>
                    <option hidden key={0} value={0}>{'1-10 of ' + (total !== undefined ? total : '0')}</option>
                    <option key={1} value={10}>{10}</option>
                    <option key={2} value={20}>{20}</option>
                    <option key={3} value={50}>{50}</option>
                    <option key={4} value={100}>{100}</option>
                </Select>}
                <MarketsDropdownWrapper
                    body={body}
                    header={header}
                    ref={this._dropdown}
                    shouldCloseDropdownOnClickOutside={!this.state.isUserOnDropdown}
                    {...restProps}
                    horizontalPosition={DropdownPositions.Right}
                ></MarketsDropdownWrapper>
            </div>
        );
    };

    private readonly _getSearchField = () => {
        return (
            <SearchWrapper>
                <MagnifierIconWrapper>{MagnifierIcon()}</MagnifierIconWrapper>
                <SearchField onChange={this._handleChange} value={this.state.search} />
            </SearchWrapper>
        );
    };

    private readonly _handleChange = (e: any) => {
        const search = e.currentTarget.value;

        this.setState({
            search,
        });
    };

    private readonly _getMarkets = () => {
        const { baseToken, markets } = this.props;
        const { search, selectedFilter } = this.state;
        if (!baseToken || !markets) {
            return <LoadingWrapper />;
        }

        const filteredMarkets =
            selectedFilter == null || selectedFilter.value === null
                ? markets
                : filterMarketsByTokenSymbol(markets, selectedFilter.value);
        const searchedMarkets = filterMarketsByString(filteredMarkets, search);
        searchedMarkets.unshift({ currencyPair: { base: "All", quote: "All" }, price: null });
        return (
            <>
                <Table>
                    {/* <THead>
                    <TR>
                        <THFirstStyled styles={{ textAlign: 'left' }}>{I18n.t('market')}</THFirstStyled>
                        <THLastStyled styles={{ textAlign: 'center' }}>{I18n.t('price')}</THLastStyled>
                    </TR>
                </THead> */}
                    <TBody>
                        {searchedMarkets.map((market, index) => {
                            let token: any = {};
                            let tokenQuote: any;
                            let setSelectedMarket = null;
                            if (market.currencyPair.base === "All") {
                                token.symbol = 'all'
                                token.primaryColor = '#333333'
                                token.icon = ''
                                setSelectedMarket = () => this._setSelectedMarket();
                            } else {
                                token = getKnownTokens().getTokenBySymbol(market.currencyPair.base);
                                tokenQuote = getKnownTokens().getTokenBySymbol(market.currencyPair.quote);
                                setSelectedMarket = () => this._setSelectedMarket(token.address, tokenQuote.address);
                            }
                            const baseSymbol = market.currencyPair.base.toUpperCase();
                            const quoteSymbol = market.currencyPair.quote.toUpperCase();
                            return (
                                <TRStyled active={false} key={index} onClick={setSelectedMarket}>
                                    <CustomTDFirstStyled styles={{ textAlign: 'left', borderBottom: true }}>
                                        <TokenIconAndLabel>
                                            <TokenIcon
                                                symbol={token.symbol}
                                                primaryColor={token.primaryColor}
                                                icon={token.icon}
                                            />
                                            <TokenLabel>
                                                {baseSymbol} / {quoteSymbol}
                                            </TokenLabel>
                                        </TokenIconAndLabel>
                                    </CustomTDFirstStyled>
                                </TRStyled>
                            );
                        })}
                    </TBody>
                </Table></>
        );
    };

    private readonly _setSelectedMarket: any = (baseAddress?: string, quoteAddress?: string) => {
        if (baseAddress && quoteAddress) {
            const baseAssetData = assetDataUtils.encodeERC20AssetData(baseAddress);
            const quoteAssetData = assetDataUtils.encodeERC20AssetData(quoteAddress);
            this.setState({ baseAssetData, quoteAssetData, currentPage: 1 });
            this.getData(1, this.state.limit, baseAssetData, quoteAssetData)
        } else {
            this.setState({ baseAssetData: '', quoteAssetData: '', currentPage: 1 });
            this.getData(1, this.state.limit, '', '')
        }
        if (this._dropdown.current) {
            this._dropdown.current.closeDropdown();
        }
    };
}

const mapStateToProps = (state: StoreState): PropsToken => {
    return {
        baseToken: getBaseToken(state),
        currencyPair: getCurrencyPair(state),
        markets: getMarkets(state),
        quoteToken: getQuoteToken(state),
        path: getCurrentRoutePath(state),
        language: getLanguage(state),
    };
};

const FilterUserHistoryContainer = connect(
    mapStateToProps,
)(FilterUserHistory);

export { FilterUserHistory, FilterUserHistoryContainer };
