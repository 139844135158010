import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import { getWeb3State } from '../../store/selectors';
import { themeBreakPoints, themeDimensions } from '../../themes/commons';
// import { NETWORK_NAME } from '../../common/constants';
import { StoreState, Web3State } from '../../util/types';

// import { ErrorCard, ErrorIcons, FontSize } from './error_card';
// const I18n = require('i18n-js');

interface OwnProps {
    centerContent?: React.ReactNode;
    endContent: React.ReactNode;
    startContent: React.ReactNode;
}

interface StateProps {
    web3State: Web3State;
}

type Props = OwnProps & StateProps;

export const separatorTopbar = css`
    &:after {
        background-color: ${props => props.theme.componentsTheme.topbarSeparatorColor};
        content: '';
        height: 62px;
        margin-left: 0;
        margin-right: 0;
        width: 1px;
    }
    &:last-child:after {
        display: none;
    }
`;

const ToolbarWrapper = styled.div`
    background: ${props => props.theme.componentsTheme.topbarBackgroundColor};
    border-bottom: 1px solid ${props => props.theme.componentsTheme.topbarBorderColor};
    height: ${themeDimensions.toolbarHeight};
    position: sticky;
    top: 0;
    z-index: 123;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 10px;
`;

const ToolbarWrapperMax = styled.div`
    align-items: center;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: space-between;
    padding: 0 ${themeDimensions.horizontalPadding};
    margin: auto;
    @media (min-width: ${themeBreakPoints.lg}) {
        padding: 0 5%;
    }
`

const ToolbarStart = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;

    @media (min-width: ${themeBreakPoints.xxl}) {
        min-width: 33.33%;
    }
`;

const ToolbarCenter = styled.div`
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;

    /* @media (min-width: ${themeBreakPoints.xxl}) {
         min-width: 33.33%;
     }*/
`;

const ToolbarEnd = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-end;
    @media (min-width: ${themeBreakPoints.xxl}) {
        min-width: 33.33%;
    }
`;

const Toolbar = (props: Props) => {
    const { startContent, centerContent, endContent } = props;
    // const getContentFromWeb3State = (web3State: Web3State): React.ReactNode => {
    //     switch (web3State) {
    //         case Web3State.Locked:
    //             return <ErrorCard fontSize={FontSize.Large} text={I18n.t('metaMaskLocked')} icon={ErrorIcons.Lock} />;
    //         case Web3State.NotInstalled:
    //             return (
    //                 <ErrorCard
    //                     fontSize={FontSize.Large}
    //                     text={I18n.t('metamaskNotInstalled')}
    //                     icon={ErrorIcons.Metamask}
    //                 />
    //             );
    //         case Web3State.Loading:
    //             return <ErrorCard fontSize={FontSize.Large} text={I18n.t('waitLoadWallet')} icon={ErrorIcons.Metamask} />;
    //         case Web3State.Error:
    //             return (
    //                 <ErrorCard fontSize={FontSize.Large} text={I18n.t('wrongNetWork', { NETWORK_NAME })} icon={ErrorIcons.Warning} />
    //             );
    //         case Web3State.Done:
    //             return (
    //                 <>
    //                     <ToolbarCenter>{centerContent}</ToolbarCenter>
    //                     <ToolbarEnd>{endContent}</ToolbarEnd>
    //                 </>
    //             );
    //         default:
    //             const _exhaustiveCheck: never = web3State;
    //             return _exhaustiveCheck;
    //     }
    // };

    return (
        <ToolbarWrapper>
            <ToolbarWrapperMax>
                <ToolbarStart>{startContent}</ToolbarStart>
                {/* {getContentFromWeb3State(props.web3State)} */}
                <ToolbarCenter>{centerContent}</ToolbarCenter>
                <ToolbarEnd>{endContent}</ToolbarEnd>
            </ToolbarWrapperMax>
        </ToolbarWrapper>
    );
};

const mapStateToProps = (state: StoreState): StateProps => {
    return {
        web3State: getWeb3State(state),
    };
};

const ToolbarContainer = connect(mapStateToProps)(Toolbar);

export { Toolbar, ToolbarContainer };
