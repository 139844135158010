import React from 'react';
import { connect } from 'react-redux';
import { getLanguage } from '../../../store/selectors';
import { StoreState } from '../../../util/types';
import styled from 'styled-components';
import { isMobile } from "react-device-detect";
import { PolicyPage } from '../../../assets/docs/Policy';
import '../../../assets/docs/Policy.css';

interface Props {
    language: string
}
const Wrap = styled.div`
    margin: ${isMobile ? '20px 1%' : '20px 5%'};
    text-align: left;
    color: ${props => props.theme.componentsTheme.textColorCommon};
    min-height: ${isMobile ? '500px' : '1000px'};
`
const Title = styled.h1`
    text-align: center;
`
const Div = styled.div``
const Head = styled.h3``
const Bdy = styled.div``
const P = styled.p``

class _App extends React.Component<Props> {

    public render = () => {
        const height = isMobile ? '500' : '1000'
        let lang = PolicyPage;
        // switch (this.props.language) {
        //     case 'ja':
        //         lang = PolicyPage;
        //         break;
        //     case 'vi':
        //         lang = PolicyPage;
        //         break;
        // }
        return (
            <Wrap id="top">
               {lang}
            </Wrap>
        );
    };
}

const mapStateToProps = (state: StoreState): Props => {
    return {
        language: getLanguage(state)
    };
};

const App = connect(
    mapStateToProps,
    null,
)(_App);
export default App;
