import React, { HTMLAttributes } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { UI_DECIMALS_DISPLAYED_PRICE_ETH } from '../../../common/constants';
import { marketFilters } from '../../../common/markets';
import { changeMarket, goToHome, goToMarketErc20, get24hVolumeAction, getExchangeChartAction } from '../../../store/actions';
import { getBaseToken, getCurrencyPair, getMarkets, getQuoteToken, getCurrentRoutePath, getLanguage, getVolume } from '../../../store/selectors';
import { themeDimensions, themeBreakPoints } from '../../../themes/commons';
import { getKnownTokens } from '../../../util/known_tokens';
import { filterMarketsByString, filterMarketsByTokenSymbol } from '../../../util/markets';
import { CurrencyPair, Filter, Market, StoreState, Token, Volume } from '../../../util/types';
import { CardBase } from '../../common/card_base';
import { Dropdown } from '../../common/dropdown';
import { MagnifierIcon } from '../../common/icons/magnifier_icon';
import { TokenIcon } from '../../common/icons/token_icon';
import { CustomTDFirst, Table, TBody, TR } from '../../common/table';
import { LoadingWrapper } from '../../common/loading';
import { isMobile } from "react-device-detect";
import { assetDataUtils } from '@0x/order-utils';

const config = require('../../../config.json');
const I18n = require('i18n-js');

interface PropsDivElement extends HTMLAttributes<HTMLDivElement> { }

interface DispatchProps {
    changeMarket: (currencyPair: CurrencyPair) => any;
    goToHome: () => any;
    goToMarketErc20: () => any;
    get24hVolume: (base: string, quote: string) => any;
    getExchangeChart: (pair: string, time: string, page: string) => any;
}

interface PropsToken {
    baseToken: Token | null;
    currencyPair: CurrencyPair;
    markets: Market[] | null;
    quoteToken: Token | null;
    path: string;
    language: string;
    volume: Volume | null,

}

type Props = PropsDivElement & PropsToken & DispatchProps;

interface State {
    selectedFilter: Filter;
    search: string;
    isUserOnDropdown: boolean;
}

interface TokenFiltersTabProps {
    active: boolean;
    onClick: number;
}

interface MarketRowProps {
    active: boolean;
}

const MarketsDropdownBody = styled(CardBase)`
    box-shadow: ${props => props.theme.componentsTheme.boxShadow};
    max-height: 100%;
    max-width: 100%;
    margin: 50px 0;
`;

const MarketsFilters = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 15px 8px 15px ${themeDimensions.horizontalPadding};
    text-transform: uppercase;
    border-bottom: 1px solid ${props => props.theme.componentsTheme.tableBorderColor};
    @media (max-width: ${themeBreakPoints.md}) {
        width: 100%;
        display: block;
    }

`;

const MarketsFiltersLabel = styled.h2`
    color: ${props => props.theme.componentsTheme.textColorCommon};
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin: 0 auto 0 40px;
    @media (max-width: ${themeBreakPoints.md}) {
        margin: 0 auto 0 0;
    }
`;

const TokenFiltersTabs = styled.div`
    align-items: center;
    display: flex;
    margin: 10px;
    @media (max-width: ${themeBreakPoints.md}) {
        margin: 10px 10px 10px 0;
    }
`;

const TokenFiltersTab = styled.span<TokenFiltersTabProps>`
    color: ${props =>
        props.active ? props.theme.componentsTheme.textColorCommon : props.theme.componentsTheme.lightGray};
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    user-select: none;
    background: ${props => props.active ? '#3FD3B8' : props.theme.componentsTheme.marketsSearchFieldBackgroundColor};
    padding: 7px 10px;
    margin: 0 10px 0 0;
    border-radius: 5px;
    &:last-child:after {
        display: none;
    }
`;

const searchFieldHeight = '32px';
const searchFieldWidth = '200px';

const SearchWrapper = styled.div`
    height: ${searchFieldHeight};
    position: relative;
    width: ${searchFieldWidth};
    margin: 0 50px 0 0;
`;

const SearchField = styled.input`
    background: ${props => props.theme.componentsTheme.marketsSearchFieldBackgroundColor};
    border-radius: ${themeDimensions.borderRadius};
    border: 1px solid ${props => props.theme.componentsTheme.marketsSearchFieldBorderColor};
    color: ${props => props.theme.componentsTheme.marketsSearchFieldTextColor};
    font-size: 13px;
    height: ${searchFieldHeight};
    left: 0;
    outline: none;
    padding: 0 15px 0 30px;
    position: absolute;
    top: 0;
    width: ${searchFieldWidth};
    z-index: 1;

    &:focus {
        border-color: ${props => props.theme.componentsTheme.marketsSearchFieldBorderColor};
    }
`;

const MagnifierIconWrapper = styled.div`
    line-height: 30px;
    height: 100%;
    left: 11px;
    position: absolute;
    top: 0;
    width: 14px;
    z-index: 12;
`;

const TableWrapper = styled.div`
    overflow: auto;
    position: relative;
    font-size: ${isMobile ? '10px' : '14px'}
`;

const verticalCellPadding = `
    padding-bottom: 10px;
    padding-top: 10px;
`;

const TRStyled = styled(TR) <MarketRowProps>`
    /* background-color: ${props => (props.active ? props.theme.componentsTheme.rowActive : 'transparent')}; */
    cursor: pointer;
    vertical-align: middle;
    &:hover {
        background-color: ${props => props.theme.componentsTheme.rowActive};
    }

    &:last-child > td {
        border-bottom-left-radius: ${themeDimensions.borderRadius};
        border-bottom-right-radius: ${themeDimensions.borderRadius};
        border-bottom: none;
    }
    .odd {
        background-color: #000;
    }
    &:nth-child(even) {background: ${props => props.theme.componentsTheme.backgroundColorMarketOdd};}
    &:nth-child(odd) {background: transparent}
`;

const CustomTDFirstStyled = styled(CustomTDFirst)`
    ${verticalCellPadding};
`;

const TokenIconAndLabel = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin: 0 0 0 40px;
    @media (max-width: ${themeBreakPoints.md}) {
        margin: 0;
    }
    font-size: ${isMobile ? '10px' : '14px'}
`;

const TokenLabel = styled.div`
    color: ${props => props.theme.componentsTheme.textColorCommon};
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 0 ${isMobile ? '0' : '12px'};
`;
const TRHead = styled.tr`
    color: #546497;
    border-bottom: 1px solid ${props => props.theme.componentsTheme.tableBorderColor};
    text-transform: uppercase;
`;

const TH = styled.th`
    padding: 15px 0;
    text-align: center;
`;

const THeadPair = styled(TH)`
    text-align: left;
    padding-left: 50px;
    @media (max-width: ${themeBreakPoints.md}) {
        padding-left: 15px;
    }
`;
const TD = styled.td`
    text-align: center;
    padding: 20px 0;
    color: ${props => props.theme.componentsTheme.textColorCommon};
`;
const Wrap = styled.div`
    margin: 20px 5%;
    text-align: left;
    color: ${props => props.theme.componentsTheme.textColorCommon};
`

class ListAssets extends React.Component<Props, State> {
    public readonly state: State = {
        selectedFilter: marketFilters[0],
        search: '',
        isUserOnDropdown: false,
    };

    private readonly _dropdown = React.createRef<Dropdown>();

    public getTypeByToken = (token: string): string => {
        const { tokens } = config;
        for (let i = 0; i < tokens.length; i++) {
            if (tokens[i] && tokens[i]['symbol'].toLowerCase() === token.toLowerCase())
                return tokens[i]['type'];
        }
        return '';
    }

    public render = () => {
        return (
            <Wrap>
                <h1>{I18n.t('listedAssets')}</h1>
                <p>{I18n.t('theFollowingAssets')}</p>
                <MarketsDropdownBody>
                    <MarketsFilters onMouseOver={this._setUserOnDropdown} onMouseOut={this._removeUserOnDropdown}>
                        <MarketsFiltersLabel>{I18n.t('allAssets')}</MarketsFiltersLabel>
                        {/* {this._getTokensFilterTabs()} */}
                        {this._getSearchField()}
                    </MarketsFilters>
                    <TableWrapper>{this._getMarkets()}</TableWrapper>
                </MarketsDropdownBody>
            </Wrap>
        );
    };

    private readonly _setUserOnDropdown = () => {
        this.setState({ isUserOnDropdown: true });
    };

    private readonly _removeUserOnDropdown = () => {
        this.setState({ isUserOnDropdown: false });
    };

    private readonly _getTokensFilterTabs = () => {
        return (
            <TokenFiltersTabs>
                {marketFilters.map((filter: Filter, index) => {
                    return (
                        <TokenFiltersTab
                            active={filter === this.state.selectedFilter}
                            key={index}
                            onClick={this._setTokensFilterTab.bind(this, filter)}
                        >
                            {filter.text}
                        </TokenFiltersTab>
                    );
                })}
            </TokenFiltersTabs>
        );
    };

    private readonly _setTokensFilterTab: any = (filter: Filter) => {
        this.setState({ selectedFilter: filter });
    };

    private readonly _getSearchField = () => {
        return (
            <SearchWrapper>
                <MagnifierIconWrapper>{MagnifierIcon()}</MagnifierIconWrapper>
                <SearchField onChange={this._handleChange} value={this.state.search} />
            </SearchWrapper>
        );
    };

    private readonly _handleChange = (e: any) => {
        const search = e.currentTarget.value;

        this.setState({
            search,
        });
    };

    private readonly _getMarkets = () => {
        const { baseToken, currencyPair, markets } = this.props;
        const { search, selectedFilter } = this.state;

        if (!baseToken || !markets) {
            return <LoadingWrapper />;
        }

        const filteredMarkets =
            selectedFilter == null || selectedFilter.value === null
                ? markets
                : filterMarketsByTokenSymbol(markets, selectedFilter.value);
        const searchedMarkets = filterMarketsByString(filteredMarkets, search);
        return (
            <Table>
                <TRHead>
                    <THeadPair>{I18n.t('asset')}</THeadPair>
                    <TH>{I18n.t('available')}</TH>
                    <TH>{I18n.t('type')}</TH>
                </TRHead>
                <TBody>
                    {searchedMarkets.map((market, index) => {
                        const isActive =
                            market.currencyPair.base === currencyPair.base &&
                            market.currencyPair.quote === currencyPair.quote;
                        const setSelectedMarket = () => this._setSelectedMarket(market.currencyPair);
                        const token = getKnownTokens().getTokenBySymbol(market.currencyPair.base);
                        const baseSymbol = market.currencyPair.base.toUpperCase();
                        const quoteSymbol = market.currencyPair.quote.toUpperCase();
                        // const key = baseSymbol + '_' + quoteSymbol;
                        // const data = volume ? volume[key.toLowerCase()] : null;
                        // const change24h = data && data.change24h.toString() ? data.change24h.toFixed(2).toString() : '0';
                        // const decimals = token ? token.decimals : 18;
                        // const displayDecimals = token ? token.displayDecimals : 2;
                        // const _volume = data && data.volume ? data.volume : 0;
                        // const amount = tokenAmountInUnits(new BigNumber(_volume), decimals, displayDecimals);
                        // const endPrice = data && data.endPrice ? data.endPrice : market.price;
                        return (
                            <TRStyled active={isActive} key={index} onClick={setSelectedMarket} className={index % 2 === 0 ? '_even' : '_odd'}>
                                <CustomTDFirstStyled styles={{ textAlign: 'left' }}>
                                    <TokenIconAndLabel>
                                        {!isMobile && <TokenIcon
                                            symbol={token.symbol}
                                            primaryColor={token.primaryColor}
                                            icon={token.icon}
                                        />}
                                        <TokenLabel>
                                            {baseSymbol} / {quoteSymbol}<br />
                                        </TokenLabel>
                                    </TokenIconAndLabel>
                                </CustomTDFirstStyled>
                                <TD>{I18n.t('yes')}</TD>
                                <TD>{this.getTypeByToken(baseSymbol)}</TD>
                            </TRStyled>
                        );
                    })}
                </TBody>
            </Table>
        );
    };

    private readonly _setSelectedMarket: any = (currencyPair: CurrencyPair) => {
        const { baseToken, quoteToken, get24hVolume } = this.props;
        this.props.changeMarket(currencyPair);
        this.props.goToMarketErc20();
        if (this._dropdown.current) {
            this._dropdown.current.closeDropdown();
        }
        if (baseToken && quoteToken) {
            const baseAssetData = assetDataUtils.encodeERC20AssetData(baseToken.address);
            const quoteAssetData = assetDataUtils.encodeERC20AssetData(quoteToken.address);
            get24hVolume(baseAssetData, quoteAssetData)
            // const pair = baseToken.symbol.toLocaleLowerCase() + '_' + quoteToken.symbol.toLocaleLowerCase();
            // getExchangeChart(pair, '6h', '20');
        }
    };

    private readonly _getPrice: any = (market: Market) => {
        if (market.price) {
            return +market.price.toFixed(UI_DECIMALS_DISPLAYED_PRICE_ETH);
        }

        return '-';
    };
}

const mapStateToProps = (state: StoreState): PropsToken => {
    return {
        baseToken: getBaseToken(state),
        currencyPair: getCurrencyPair(state),
        markets: getMarkets(state),
        quoteToken: getQuoteToken(state),
        path: getCurrentRoutePath(state),
        language: getLanguage(state),
        volume: getVolume(state)
    };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
    return {
        changeMarket: (currencyPair: CurrencyPair) => dispatch(changeMarket(currencyPair)),
        goToHome: () => dispatch(goToHome()),
        goToMarketErc20: () => dispatch(goToMarketErc20()),
        get24hVolume: (base: string = '', quote: string = '') => dispatch(get24hVolumeAction(base, quote)),
        getExchangeChart: (pair: string, time: string, page: string) => dispatch(getExchangeChartAction(pair, time, page)),
    };
};

const ListAssetsIndex = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ListAssets);

export { ListAssetsIndex, ListAssets };
