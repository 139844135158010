// import { OrderStatus, BigNumber, orderHashUtils, assetDataUtils } from '0x.js';
import { BigNumber } from '@0x/utils';
import { assetDataUtils, orderHashUtils } from '@0x/order-utils';
import { OrderStatus } from '@0x/types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { UI_DECIMALS_DISPLAYED_PRICE_ETH, ZERO_ADDRESS } from '../../../common/constants';
import {
    getBaseToken,
    getEthAccount,
    getQuoteToken,
    getUserHistories,
    getUserOrders,
    getWeb3State,
} from '../../../store/selectors';
import { tokenAmountInUnits } from '../../../util/tokens';
import { OrderSide, StoreState, Token, UIOrder, Web3State } from '../../../util/types';
import { Card } from '../../common/card';
import { EmptyContent } from '../../common/empty_content';
import { LoadingWrapper } from '../../common/loading';
import { CustomTD, Table, TH, THead, TR } from '../../common/table';
import { CloseIcon } from '../../common/icons/close_icon';
import { CancelOrderButtonContainer } from './cancel_order_button';
import {
    getUserHistoryFromAPI,
    rejectSpecificOrder,
    startBuySellMarketSteps,
    submitMarketOrder,
} from '../../../store/actions';
import { fetchTakerAndMakerFee } from '../../../store/relayer/actions';
import { themeDimensions } from '../../../themes/commons';
import { UserHistoryContainer } from './user_history';
// import { InsufficientOrdersAmountException } from './../../../exceptions/insufficient_orders_amount_exception';
// import { INSUFFICIENT_ORDERS_TO_FILL_AMOUNT_ERR } from './../../../exceptions/common';
import { fixBigNumber } from '../../../util/number_utils';

const I18n = require('i18n-js');

interface StateProps {
    baseToken: Token | null;
    orders: UIOrder[];
    quoteToken: Token | null;
    web3State?: Web3State;
    ethAccount: string | null;
    userHistories: any;
}

enum TabType {
    MyOrder = 'MyOrder',
    Specific = 'Specific',
    MyHistory = 'MyHistory'
}

interface State {
    tab: TabType;
    tabType: TabType;
    disable: boolean;
}


interface DispatchProps {
    onSubmitAcceptOrder: (amount: BigNumber, side: OrderSide, specificOrder: UIOrder) => Promise<{ txHash: string; amountInReturn: BigNumber }>;
    onRejectSpecificOrder: (orderHash: string) => Promise<void>;
    getUserHistoryFromAPI: (currentPage: number, limit: number, baseAssetData?: string, quoteAssetData?: string) => any;
    onFetchTakerAndMakerFee: (amount: BigNumber, price: BigNumber, side: OrderSide) => Promise<any>;
    onSubmitMarketOrder: (amount: BigNumber, side: OrderSide, takerFee: BigNumber, specificOrder: UIOrder) => Promise<any>;
}

type Props = StateProps & DispatchProps;

const TabsContainer = styled.div`
    align-items: center;
    display: flex;
    background-color: ${props => props.theme.componentsTheme.background};;
`;

const TabButton = styled.div<{ isSelected: boolean; }>`
    align-items: center;
    background-color: ${props =>
    props.isSelected ? props.theme.componentsTheme.inactiveTabBackgroundColor : 'transparent'};
    border-bottom-color: ${props => (props.isSelected ? '#3FD3B8' : 'transparent')};
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-right-color: ${props => (props.isSelected ? props.theme.componentsTheme.cardBorderColor : 'transparent')};
    border-right-style: solid;
    border-right-width: 0;
    cursor: ${props => (props.isSelected ? 'default' : 'pointer')};
    display: flex;
    font-weight: 600;
    justify-content: center;
    padding: 10px;
    /* border-bottom: ${props => props.isSelected ? '2px' : '0px'} solid #3CB34F; */
    &:first-child {
        border-top-left-radius: ${themeDimensions.borderRadius};
    }

    &:last-child {
        border-left-color: ${props => (props.isSelected ? props.theme.componentsTheme.cardBorderColor : 'transparent')};
        border-left-style: solid;
        border-left-width: 1px;
        border-right: none;
        border-top-right-radius: ${themeDimensions.borderRadius};
    }
    color: ${props =>  props.isSelected ? '#3FD3B8' : props.theme.componentsTheme.textColorCommon};
    padding: 10px;
    font-size: 14px;
    max-width: 200px;
`;

const SideTD = styled(CustomTD) <{ side: OrderSide }>`
    color: ${props =>
    props.side === OrderSide.Buy ? props.theme.componentsTheme.green : props.theme.componentsTheme.red};
    padding: 10px;
`;

const CTD = styled(CustomTD)`
    padding: 10px;
`;

const Span = styled.span`
    color: #546497;
    border-radius: 50px;
    border: 0px solid;
    padding: 3px 15px;
`;
const CTR = styled(TR)`
    border-bottom: 1px solid ${props => props.theme.componentsTheme.tableBorderColor};
`;
const TBODY = styled.tbody`
    border-top: 1px solid ${props => props.theme.componentsTheme.tableBorderColor};
`;

const Button = styled.button`
    align-items: center;
    background: none;
    border: none;
    display: flex;
    height: 17px;
    justify-content: flex-end;
    outline: 0;
    padding: 0;

    &:hover {
        cursor: pointer;
    }

    &[disabled] {
        cursor: default;
    }
`;

class OrderHistory extends React.Component<Props, State> {
    public state = {
        tab: TabType.MyOrder,
        tabType: TabType.MyOrder,
        disable: false,
    };

    public acceptHandle = async (specificOrder: UIOrder, side: OrderSide) => {
        //  market sell with order buy || market buy with order sell.
        this.setState({ disable: true });
        side === OrderSide.Buy ? side = OrderSide.Sell : side = OrderSide.Buy;
        // await this.props.onSubmitAcceptOrder(specificOrder.size, specificOrder.side, specificOrder);
        let makerAmount = specificOrder.size || new BigNumber(0);
        let price = specificOrder.price || new BigNumber(0);
        try {
            const { takerFee } = await this.props.onFetchTakerAndMakerFee(makerAmount, price, side);
            await this.props.onSubmitMarketOrder(makerAmount, side, takerFee, specificOrder);
            this.setState({ disable: false });
        } catch (error) {
            this.setState({ disable: false });
            window.alert(error);
            // throw new InsufficientOrdersAmountException();
        }
    };

    public rejectHandle = async (orderHash: string) => {
        let cf = window.confirm(I18n.t('alertReject'));
        if (cf) await this.props.onRejectSpecificOrder(orderHash);
    };

    public AcceptOrderButtonContainer = (props: { order: UIOrder }) => {
        return (
            <button disabled={this.state.disable} title={I18n.t('acceptOrder')} type="button"
                    onClick={() => this.acceptHandle(props.order, props.order.side)}>
                {I18n.t('accept')}
            </button>
        );
    };

    public componentDidMount = async () => {
        const { baseToken, quoteToken } = this.props;
        if (baseToken && quoteToken) {
            const baseAssetData = assetDataUtils.encodeERC20AssetData(baseToken.address);
            const quoteAssetData = assetDataUtils.encodeERC20AssetData(quoteToken.address);
            await this.props.getUserHistoryFromAPI(1, 10, baseAssetData, quoteAssetData);
        }
    };

    public componentWillReceiveProps = async (nextProps: any) => {
        if ((nextProps.baseToken !== this.props.baseToken) || (nextProps.quoteToken !== this.props.quoteToken) || (nextProps.ethAccount !== this.props.ethAccount)) {
            const baseAssetData = assetDataUtils.encodeERC20AssetData(nextProps.baseToken.address);
            const quoteAssetData = assetDataUtils.encodeERC20AssetData(nextProps.quoteToken.address);
            await this.props.getUserHistoryFromAPI(1, 10, baseAssetData, quoteAssetData);
        }
    };

    public RejectOrderButtonContainer = (orderHash: any) => {
        return (
            <Button title={I18n.t('rejectOrder')} type="button" onClick={() => this.rejectHandle(orderHash.orderHash)}>
                <CloseIcon />
            </Button>
        );
    };

    public orderToRow = (order: UIOrder, index: number, baseToken: Token) => {
        const isTakerAddress = (order.rawOrder.takerAddress !== ZERO_ADDRESS) && (order.rawOrder.takerAddress === this.props.ethAccount) ? true : false;
        //if (isTakerAddress) order.side === OrderSide.Sell ? order.side = OrderSide.Buy : order.side = OrderSide.Sell
        const sideLabel = order.side === OrderSide.Sell ? I18n.t('sell') : I18n.t('buy');
        const size = +tokenAmountInUnits(order.size, baseToken.decimals, baseToken.displayDecimals);
        let status = '--';
        let isOrderFillable = false;

        const filled = order.filled
            ? +tokenAmountInUnits(order.filled, baseToken.decimals, baseToken.displayDecimals)
            : null;
        if (order.status) {
            isOrderFillable = order.status === OrderStatus.Fillable;
            status = order.isSpecificReject ? I18n.t('rejected') : (isOrderFillable ? I18n.t('open') : I18n.t('filled'));
        }

        // const price = +parseFloat(order.price.toString()).toFixed(UI_DECIMALS_DISPLAYED_PRICE_ETH);
        let price = order && order.price ? fixBigNumber(new BigNumber(order.price).toFixed(UI_DECIMALS_DISPLAYED_PRICE_ETH)) : '';
        if (order && order.price)
            price = +price === 0 ? fixBigNumber(new BigNumber(order.price).toString()) : fixBigNumber(new BigNumber(order.price).toFixed(UI_DECIMALS_DISPLAYED_PRICE_ETH));

        const orderHash = orderHashUtils.getOrderHash(order.rawOrder);
        return (
            <CTR key={index}>
                <SideTD side={order.side}>{sideLabel}</SideTD>
                <CTD styles={{ textAlign: 'center', tabular: true }}>{size}</CTD>
                <CTD styles={{
                    textAlign: 'center',
                    tabular: true,
                }}>{isTakerAddress ? order.rawOrder.makerAddress : filled}</CTD>
                <CTD styles={{ textAlign: 'center', tabular: true }}>{price}</CTD>
                <CTD styles={{ textAlign: 'center' }}>
                    {isTakerAddress ? <this.AcceptOrderButtonContainer order={order} /> : <Span>{status}</Span>}</CTD>
                <CTD styles={{ textAlign: 'center' }}>
                    {isTakerAddress ? <this.RejectOrderButtonContainer orderHash={orderHash} /> : (isOrderFillable ?
                        <CancelOrderButtonContainer order={order} /> : '')}
                </CTD>
            </CTR>
        );
    };

    public changeTab = (tab: TabType) => () => this.setState({ tab, tabType: tab });

    public render = () => {
        const { orders, baseToken, quoteToken, web3State, ethAccount, userHistories } = this.props;
        const ordersToShowSpecific = orders.filter(order => (order.status === OrderStatus.Fillable) && (order.isSpecificReject === false) && (order.rawOrder.takerAddress !== ZERO_ADDRESS) && (order.rawOrder.takerAddress === ethAccount));
        const ordersToShow = orders.filter(order => (order.status === OrderStatus.Fillable) && (order.rawOrder.takerAddress !== ethAccount));

        let content: React.ReactNode;
        let contentOrder: React.ReactNode;
        let contentSpecific: React.ReactNode;
        let contentMyTradeHistory: React.ReactNode;
        let tabContent: React.ReactNode = (
            <TabsContainer>
                <TabButton
                    isSelected={this.state.tab === TabType.MyOrder}
                    onClick={this.changeTab(TabType.MyOrder)}
                >
                    {I18n.t('myOpenOrders')}
                </TabButton>
                <TabButton
                    isSelected={this.state.tab === TabType.Specific}
                    onClick={this.changeTab(TabType.Specific)}
                >
                    {I18n.t('specificOrders')}
                </TabButton>
                <TabButton
                    isSelected={this.state.tab === TabType.MyHistory}
                    onClick={this.changeTab(TabType.MyHistory)}
                >
                    {I18n.t('myTradeHistory')}
                </TabButton>
            </TabsContainer>
        );
        switch (web3State) {
            case Web3State.Locked:
            case Web3State.NotInstalled:
            case Web3State.Loading: {
                content = null;// <>{tabContent}<LoadingWrapper minHeight="120px" /></>;
                break;
            }
            default: {
                if (web3State !== Web3State.Error && (!baseToken || !quoteToken)) {
                    content = <>{tabContent} <LoadingWrapper minHeight="120px" /></>;
                }

                if (ordersToShow.length === 0 || !baseToken || !quoteToken) {
                    contentOrder = <><EmptyContent alignAbsoluteCenter={true} text={I18n.t('noOrders')} /></>;
                } else {
                    contentOrder = (
                        <Table isResponsive={true}>
                            <THead>
                                <TR>
                                    <TH styles={{ textAlign: 'center' }}>{I18n.t('side')}</TH>
                                    <TH styles={{ textAlign: 'center' }}>{I18n.t('size')} ({baseToken.symbol})</TH>
                                    <TH styles={{ textAlign: 'center' }}>{I18n.t('filled')} ({baseToken.symbol})</TH>
                                    <TH styles={{ textAlign: 'center' }}>{I18n.t('price')} ({quoteToken.symbol})</TH>
                                    <TH styles={{ textAlign: 'center' }}>{I18n.t('status')}</TH>
                                    <TH>&nbsp;</TH>
                                </TR>
                            </THead>
                            <TBODY>{ordersToShow.map((order, index) => this.orderToRow(order, index, baseToken))}</TBODY>
                        </Table>
                    );
                }

                if (ordersToShowSpecific.length === 0 || !baseToken || !quoteToken) {
                    contentSpecific = <EmptyContent alignAbsoluteCenter={true} text={I18n.t('noOrders')} />;
                } else {
                    contentSpecific = (
                        <Table isResponsive={true}>
                            <THead>
                                <TR>
                                    <TH styles={{ textAlign: 'center' }}>{I18n.t('side')}</TH>
                                    <TH styles={{ textAlign: 'center' }}>{I18n.t('size')} ({baseToken.symbol})</TH>
                                    <TH styles={{ textAlign: 'center' }}>{I18n.t('makerAddress')}</TH>
                                    <TH styles={{ textAlign: 'center' }}>{I18n.t('price')} ({quoteToken.symbol})</TH>
                                    <TH styles={{ textAlign: 'center' }}>{I18n.t('status')}</TH>
                                    <TH>&nbsp;</TH>
                                </TR>
                            </THead>
                            <TBODY>{ordersToShowSpecific.map((order, index) => this.orderToRow(order, index, baseToken))}</TBODY>
                        </Table>
                    );
                }

                if (userHistories.length === 0 || (userHistories.records && userHistories.records.length === 0) || !baseToken || !quoteToken) {
                    contentMyTradeHistory = <EmptyContent style={{ minHeight: '120px' }} alignAbsoluteCenter={true}
                                                          text={I18n.t('noData')} />;
                } else {
                    contentMyTradeHistory = (<UserHistoryContainer pageTrade={true} />);
                }

                content = (
                    <>
                        {tabContent}
                        {this.state.tabType === TabType.MyOrder && (
                            <Card paddingBody={'10px 0'} minHeightBody={'220px'}>
                                {contentOrder}
                            </Card>
                        )}
                        {this.state.tabType === TabType.Specific && (
                            <>
                                <Card paddingBody={'10px 0'} minHeightBody={'220px'}>
                                    {contentSpecific}
                                </Card>
                            </>
                        )}
                        {this.state.tabType === TabType.MyHistory && (
                            <>
                                <Card paddingBody={'10px 10px'} minHeightBody={'220px'}>
                                    {contentMyTradeHistory}
                                </Card>
                            </>
                        )}
                    </>
                );
                break;
            }
        }

        return content;
    };
}

const mapStateToProps = (state: StoreState): StateProps => {
    return {
        baseToken: getBaseToken(state),
        orders: getUserOrders(state),
        quoteToken: getQuoteToken(state),
        web3State: getWeb3State(state),
        ethAccount: getEthAccount(state),
        userHistories: getUserHistories(state),
    };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
    return {
        onSubmitAcceptOrder: (amount: BigNumber, side: OrderSide, specificOrder: UIOrder) => dispatch(submitMarketOrder(amount, side, specificOrder)),
        onRejectSpecificOrder: (orderHash: string) => dispatch(rejectSpecificOrder(orderHash)),
        getUserHistoryFromAPI: (currentPage: number, limit: number, baseAssetData?: string, quoteAssetData?: string) =>
            dispatch(getUserHistoryFromAPI(currentPage, limit, baseAssetData, quoteAssetData)),
        onSubmitMarketOrder: (amount: BigNumber, side: OrderSide, takerFee: BigNumber, specificOrder: UIOrder) =>
            dispatch(startBuySellMarketSteps(amount, side, takerFee, specificOrder)),
        onFetchTakerAndMakerFee: (amount: BigNumber, price: BigNumber, side: OrderSide) =>
            dispatch(fetchTakerAndMakerFee(amount, price, side)),
    };
};

const OrderHistoryContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(OrderHistory);

export { OrderHistory, OrderHistoryContainer };
