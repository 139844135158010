import React from 'react';
import { Route, Switch } from 'react-router';
import { ThemeProvider } from 'styled-components/macro';
import { connect } from 'react-redux';

import { ERC20_APP_BASE_PATH, ERC20_THEME_NAME, DARK_THEME } from '../../common/constants';
import { AdBlockDetector } from '../../components/common/adblock_detector';
import { GeneralLayout } from '../../components/general_layout';
import { getTheme } from '../../themes/theme_meta_data_utils';
// import { MARKETPLACES } from '../../util/types';

import { Marketplace } from './pages/marketplace';
import { MyWallet } from './pages/my_wallet';
import Dashboard from './pages/home';
import Support from './pages/supports';
import AboutUS from './pages/about-us';
import FAQ from './pages/faq';
import Features from './pages/features';
import Benefits from './pages/benefits';
import Terms from './pages/terms';
import ContactUs from './pages/contac_us';
import CommingSoon from './pages/coming_soon';
import DevTeam from './pages/develop_team';
import Tutorial from './pages/tutorial';
import Fees from './pages/fees';
import Policy from './pages/policy';
import Cookies from './pages/cookies';
import { ListAssetsIndex } from './pages/list-assets';
import { UserHistory } from './pages/user_history';
import NotPage from './pages/404';
import Earn from './pages/earn';

import { getLanguage } from '../../store/selectors'
import { StoreState } from '../../util/types';
import { getThemeUI } from '../../store/selectors';
import { withTheme } from 'styled-components/macro';
import { ToolbarContentContainer } from './common/main_menu';
import { Socket } from './../../services/socket';
const Sockets = new Socket();
Sockets.initSocket();

interface StateProps {
    theme: string;
    language: string;
}
const _Erc20App = (props: StateProps) => {
    const href = window.location.hash || '';
    // const theme = href.indexOf('/market?') < 0 ? DARK_THEME : (props.theme ? props.theme : ERC20_THEME_NAME);
    const theme = props.theme ? props.theme : ERC20_THEME_NAME;
    const themeColor = getTheme(theme);
    const toolbar = <ToolbarContentContainer />;

    return (
        <ThemeProvider theme={themeColor}>
            <GeneralLayout toolbar={toolbar}>
                <AdBlockDetector />
                <Switch>
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/`} component={Dashboard} />
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/market`} component={Marketplace} />
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/my-wallet`} component={MyWallet} />
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/comming-soon`} component={CommingSoon} />
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/support`} component={Support} />
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/about-us`} component={AboutUS} />
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/faq`} component={FAQ} />
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/contact-us`} component={NotPage} />
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/features`} component={Benefits} />
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/features/decentralized-exchange-benefits`} component={Benefits} />
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/terms`} component={Terms} />
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/comming-soon`} component={CommingSoon} />
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/dev-team`} component={DevTeam} />
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/tutorial`} component={Tutorial} />
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/fees`} component={Fees} />
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/policy`} component={Policy} />
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/cookies`} component={Cookies} />
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/list-assets`} component={ListAssetsIndex} />
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/user-history`} component={UserHistory} />
                    <Route exact={true} path={`${ERC20_APP_BASE_PATH}/earn`} component={Earn} />
                    <Route component={NotPage} />
                </Switch>
            </GeneralLayout>
        </ThemeProvider>
    );
};

const mapStateToProps = (state: StoreState): StateProps => {
    return {
        theme: getThemeUI(state),
        language: getLanguage(state)
    };
};

export const Erc20App = withTheme(
    connect(
        mapStateToProps,
        null,
    )(_Erc20App),
);
