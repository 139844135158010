
import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div``
const Faqs = styled.div``
const Head = styled.h3``
const Question = styled.h3``
const Answer = styled.p``

export const jp =
    <Wrap>
        <Head>ウォレットのセットアップ</Head>
        <Faqs>
            <Question>1.1新しいメタマスクアカウントを作成する</Question>
            <Answer>Metamask Webサイトにアクセスします。
            <br />
        -「Get Chrome Extension」をクリックしてMetamaskをインストールします。
        <br />
        -右上の[Chromeに追加]をクリックします。
        <br />
        -「拡張機能の追加」をクリックして、インストールを完了します。
        <br />
            </Answer>
        </Faqs>
        <Faqs>
            <Question>1.2メタマスクの使用
            </Question>
            <Answer>Google Chromeブラウザの右上隅にあるMetamaskロゴをクリックします。
            <br />
            利用規約を読み、同意します。 2〜3ページ分の条件に同意する必要がある場合があります。
            <br />
            パスワードを入力し、「作成」をクリックしてウォレットを作成します。
            <br />
            ボールトの12の「シードワード」のセットが表示されます。 「シードワードをファイルとして保存」をクリックし、ダウンロードした「メタマスクシードワード」ファイルを安全な場所にコピーします。ボールトにアクセスするために必要になります。<br />
            シードワードファイルが保護されたら、[安全な場所にコピーしました]をクリックします。<br />
            </Answer>
        </Faqs>
        <Faqs>
            <Question>1.3ウォレットをインポートする
            </Question>
            <Answer>
                -すでにETHウォレットをお持ちの場合は、それをメタマスクにインポートできます。                <br />
        -[インポート]タブをクリックします
        <br />
        - ウォレットの秘密鍵をコピーして貼り付けます
        <br />
        -「インポート」ボタンをクリックします
        <br />

            </Answer>
        </Faqs>
        <Faqs>
            <Question>1.4ウォレットをDEXに接続する
            </Question>
            <Answer>
                -ペアをクリックすると、交換ウィンドウに移動します
                <br />
                -左上にある「メタマスクの接続」をクリックします
        <br />
        -パスワードを入力してください
        <br />
        -[ログイン]をクリックします
        <br />
        -「接続」をクリックしてアカウントを接続します
        <br />
        -緑色のライトは、ウォレットが接続されていることを示します
        <br />
        -選択したペアのウォレットの残高が左上隅に表示されます。
        <br />

            </Answer>
        </Faqs>
        <Head>2.トークンのロックを解除する
        </Head>
        <Faqs>
            <Question>トークンを交換するには、トークンをアンロックする必要があります。
            </Question>
            <Answer>
                -「マイウォレット」に移動し、南京錠のアイコンをクリックします
                <br />
                -メタマスクでロック解除を確認
        <br />
        -トークンのロックが解除され、取引できるようになります（ETH）
        <br />
        -この場合、同じように取引したいトークン（ZRX）をアンロックします。
        <br />
            </Answer>
        </Faqs>
        <Faqs>
            <Question></Question>
            <Answer></Answer>
        </Faqs>
        <Faqs>
            <Question></Question>
            <Answer></Answer>
        </Faqs>
    </Wrap>
