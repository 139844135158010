import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
// import { assetDataUtils, BigNumber } from '0x.js';
import { BigNumber } from '@0x/utils';
import { get24hVolumeAction, getDepthChartAction, getExchangeChartAction } from '../../../store/actions';
import { Token, StoreState, Volume, exCharts, UIOrder, OrderBook, Web3State, OrderBookItem } from '../../../util/types';
import { getVolume, getBaseToken, getQuoteToken, getExchangeCharts, getOrders, getOrderBook, getEthAccount, getLanguage, getWeb3State } from '../../../store/selectors';
import { tokenAmountInUnits } from '../../../util/tokens';
import { PriceChart } from './chart_price';
import { DepthChart } from './chart_depth';
import { Socket } from '../../../services/socket';
import { isMobileOnly } from "react-device-detect";
import { assetDataUtils } from '@0x/order-utils';
import { MAX_CHART_LENGTH, SHOW_PRICE_CHART } from '../../../common/constants';

const I18n = require('i18n-js');
const Sockets = new Socket();

interface PropsAction {
    get24hVolume: (base: string, quote: string) => any,
    getExchangeChart: (pair: string, time: string, page: string) => any,
    getDepthChart: (pair: string, time: string, page: string) => any,
    OnPairSubcribe: (base: string, quote: string, address: string) => void,
}

interface active {
    active: boolean;
}

interface Props {
    baseToken: Token | null,
    quoteToken: Token | null,
    volume: Volume | null,
    exchangeCharts: exCharts[],
    orders: UIOrder[],
    orderBook: OrderBook;
    ethAccount: string;
    language: string;
    web3State?: Web3State;
}

interface State {
    timeSelect: string,
    tabIndex: number;
}

interface xy {
    x: number,
    y: number,
}
const syncTime = 60000;

class Charts extends React.Component<PropsAction & Props, State> {
    constructor(props: PropsAction & Props) {
        super(props);
        this.renderChart = this.renderChart.bind(this);
        this.renderBidAskChart = this.renderBidAskChart.bind(this);
    }
    private sync = false;
    public syncInter: number = 0;
    public pair: string = '';

    public componentDidMount = () => {
        this.syncData(this.props);
    }
    public timeShow = { '1h': '1h', '6h': '6h', '1D': '1D', '1W': '1W', '1M': '1M', }
    public state = {
        timeSelect: '1h',
        tabIndex: 1
    }

    public synChart() {
        const { getExchangeChart } = this.props;
        if (this.pair) {
            getExchangeChart(this.pair, this.state.timeSelect, '20');
        }
    }

    componentWillUnmount() {
        if (this.syncInter)
            clearInterval(this.syncInter);
    }

    componentWillReceiveProps(nextProps: any) {
        const { baseToken, quoteToken } = this.props;
        // const {baseToken, quoteToken, ethAccount, OnPairSubcribe } = this.props;
        // if (nextProps.web3State === Web3State.Done && baseToken && quoteToken) {
        //     const baseAssetData = assetDataUtils.encodeERC20AssetData(baseToken.address)
        //     const quoteAssetData = assetDataUtils.encodeERC20AssetData(quoteToken.address)
        //     OnPairSubcribe(baseAssetData, quoteAssetData, ethAccount);
        // }
        if (!this.sync || (baseToken && quoteToken && nextProps
            && (baseToken.symbol !== nextProps.baseToken.symbol
                || quoteToken.symbol !== nextProps.quoteToken.symbol)
        )) {
            this.syncData(nextProps);
        }
    }

    public syncData = (props: any) => {
        const { baseToken, quoteToken, get24hVolume, getExchangeChart } = props;
        if (baseToken && quoteToken) {
            const baseAssetData = assetDataUtils.encodeERC20AssetData(baseToken.address)
            const quoteAssetData = assetDataUtils.encodeERC20AssetData(quoteToken.address)
            get24hVolume(baseAssetData, quoteAssetData);
            const pair = this.pair = baseToken.symbol.toLocaleLowerCase() + '_' + quoteToken.symbol.toLocaleLowerCase();
            getExchangeChart(pair, this.state.timeSelect, '20');
            this.syncInter = setInterval(() => {
                this.synChart();
            }, syncTime);
            this.sync = true;
        }
    }

    public renderTime = () => {
        return Object.keys(this.timeShow).map((value, key) => {
            return (
                <P active={this.state.timeSelect === value} key={key} onClick={() => this.onSelectTime(value)} className={this.state.timeSelect === value ? 'time_active' : ''}>
                    {value}
                </P>
            )
        })
    }

    public onSelectTime(value: string) {
        this.sync = false;
        this.setState({ timeSelect: value }, () => {
            this.syncData(this.props);
        });
    }

    public renderChart = () => {
        const { exchangeCharts } = this.props;
        let data: exCharts[] = exchangeCharts;

        const series = [{
            data
        }];
        return (
            <PriceChart series={series} />
        )
    }

    public renderBidAskChart = () => {
        const { orderBook } = this.props;
        const { buyOrders, sellOrders } = orderBook;

        let bids = {
            name: I18n.t("bids"),
            data: [] as xy[]
        };
        let asks = {
            name: I18n.t("asks"),
            data: [] as xy[]
        };
        let maxBuy = new BigNumber(0);
        let minBuy = new BigNumber(0);
        // let leng = buyOrders.length > sellOrders.length ? sellOrders.length : buyOrders.length;
        // leng = leng > MAX_CHART_LENGTH ? MAX_CHART_LENGTH : leng;
        const maxLeng = buyOrders.length > MAX_CHART_LENGTH ? MAX_CHART_LENGTH : buyOrders.length;
        let width = new BigNumber(0);

        if (maxLeng > 0) {
            if (buyOrders) {
                let sum = new BigNumber(0);
                for (let i = 0; i < maxLeng; i++) {
                    if (i === 0) {
                        minBuy = maxBuy = buyOrders[i].price;
                        // asks.data.push({
                        //     x: +buyOrders[i].price.toString(),
                        //     y: 0,
                        // })
                    }
                    sum = sum.plus(new BigNumber(buyOrders[i].size));
                    bids.data.push({
                        x: +buyOrders[i].price.toString(),
                        y: +tokenAmountInUnits(sum),
                    })
                    if (buyOrders[i].price.isGreaterThan(maxBuy)) {
                        maxBuy = buyOrders[i].price;
                    }
                    if (buyOrders[i].price.isLessThan(minBuy)) {
                        minBuy = buyOrders[i].price;
                    }
                }
            }
            width = maxBuy.minus(minBuy);
            const maxWidth = maxBuy.plus(width);
            //Get all sellOrder >= maxBuyOrder
            let _sellOrders: OrderBookItem[] = [];
            if (sellOrders) {
                for (let i = sellOrders.length - 1; i >= 0; i--) {
                    // if (sellOrders[i].price.isGreaterThanOrEqualTo(maxBuy)) {
                    _sellOrders.push(sellOrders[i]);
                    // }
                }
            }
            let maxOrder = new BigNumber(0);
            let minOrder = new BigNumber(0);
            if (_sellOrders) {
                let sum = new BigNumber(0);
                for (let i = 0; i < _sellOrders.length; i++) {
                    if (i === 0) {
                        minOrder = maxOrder = _sellOrders[i].price;
                    }
                    sum = sum.plus(new BigNumber(_sellOrders[i].size));
                    if (_sellOrders[i].price.isGreaterThanOrEqualTo(0)) {
                        asks.data.push({
                            x: +_sellOrders[i].price.toString(),
                            y: +tokenAmountInUnits(sum),
                        })
                    }
                    else {
                        // asks.data.push({
                        //     x: +maxWidth.toString(),
                        //     y: +tokenAmountInUnits(sum),
                        // });
                        // break;
                    }
                    if (_sellOrders[i].price.isLessThan(minOrder)) {
                        minOrder = _sellOrders[i].price;
                    }
                    if (_sellOrders[i].price.isGreaterThan(maxOrder)) {
                        maxOrder = _sellOrders[i].price;
                    }
                }
            }
        }
        else {
            asks.data = bids.data = [{ x: 0, y: 0 }];
        }

        // const midPoint = {
        //     x: +width.toString(),
        //     y: 0
        // }
        // if (asks.data.length < 2)
        //     asks.data.unshift(midPoint);
        // if (bids.data.length < 2)
        //     bids.data.unshift(midPoint);
        return (
            <DepthChart series={[bids, asks]} />
        )
    }

    public renderTab(tabIndex: number) {
        return (
            <Tab>
                {SHOW_PRICE_CHART && <TabButton active={tabIndex === 0} onClick={() => this.setState({ tabIndex: 0 })}>{I18n.t('priceChart')}</TabButton>}
                <TabButton active={tabIndex === 1} onClick={() => this.setState({ tabIndex: 1 })}>{I18n.t('depthChart')}</TabButton>
            </Tab>
        )
    }

    public renderDropdown() {
        return (
            <>
                {/* <Img src={require('../../../assets/icons/noun_chart.svg')} /> */}
                <DropdownChart onChange={(e) => this.setState({ tabIndex: parseInt(e.currentTarget.value) })} defaultValue='0'>
                    <option value='0'>{I18n.t('priceChart')}</option>
                    <option value='1'>{I18n.t('depthChart')}</option>
                </DropdownChart>
            </>
        )
    }

    public render = () => {
        const { tabIndex } = this.state;
        let tabChart: React.ReactNode;
        tabChart = isMobileOnly ? this.renderDropdown() : this.renderTab(tabIndex)
        return (
            <ChartWrapp>
                {SHOW_PRICE_CHART ? tabChart : this.renderTab(tabIndex)}
                {tabIndex === 0 && <Time>{this.renderTime()}</Time>}
                {tabIndex === 0 && this.renderChart()}
                {tabIndex === 1 && this.renderBidAskChart()}
            </ChartWrapp>
        );
    };
}

const mapStateToProps = (state: StoreState): Props => {
    return {
        baseToken: getBaseToken(state),
        quoteToken: getQuoteToken(state),
        volume: getVolume(state),
        exchangeCharts: getExchangeCharts(state),
        orders: getOrders(state),
        orderBook: getOrderBook(state),
        ethAccount: getEthAccount(state),
        language: getLanguage(state),
        web3State: getWeb3State(state),
    };
};
const mapDispatchToProps = (dispatch: any): PropsAction => {
    return {
        get24hVolume: (base: string = '', quote: string = '') => dispatch(get24hVolumeAction(base, quote)),
        getExchangeChart: (pair: string, time: string, page: string) => dispatch(getExchangeChartAction(pair, time, page)),
        getDepthChart: () => dispatch(getDepthChartAction()),
        OnPairSubcribe: (base: string, quote: string, address: string) => dispatch(Sockets.OnPairSubcribe(base, quote, address))
    };

};

const App = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Charts);

export default App;

// const minHeight = isMobile ? 'initial' : '653px'

const ChartWrapp = styled.div`
    color: ${props => props.theme.componentsTheme.myWalletLinkColor};
    background: ${props => props.theme.componentsTheme.cardBackgroundColor};
    border: 1px solid ${props => props.theme.componentsTheme.cardBorderColor};
    margin: 0 0 10px 0;
    ${isMobileOnly ? ` .apexcharts-toolbar {
        top: 10px !important;
        right: 12px;
   }
   .hidden {width:0px;}
   `: ''}
   
`

const Time = styled.div`
    color: ${props => props.theme.componentsTheme.textColorCommon};
    text-align: right;
    position: absolute;
    right: ${isMobileOnly ? '110px' : '150px'};
    z-index: 9;
    top: 12px;
`

const P = styled.span  <active>`
    display: inline-block;
    cursor: pointer;
    border: 1px solid ${props => props.theme.componentsTheme.cardBorderColor};
    border-radius: 0;
    padding: 2px 5px;
    margin-left: -1px;
    font-size: 10px;
    background: ${props => props.active === true ? '#3FD3B8' : props.theme.componentsTheme.cardBorderColor}
`
const Tab = styled.div`
    text-align: left;
    border: 1px solid #E8E9EA;
    margin: 0 0 10px 0;
`;
const TabButton = styled.button <active>`
    font-size: 14px;
    border: 0;
    cursor: pointer;
    margin: 0;
    color: ${props => props.theme.componentsTheme.textColorCommon};
    padding: 14px;
    background: ${props => props.active === true ? props.theme.componentsTheme.background : 'transparent'}
    font-weight: ${props => props.active === true ? '600' : '100'};
`;

const DropdownChart = styled.select`
    background-color: #fff;
    border: none;
    color: ${props => props.theme.componentsTheme.textInputTextColor};
    font-size: 10px;
    font-weight: 600;
    text-align: left;
    position: absolute;
    z-index:9;
    top:12px;
    max-width: 80px !important;
    margin: 0;
`;

// const Img = styled.img`margin: 14px 0px 0px 15px;`
