import { BigNumber } from '@0x/utils';

import { isWeth } from './known_tokens';

export const tokenAmountInUnitsToBigNumber = (amount: BigNumber, decimals: number): BigNumber => {
    const decimalsPerToken = new BigNumber(10).pow(decimals);
    if (amount && typeof amount.div === 'function')
        return amount.div(decimalsPerToken);
    return new BigNumber(0);
};

export const tokenAmountInUnits = (amount: BigNumber, decimals: number = 18, toFixedDecimals = 18): string => {
    return '' + +tokenAmountInUnitsToBigNumber(amount, decimals).toFixed(toFixedDecimals);
};

export const unitsInTokenAmount = (units: string, decimals: number): BigNumber => {
    const decimalsPerToken = new BigNumber(10).pow(decimals);

    return new BigNumber(units).multipliedBy(decimalsPerToken);
};

export const tokenSymbolToDisplayString = (symbol: string): string => {
    return isWeth(symbol) ? 'wETH' : symbol.toUpperCase();
};
