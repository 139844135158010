
import React from 'react';
import styled from 'styled-components';
import { OrderType, OrderSide } from '../../../util/types';
import { IconType, Tooltip } from '../../common/tooltip';
import { BuySellContainer } from '../marketplace/buy_sell';
import { themeDimensions } from '../../../themes/commons';

const I18n = require('i18n-js');
interface Prop { }
interface State {
    orderType?: OrderType,
}

const BuySellContainers = <BuySellContainer />;
export { BuySellContainers }

export default class BuySellTabs extends React.Component<Prop, State> {
    public state: State = { orderType: OrderType.Limit }

    public changeTab = (orderType: OrderType) => () => this.setState({ orderType });

    public render = () => {
        const { orderType } = this.state;

        const toolTip = (
            <TooltipStyled description={I18n.t('limitTitle')} iconType={IconType.Fill} />
        );

        return (
            <>
                <TabsContainer>
                    <TabButton
                        isSelected={orderType === OrderType.Limit}
                        onClick={this.changeTab(OrderType.Limit)}
                        side={OrderSide.Buy}
                    >
                        {I18n.t('limit')}
                        {toolTip}
                    </TabButton>
                    <TabButton
                        isSelected={orderType === OrderType.Market}
                        onClick={this.changeTab(OrderType.Market)}
                        side={OrderSide.Sell}
                    >
                        {I18n.t('market')}
                    </TabButton>
                </TabsContainer>
                <BuySellWrapper>
                    <BuySell><BuySellContainer orderType={orderType} orderSide={OrderSide.Buy} /></BuySell>
                    <BuySell><BuySellContainer orderType={orderType} orderSide={OrderSide.Sell} /></BuySell>
                </BuySellWrapper>
            </>
        )
    }
}

const BuySell = styled.div`
    width: 50%;
`

const TabsContainer = styled.div`
    align-items: center;
    display: flex;
    border: 1px solid ${props => props.theme.componentsTheme.cardBorderColor};
    background: ${props => props.theme.componentsTheme.cardBackgroundColor};
`;

const TabButton = styled.div<{ isSelected: boolean; side?: OrderSide }>`
    align-items: center;
    background-color: ${props =>
        props.isSelected ? props.theme.componentsTheme.inactiveTabBackgroundColor : 'transparent'};
    border-bottom-color: ${props => (props.isSelected ? '#3FD3B8' : 'transparent')};
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-right-color: ${props => (props.isSelected ? props.theme.componentsTheme.cardBorderColor : 'transparent')};
    border-right-style: solid;
    border-right-width: 1px;
    /* color: ${props =>
        props.isSelected
            ? props.side === OrderSide.Buy
                ? props.theme.componentsTheme.green
                : props.theme.componentsTheme.red
            : props.theme.componentsTheme.textLight}; */
    cursor: ${props => (props.isSelected ? 'default' : 'pointer')};
    display: flex;
    font-weight: 600;
    padding: 11px 30px;
    justify-content: center;
    /* border-bottom: ${props => props.isSelected ? '2px' : '0px'} solid #3CB34F; */
    &:first-child {
        border-top-left-radius: ${themeDimensions.borderRadius};
    }

    &:last-child {
        border-left-color: ${props => (props.isSelected ? props.theme.componentsTheme.cardBorderColor : 'transparent')};
        border-left-style: solid;
        border-left-width: 1px;
        border-right: none;
        border-top-right-radius: ${themeDimensions.borderRadius};
    }
    color: ${props => props.theme.componentsTheme.textColorCommon};
`;
const TooltipStyled = styled(Tooltip)`
    margin-left: 10px;
`;
const BuySellWrapper = styled.div`
    margin-bottom: ${themeDimensions.verticalSeparationSm};
    display: flex;
    width: 100%;
`;
