
import React, { HTMLAttributes } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
// import { BigNumber, assetDataUtils } from '0x.js';
import { BigNumber } from '@0x/utils';
import { assetDataUtils } from '@0x/order-utils';
import { getTransactionLink } from '../../../../src/util/transaction_link';
import { getKnownTokens } from '../../../util/known_tokens';
import moment from 'moment';
// import { OrderStatus } from '0x.js';
import { Pagination } from '../../common/Pagination/Pagination';
import { UI_DECIMALS_DISPLAYED_PRICE_ETH } from '../../../common/constants';
import { tokenAmountInUnits, tokenAmountInUnitsToBigNumber } from '../../../util/tokens';
import { getEstimatedTxTimeMs, getUserHistories, getBaseToken, getQuoteToken, getWeb3State, getEthAccount } from '../../../store/selectors';
import { Card } from '../../common/card';
import { EmptyContent } from '../../common/empty_content';
import { LoadingWrapper } from '../../common/loading';
import { CustomTD, Table, THead, TR, TH } from '../../common/table';
import { OrderSide, StoreState, Token, Web3State, UserHistory } from '../../../util/types';
import { getUserHistoryFromAPI } from '../../../store/ui/actions';
import { FilterUserHistoryContainer } from './filter_user_history';
import { Socket } from '../../../services/socket';
import { fixBigNumber } from '../../../util/number_utils';

const I18n = require('i18n-js');
const Sockets = new Socket();

interface StateProps {
    estimatedTxTimeMs: number;
    userHistories: any;
    baseToken: Token | null;
    quoteToken: Token | null;
    web3State?: Web3State;
    ethAccount: string;
}
interface State {
    limit: number;
    currentPage: number;
    baseAssetData: string,
    quoteAssetData: string,
    isApiLoading: boolean,
}

interface UserHistoryProps {
    pageTrade?: boolean;
}

interface DispatchProps {
    getUserHistoryFromAPI: (currentPage: number, limit: number, baseAssetData?: string, quoteAssetData?: string) => any;
    listenSocketUserHistory: (address: string) => any;
}

type Props = HTMLAttributes<HTMLDivElement> & StateProps & DispatchProps & UserHistoryProps;

const SideTD = styled(CustomTD) <{ side: OrderSide }>`
    color: ${props =>
        props.side === OrderSide.Buy ? props.theme.componentsTheme.green : props.theme.componentsTheme.red};
            font-weight: 600;
            letter-spacing: 0.25px;
            line-height: 20px;
`;

const TBODY = styled.tbody`
    border-top: 1px solid ${props => props.theme.componentsTheme.tableBorderColor};
`;

const AHash = styled.a`
    display: inline-block;
    max-width: 300px;
    text-decoration: none;
`
const THH = styled(TH)`
	font-size: 14px;
	font-weight: bold;
	line-height: 32px;
`

const CardCustom = styled(Card)`
    max-width:1198px;
    margin:auto;
`
const TRCustom = styled(TR)`
border-top:1px solid #D0DDF5;
`
//height: 56px;
const orderToRow = (userHistories: any, index: number, ethAccount: any) => {
    try {
        const knownTokens = getKnownTokens();
        let quoteToken = knownTokens.getTokenByAssetData(userHistories.takerAssetData)
        let baseToken = knownTokens.getTokenByAssetData(userHistories.makerAssetData)
        // const takerAssetAmount = new BigNumber(userHistories.takerAssetAmount);
        // const makerAssetAmount = new BigNumber(parseInt(userHistories.makerAssetAmount));
        const filledAmount = new BigNumber(userHistories.filledAmount);
        if (userHistories.side === OrderSide.Sell) {
            const a = quoteToken;
            quoteToken = baseToken;
            baseToken = a;
        }

        const amount = tokenAmountInUnits(filledAmount, baseToken.decimals, baseToken.displayDecimals);
        //const takerAssetAmountInUnits = tokenAmountInUnitsToBigNumber(takerAssetAmount, baseToken.decimals);
        //const makerAssetAmountInUnits = tokenAmountInUnitsToBigNumber(makerAssetAmount, quoteToken.decimals);
        //const price = userHistories.side === 0 ? makerAssetAmountInUnits.div(takerAssetAmountInUnits) : takerAssetAmountInUnits.div(makerAssetAmountInUnits);
        let side = userHistories.side;
        if (userHistories.makerAddress === ethAccount) {
            side = userHistories.side === OrderSide.Buy ? OrderSide.Sell : OrderSide.Buy;
        }
        // const parsePrice = parseFloat(price.toString(10)).toFixed(UI_DECIMALS_DISPLAYED_PRICE_ETH);
        let parsePrice = userHistories.price ? fixBigNumber(+ new BigNumber(userHistories.price).toFixed(UI_DECIMALS_DISPLAYED_PRICE_ETH)) : '';
        //parsePrice = +parsePrice === 0 ? fixBigNumber(new BigNumber(price).toString()) : fixBigNumber(new BigNumber(price).toFixed(UI_DECIMALS_DISPLAYED_PRICE_ETH));
        const sideLabel = side === OrderSide.Sell ? I18n.t('sell') : I18n.t('buy');
        const timestamp = userHistories.orderedTime.toString();
        const Hash = userHistories.txHash;
        return (
            <TRCustom key={index}>
                <SideTD side={side}>{sideLabel}</SideTD>
                <CustomTD styles={{ textAlign: '', tabular: true }}>{+amount} ({baseToken.symbol})</CustomTD>
                <CustomTD styles={{ textAlign: '', tabular: true }}>{parsePrice} ({quoteToken.symbol})</CustomTD>
                <CustomTD styles={{ textAlign: 'right', tabular: true }}>
                    <AHash href={getTransactionLink(Hash)} target="_blank">
                        {Hash}
                    </AHash>
                </CustomTD>
                <CustomTD styles={{ textAlign: 'right', tabular: true, color: '#546497' }}>{moment.unix(timestamp / 1000).format('YYYY-MM-DD HH:mm:ss')}</CustomTD>
            </TRCustom>
        );
    } catch (error) {
    }
};

class UserHistoryList extends React.Component<Props, State, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            limit: 10,
            currentPage: 1,
            baseAssetData: '',
            quoteAssetData: '',
            isApiLoading: false,
        }
    }

    public componentDidMount = async () => {
        const { listenSocketUserHistory, ethAccount, pageTrade, getUserHistoryFromAPI } = this.props
        if (!pageTrade) {
            if (ethAccount) {
                listenSocketUserHistory(ethAccount);
                this.setState({ isApiLoading: true })
                await getUserHistoryFromAPI(this.state.currentPage, this.state.limit, this.state.baseAssetData, this.state.quoteAssetData);
                this.setState({ isApiLoading: false })
            }
        }
    }

    public componentWillReceiveProps = async (nextProps: any) => {
        const { listenSocketUserHistory, pageTrade, getUserHistoryFromAPI } = this.props
        if (!pageTrade) {
            if (nextProps.ethAccount && nextProps.ethAccount !== this.props.ethAccount) {
                listenSocketUserHistory(nextProps.ethAccount);
                await getUserHistoryFromAPI(this.state.currentPage, this.state.limit, this.state.baseAssetData, this.state.quoteAssetData);
            }
        }
    }

    public render = () => {
        const { userHistories, ethAccount, getUserHistoryFromAPI, pageTrade, baseToken, quoteToken } = this.props;
        const totalPages = Math.ceil(userHistories.total / this.state.limit) || 0;
        const total = userHistories.total || 0;
        let content: React.ReactNode;
        let action: React.ReactNode;
        action = <FilterUserHistoryContainer
            total={total}
            getData={(currentPage: number, limit: number, baseAssetData: string, quoteAssetData: string) => {
                this.setState({ currentPage, limit, baseAssetData, quoteAssetData });
                getUserHistoryFromAPI(currentPage, limit, baseAssetData, quoteAssetData);
            }}
        />;
        if (this.state.isApiLoading) {
            content = <LoadingWrapper minHeight="300px" />;
        } else if (userHistories.length === 0 || (userHistories.records && userHistories.records.length === 0)) {
            content = <EmptyContent style={{ minHeight: '300px' }} text={I18n.t('noData')} />;
        } else if (userHistories.records && userHistories.records.length > 0) {
            content = (
                <Table isResponsive={true}>
                    <THead style={{ height: '56px' }}>
                        <TRCustom style={{ borderTop: 'none' }}>
                            <THH>{I18n.t('side')}</THH>
                            <THH styles={{ textAlign: '' }}>{I18n.t('amount')}</THH>
                            <THH styles={{ textAlign: '' }}>{I18n.t('price')}</THH>
                            <THH styles={{ textAlign: 'right' }}>{I18n.t('transactionHash')} </THH>
                            <THH styles={{ textAlign: 'right' }}>{I18n.t('time')}</THH>
                        </TRCustom>
                    </THead>
                    <TBODY>{userHistories.records.map((userHistories: UserHistory, index: number) => orderToRow(userHistories, index, ethAccount))}</TBODY>
                </Table>
            );
        }
        if (pageTrade === true) {
            if (baseToken && quoteToken) {
                const baseAssetData = assetDataUtils.encodeERC20AssetData(baseToken.address);
                const quoteAssetData = assetDataUtils.encodeERC20AssetData(quoteToken.address);
                return <>
                    {content}
                    <Pagination
                        limit={this.state.limit}
                        currentPage={this.state.currentPage}
                        totalPages={totalPages}
                        getData={(page: any) => {
                            this.setState({ currentPage: page });
                            getUserHistoryFromAPI(page, this.state.limit, baseAssetData, quoteAssetData);
                        }}
                    /></>
            }
        }
        return <CardCustom style={{ minHeight: '350px' }} title={I18n.t('myHistory')} action={action}>
            {content}
            <Pagination
                limit={this.state.limit}
                currentPage={this.state.currentPage}
                totalPages={totalPages}
                getData={(page: any) => {
                    this.setState({ currentPage: page });
                    getUserHistoryFromAPI(page, this.state.limit, this.state.baseAssetData, this.state.quoteAssetData);
                }}
            />
        </CardCustom>;
    };
}

const mapStateToProps = (state: StoreState): StateProps => {
    return {
        estimatedTxTimeMs: getEstimatedTxTimeMs(state),
        userHistories: getUserHistories(state),
        baseToken: getBaseToken(state),
        quoteToken: getQuoteToken(state),
        web3State: getWeb3State(state),
        ethAccount: getEthAccount(state),
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getUserHistoryFromAPI: (currentPage: number, limit: number, baseAssetData?: string, quoteAssetData?: string) =>
            dispatch(getUserHistoryFromAPI(currentPage, limit, baseAssetData, quoteAssetData)),
        listenSocketUserHistory: (address: string) => dispatch(Sockets.listenSocketUserHistory(address))
    };
};

const UserHistoryContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserHistoryList);

export { UserHistoryList, UserHistoryContainer };
