import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div``
const Div = styled.div``
const Head = styled.h3``
const Bdy = styled.div``

export const vi = <Wrap>
    <Div>
        <Head>Cookie là gì?</Head>
        <Bdy>
            Cookies là các tệp văn bản, chứa một lượng nhỏ dữ liệu, được tải xuống trình duyệt của bạn khi bạn truy cập các trang web. Cookies có thể được nhận ra bởi trang web đã tải xuống chúng và điều này giúp các trang web biết nếu thiết bị duyệt đã truy cập chúng trước đó.</Bdy>
    </Div>
    <Div>
        <Head>Chức năng Cookies</Head>
        <Bdy>
            Cookie chức năng là rất cần thiết để cung cấp dịch vụ của chúng tôi. Chúng được sử dụng để ghi nhớ sở thích của bạn trên trang web của chúng tôi và để cung cấp trải nghiệm nâng cao và được cá nhân hóa. Thông tin được thu thập bởi các cookie này thường được ẩn danh, vì vậy chúng tôi không thể nhận dạng cá nhân bạn. Cookie chức năng không theo dõi việc sử dụng internet của bạn hoặc thu thập thông tin có thể được sử dụng để bán quảng cáo, nhưng chúng giúp cung cấp nội dung quảng cáo. Những cookie này thường là cookie phiên sẽ hết hạn khi bạn đóng phiên duyệt web.</Bdy>
    </Div>
    <Div>
        <Head>Quảng cáo Cookies</Head>
        <Bdy>
            Cookies quảng cáo nhớ rằng bạn đã truy cập một trang web và sử dụng dữ liệu đó để cung cấp cho bạn quảng cáo phù hợp với sở thích của bạn. Chúng cũng được sử dụng để giới hạn số lần bạn xem quảng cáo cũng như giúp đo lường hiệu quả của chiến dịch quảng cáo. Thông tin được thu thập bởi các cookie này có thể được chia sẻ với các đối tác bên thứ ba đáng tin cậy như nhà quảng cáo.</Bdy>
    </Div>
    <Div>
        <Head>Cookies thiết yếu</Head>
        <Bdy>
            Cookie thiết yếu là rất cần thiết để cung cấp dịch vụ của chúng tôi. Không có các cookie này, các phần của trang web của chúng tôi sẽ không hoạt động. Những cookie này không theo dõi nơi bạn đã truy cập internet, không nhớ các tùy chọn ngoài chuyến thăm hiện tại của bạn và không thu thập thông tin về bạn có thể được sử dụng cho mục đích tiếp thị. Những cookie này thường là cookie phiên sẽ hết hạn khi bạn đóng phiên duyệt web.
</Bdy>
    </Div>
    <Div>
        <Head>Cookie hiệu suất phân tích</Head>
        <Bdy>
            Cookie hiệu suất phân tích được sử dụng để theo dõi hiệu suất của trang web để xác định số lượt xem trang và số lượng người dùng duy nhất mà một trang web có. Dịch vụ phân tích trang web có thể được thiết kế và vận hành bởi các bên thứ ba. Thông tin được cung cấp bởi các cookie này cho phép chúng tôi phân tích các mẫu hành vi người dùng và chúng tôi sử dụng thông tin đó để nâng cao trải nghiệm người dùng hoặc xác định các khu vực của trang web có thể cần bảo trì. Thông tin là ẩn danh, không thể được sử dụng để nhận dạng bạn, không chứa thông tin cá nhân và chỉ được sử dụng cho mục đích thống kê.
</Bdy>
    </Div>
</Wrap>
