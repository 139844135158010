import React from 'react';
import { connect } from 'react-redux';
import { getLanguage } from '../../../store/selectors';
import { StoreState } from '../../../util/types';
import styled from 'styled-components';
import { isMobileOnly } from "react-device-detect";

const Banner = require('../../../assets/images/earn.png');
const I18n = require('i18n-js');
const EARN = 'https://forms.gle/4MpG92XFznMEPgwW6';

interface Props {
    language: string
}
const Div = styled.div`
    text-align: center;
    font-family: sans-serif;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-content: center;
    flex-direction: column;
    min-height: 500px;
    padding: 100px 5%;
    color: ${props => props.theme.componentsTheme.textColorCommon};
`

const Head = styled.h1`
    font-size: ${isMobileOnly ? '22px' : '30px'};
`
const Img = styled.img`
    width: ${isMobileOnly ? '250px' : '300px'};
`
const Earn = styled.a`
    padding: 10px 20px;
    border-radius: 5px;
    background: #D23934;
    margin: 0 0;
    cursor: pointer;
    border: none;
    color: #fff;
    text-transform: uppercase;
`
const Iframe = styled.iframe`
    width: 100%;
    height: 2200px;
`
const P = styled.p`
    margin: 50px 0;
`
const ILink = <Iframe src="https://forms.gle/4MpG92XFznMEPgwW6" />;

class _App extends React.Component<Props> {

    public render = () => {

        return (
            <Div>
                <Head>{I18n.t('earnPortal')}</Head>
                <Head>{I18n.t('comingSoon')}...</Head>
                <Img src={Banner} />
                <P>
                    <Earn target="_blank" href={EARN} >{I18n.t('registerInterest')}</Earn>
                </P>
            </Div>
        );
    };
}

const mapStateToProps = (state: StoreState): Props => {
    return {
        language: getLanguage(state)
    };
};

const App = connect(
    mapStateToProps,
    null,
)(_App);
export default App;
