import React from 'react';

export const TermsPage = <div class="Terms">
    <p class="c7"><span class="c22 c21 c28">TERMS OF SERVICE<br/></span></p>
        <p class="c1"><span class="c14">This Terms of Service Agreement (&nbsp;</span><span
            class="c11 c21">&quot;Terms&quot;</span><span class="c14">&nbsp;or&nbsp;</span><span
                class="c11 c21">&quot;Agreement&quot;</span><span class="c14">&nbsp;) is a contract between you
        (&nbsp;</span><span class="c11 c21">&quot;User&quot;</span><span class="c14">&nbsp;,&nbsp;</span><span
                class="c11 c21">&quot;you&quot;</span><span class="c14">&nbsp;or&nbsp;</span><span
                    class="c11 c21">&quot;your&quot;</span><span class="c14">&nbsp;) and Wowoo X Pte Ltd. (together with its
                    subsidiaries and
                    affiliates,&nbsp;&quot;Wowoo&quot;,&nbsp;&quot;we&quot;,&nbsp;&quot;us&quot;&nbsp;or&nbsp;&quot;our&quot;),
        operators of the ONDEX digital asset platform (&quot;ONDEX</span><span class="c11 c21">&nbsp;</span><span
                class="c14">&quot;&nbsp;or&nbsp;&quot;&nbsp;</span><span class="c11 c21">Site</span><span class="c0">&quot;,
        currently available at ONDEX ) that applies to your:</span></p>
        <ul class="c23 lst-kix_list_1-0 start">
            <li class="c29"><span class="c16">use of ONDEX to buy or sell digital assets;</span></li>
            <li class="c2"><span class="c24 c21 c25">use of the ONDEX Application Programming Interface (&ldquo;</span><span
                class="c15">API&rdquo;)</span><span class="c16">;</span></li>
            <li class="c2"><span class="c16">creation of a non-hosted software wallet for digital assets;</span></li>
            <li class="c2"><span class="c16">running of ONDEX staking software (&quot;ONDEX&quot;); or</span></li>
            <li class="c4"><span class="c25 c24 c21">use of any other services offered by us (together,
            the&nbsp;</span><span class="c15">&quot;Services&rdquo;)</span><span class="c16">.</span></li>
        </ul>
        <p class="c6"><span class="c0">This Agreement contains important information about the terms of our services.
        &nbsp;Please read it carefully. &nbsp;By using our Services, you agree and are bound by the terms
        below.</span></p>
        <p class="c1"><span class="c0">IMPORTANCE NOTICE: THIS AGREEMENT CONTAINS A BINDING ARBITRATION CLAUSE AND CLASS
        ACTION WAIVER THAT IMPACT YOUR RIGHTS ABOUT HOW TO RESOLVE DISPUTES. PLEASE READ IT CAREFULLY.</span></p>
        <p class="c6"><span class="c0">THE SERVICES ARE NOT OFFERED TO PERSONS WHO ARE LOCATED OR HAVE THE PLACE OF
        RESIDENCE IN ANY RESTRICTED JURISDICTIONS, AS DEFINED IN SECTION 7. </span></p>
        <p class="c1"><span class="c0">Subject to these Terms, Wowoo grants you a limited, non-exclusive, non-sublicensable
        and fully revocable license to access and use the Services during the time you comply with these
        Terms.</span></p>
        <p class="c13"><span class="c3">DEFINITIONS</span></p>
        <p class="c13 c19"><span class="c3"></span></p>
        <p class="c13"><span class="c11">Token holders:</span><span class="c17">&nbsp;owners of ERC20 tokens and ST20
        tokens, which are considered entries in a blockchain-based software ledger that represent crypto
        assets.</span></p>
        <p class="c13"><span class="c11">DEX:</span><span class="c17">&nbsp;online Information Service Provider that
        provides information consisting of the publication of purchase and sale token orders for private exchanging
        purposes between Token Holders.</span></p>
        <p class="c13"><span class="c11">Purchase Orders:</span><span class="c17">&nbsp;orders generated by the token
        holders to buy tokens, orders registered in a smart contract in the blockchain that upon acceptance by other
        token holders become transactions or transfers of tokens accepted by both parties.</span></p>
        <p class="c13"><span class="c11">Sales Orders:</span><span class="c17">&nbsp;orders generated by token holders to
        sell their tokens, orders registered in a smart contract in the blockchain that upon acceptance by other
        token holders become transactions or transfers of tokens accepted by both parties.</span></p>
        <p class="c13"><span class="c11">Exchange Information Service:</span><span class="c17">&nbsp;DEX online information
        service contains information related to active Token Holders purchase and sale Orders.</span></p>
        <p class="c1 c19"><span class="c0"></span></p>
        <p class="c8"><span class="c5">1. Description of Services</span></p>
        <p class="c6"><span class="c0">Wowoo provides a secure and convenient platform for buying and selling digital assets
        on the Ethereum network and using exclusively Ethereum based tokens (ERC20 and ST20). Users deposit their
        digital assets to the ONDEX smart contract and authorize Ethereum transactions through the Site to buy and
        sell those assets on the Ethereum network for other assets. Users may use our platform to buy and sell
        digital assets using the ONDEX smart contract. Our Services do not allow Users to buy and sell their assets
        for anything other than assets on the Ethereum network, i.e., blockchain-based tokens following the ERC20
        and ST20 standard. That means we do not support the conversion of fiat currency to digital assets, or vice
        versa.</span></p>
        <p class="c6"><span class="c0">For both parties involved in the trade, we charge a transaction fee and a gas charge
        to cover the gas expense of dispatching transactions (including withdrawals) to the Ethereum blockchain.
    </span></p>
        <p class="c1"><span class="c0">ONDEX is a non-custodial platform, which means we do not own, control, or take
        custody of any digital assets held by Users on the platform. Users maintain custody over their digital
        assets at all times.</span></p>
        <p class="c8"><span class="c5">2. Account Registration</span></p>
        <p class="c18"><span class="c10">By accessing or using the Services or by registering with us for an account
        (</span><span class="c24 c31">&quot;Account&quot;</span><span class="c10">),</span><span
                class="c24">&nbsp;</span><span class="c10">you agree that you have read, understood and accepted all the
        terms and conditions set out in this Agreement as well as our Privacy Policy.</span><span class="c0">You
            also represent that you have the legal authority to accept these Terms on behalf of yourself and any party
            you represent in connection with your use of the Services. If you are an individual entering these Terms on
            behalf of an entity, you represent and warrant that you have the power to bind that entity, and hereby agree
            to be bound by these Terms on behalf of that entity, with the terms &quot;you&quot; and &quot;your&quot;
        applying to you, that entity, and other users who access the Services on behalf of that entity.</span></p>
        <p class="c18"><span class="c0">In our sole and absolute discretion, we may refuse to allow you to open an account,
        suspend your account, consolidate accounts if you have more than one account or terminate your account or
        use one or more of the Services, without any liability to you or any third party. Such actions may be taken
        as a result of a number of factors, including without limitation the failure to positively identify you, by
        legal/regulatory demand, or your violation of the terms of this Agreement. We may also temporarily suspend
        access to your Account if a technical problem arises.</span></p>
        <p class="c8"><span class="c5">3. Security of your Account and Wallet</span></p>
        <p class="c1"><span class="c0">We are not an insured financial institution. As a result, the amounts displayed
        through the Services are not insured against loss, unlike funds held in most banks or credit unions. </span>
        </p>
        <p class="c1"><span class="c0">Furthermore, ONDEX is a non-custodial platform. As such, we do not know the digital
        asset wallets you use in connection with the Services, and do not have access to the private keys. You are
        therefore solely responsible for the confidentiality and security of your Accounts or devices that you use
        to access the Services. You are also responsible for maintaining the security of the digital asset wallets
        you use in connection with the Services, and ensuring that no unauthorized person has access to your wallet
        files, its private keys, or any device you use in connection with the Services.</span></p>
        <p class="c1"><span class="c0">We will not be liable for any loss or damage caused by your failure to protect your
        account or digital asset wallets.</span></p>
        <p class="c18"><span class="c5">4. Trading Terms</span></p>
        <p class="c1"><span class="c0">We provide a platform for Users to connect orders they enter with orders from other
        Users. Depending on the trading activity at the time an order is placed, orders may be partially filled or
        may be filled by a number of orders. </span></p>
        <p class="c1"><span class="c0">For several reasons, a transaction on ONDEX may fail, including without limitation a
        change in prices, order availability or technical difficulties experienced by us or Nodes. &nbsp;We do not
        make a representation or warrant that any transaction will be carried out in full or at all. We are, under
        no circumstances, liable for any loss or injury suffered by a failure to complete a transaction properly or
        in a timely manner including dispatch to the blockchain of Ethereum. Furthermore, we are in no way
        responsible for notifying you of a transaction failure, even though you can see any such failures on the
        Site. You are fully responsible for identifying and inquiring about the failure of any transaction you
        initiate. </span></p>
        <p class="c1"><span class="c0">The digital assets available for purchase through the Services may be subject to high
        or low volume of transactions, liquidity issues and potentially extended periods of volatility. WE MAY ALSO
        DELIST AN ASSET FROM TRADING ON ONDEX WITHOUT PRIOR NOTICE AT ANY TIME, FOR ANY REASON. You acknowledge that
        while we use commercially reasonable methods to provide you with trading availability and price data through
        our Services, we do not guarantee that the Services are available consistently.</span></p>
        <p class="c1"><span class="c0">You agree that you assume all of the risks and potential losses associated with
        delisted digital assets, price fluctuations, or differences in actual versus the prices indicated. </span>
        </p>
        <p class="c1"><span class="c0">We operate as a provider of platforms and generally do not act as principal or
        counterparty in relation to trades entered into on the platform. Notwithstanding the aforementioned, (i) we
        may act as a counterparty for limited trades made for the purpose of liquidating fees collected on User
        trades, and (ii) our affiliates or partners may execute trades on the platform.</span></p>
        <p class="c8"><span class="c5">5. Restricted Activities</span></p>
        <p class="c6"><span class="c0">In connection with your use of the Services, you will not:</span></p>
        <ul class="c23 lst-kix_list_2-0 start">
            <li class="c29"><span class="c16">Breach or assist any party in breaching any law, statute, ordinance,
            regulation or any rule of any self-regulatory or similar organization of which you are or are required
            to be a member by using the Services;</span></li>
            <li class="c2"><span class="c16">Involve to any trading activity that, at our sole discretion, amounts to or
            could amount to market abuse including without limitation pumping and dumping, entering fictitious
            transactions or wash trading, front running, bear raiding or involving in disorderly market
            conduct;</span></li>
            <li class="c2"><span class="c16">Infringe the copyright, patent, trade-mark or other intellectual property
            rights of our or any third party;</span></li>
            <li class="c30"><span class="c16">Take any action that imposes an unreasonable or disproportionate burden on our
            infrastructure or our Nodes, or adversely interfere with, or expropriate any system, data or
            information;</span></li>
            <li class="c2"><span class="c16">transfer or upload any material containing viruses, Trojan horses, worms or any
            other harmful programs to the Site or to Nodes;</span></li>
            <li class="c2"><span class="c16">attempt to obtain unauthorized access or use of the Site, the API, other
            Accounts, Nodes or computer systems connected to the Site; or</span></li>
            <li class="c4"><span class="c16">engage in any behaviour which violates this Agreement or is otherwise
            considered unacceptable by us in our sole discretion.</span></li>
        </ul>
        <p class="c9"><span class="c16"></span></p>
        <p class="c8"><span class="c5">7. Restricted Jurisdictions</span></p>
        <p class="c1"><span class="c0">By using the Services, you represent that your actions are not in not in violation of
        all applicable export control restrictions subjected to the Services. You may not use the Services if you
        are (i) a resident, national or agent of Crimea, Cuba, Iran, North Korea, Syria or any other country or
        region to which the United States embargoes goods or imposes similar sanctions (&quot;Restricted
        Jurisdictions&quot;); (ii) a resident, national or agent of the U.S. states New York and Washington; (iii) a
        member of any sanctions list or equivalent maintained by the Government of the United States
        (&quot;Restricted Persons&quot;); or (iv) you intend to deal with any Restricted Jurisdictions or Restricted
        Persons.</span></p>
        <p class="c8"><span class="c5">8. Risk Disclosures</span></p>
        <p class="c6"><span class="c0">It&#39;s risky to trade digital assets and you should never trade more than they can
        afford to lose. The following list of risks associated with digital assets and the Services is not
        exhaustive.</span></p>
        <p class="c6"><span class="c14 c20">Risks of digital assets trading</span></p>
        <p class="c1"><span class="c0">The prices of digital assets are highly unstable, and digital asset trading is
        extremely risky. Moreover, factors beyond our control may affect market liquidity for a particular digital
        asset, such as regulatory activity, market manipulation, the digital asset issuer&#39;s acts or omissions or
        unexplainable price instability. We may also determine that it is in Users&#39; best interest to suspend
        trading in a digital asset for these reasons. By using the Services, you accept the risk of trading digital
        assets. You represent that you have been, are and will be solely responsible for making your own independent
        assessment into the risks of the transaction and the underlying digital asset.</span></p>
        <p class="c6"><span class="c0">Our listing of a digital asset does not specify approval or disapproval of the merits
        of the digital asset as an investment or as a technology. &nbsp;We do not give any guarantee regarding the
        suitability of the digital asset traded under this Agreement and assume no fiduciary duty in our relations
        with you. When determining whether to buy or sell a particular digital asset, you must perform your own due
        diligence. We will not share in any portion of your gains if you choose wisely and we will not bear any
        portion of your losses if you chose poorly.</span></p>
        <p class="c6"><span class="c14 c22 c20">No brokerage or investment advisory services </span></p>
        <p class="c1"><span class="c0">We provide a service that is for execution only. We do not broker transactions on
        your behalf, advise on the merits of any specific digital assets, transactions or associated risks or
        provide any other financial, investment or legal advice related to the Services. To the extent that we do
        provide trading data, post market commentary, articles or posts on social media, this is incidental to your
        relationship with us, and such information may not be regarded as investment or financial advice and should
        not be interpreted as such. ANY DECISION TO BUY OR SELL DIGITAL ASSETS IS SOLELY UNDER DECISION OF USERS AND
        WE WILL NOT BE LIABLE FOR ANY CONSEQUENT LOSS.</span></p>
        <p class="c6"><span class="c14 c20 c22">No Liquidity Promise </span></p>
        <p class="c1"><span class="c0">The ability for you on ONDEX to buy or sell digital assets depends on a counterparty
        willing to buy or sell the same digital assets. We make no promises regarding the timing or availability of
        buying or selling digital assets on the Site. We reserve the right, without notice and without liability, to
        change, suspend or discontinue any aspect of the Services at any time and in any jurisdiction. This
        includes, without limitation, the absolute discretion to list and de-list digital assets available for
        trading at any time. We are not required to give prior notice of delisting.</span></p>
        <p class="c6"><span class="c0">You acknowledge and agree that digital assets and/or features in one jurisdiction may
        not be available for trading or access in another.</span></p>
        <p class="c6"><span class="c14 c20">No guarantee of continuous access to Services</span></p>
        <p class="c1"><span class="c0">We do not guarantee continuous, uninterrupted or secure access to any part of the
        Services, and many factors beyond our control, including regulatory reasons and issues with the Ethereum
        network that may interfere with the operation of our Site. We will make reasonable efforts to make sure
        transactions are processed in a timely manner, but we do not make representations or warranties as to the
        amount of time required to complete transactions.</span></p>
        <p class="c8"><span class="c5">9. Taxes</span></p>
        <p class="c1"><span class="c0">You are responsible to determine what, if any, taxes apply to the payments you make
        or receive, and to collect, report, and transfer the correct tax to the appropriate tax authority. We will
        make any tax withholdings or filings that we are required to make by law, but we are not responsible for
        determining whether taxes are applicable to your transaction or for collecting, reporting or remitting any
        taxes that arise from any transaction. You are encouraged to speak regarding the tax implication of using
        the Services with your personal tax advisor.</span></p>
        <p class="c8"><span class="c5">10. Indemnification and Release</span></p>
        <p class="c1"><span class="c0">ONDEX and its subsidiaries, parents, affiliates, service providers, and of their
        respective officers, directors, agents, joint ventures, employees or representatives (collectively,
        the&nbsp;&quot;Released Parties&quot;), shall not be responsible for any damages which may result from or in
        connection with your use of the Services. This includes, but is not limited to, claims, applications,
        injuries, delays, direct loss, loss of business or profits, cost of business interruptions, loss of goodwill
        or business profits, loss of cryptocurrency or digital assets, damage caused by your computer, computer
        software, systems and programs, and the data thereon or any other direct or indirect, consequential or
        incidental damages. The Released Parties are not responsible for any losses incurred, either directly or
        indirectly, by using the Site or any of its functions and features (collectively, all of the above mentioned
        items are referred to as &quot;losses&quot; herein). You hereby release the Released Parties from liability
        for all and any losses. These limitations of liability apply whether the liability or loss is based on
        negligence, contract, tort, or any other basis, even if the released parties have been advised of such
        losses or should have known about the possibility. </span></p>
        <p class="c1"><span class="c0">You agree to indemnify and hold the Released Parties harmless from any claim or
        losses (including attorneys&rsquo; fees and any losses, fines, fees, or penalties imposed by any regulatory
        authority) resulting from violation of this Agreement, or your violation of any law or regulation.</span>
        </p>
        <p class="c1"><span class="c0">If you have a dispute with one or more Users, Nodes, Digital Assets issuers listed on
        ONDEX or other third parties, you release the Released Parties of any and all claims, claims and damages
        (actual and consequential) of any kind and nature arising out of or in any way related to such
        disputes.</span></p>
        <p class="c8"><span class="c5">11. LIMITATION OF LIABILITY; NO WARRANTY</span></p>
        <p class="c1"><span class="c0">YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE RELEASED PARTIES SHALL NOT BE LIABLE FOR
        ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS
        INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF
        SUCH RELEASED PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT,
        NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, RESULTING FROM: (I) THE INABILITY TO USE OR THE USE OF THE
        SERVICES; (II) THE COST OF PROCUREMENT OF SUBSTITUTE SERVICES RESULTING FROM ANY DATA, INFORMATION, OR
        SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE
        SERVICES; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; OR (IV) ANY OTHER MATTER
        RELATING TO THE SERVICES.</span></p>
        <p class="c6"><span class="c0">IF YOU ARE NOT SATISFIED WITH ANY PORTION OF THE SERVICES OR WITH THIS AGREEMENT,
        YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE ANY USE OF THE SERVICES AND CLOSE YOUR ACCOUNT. THE
        SERVICES ARE OFFERED&nbsp;&quot;AS IS&quot;&nbsp;AND WITHOUT ANY REPRESENTATION OR WARRANTY, WHETHER EXPRESS
        OR IMPLIED. </span></p>
        <p class="c1"><span class="c0">THE RELEASED PARTIES SPECIFICAL DISCLAIM ANY IMPLIED WARRANTIES OF TITLE,
        MERCHANTABILITY, FITNESS OR NON-INFRINGEMENT OF A PARTICULAR PURPOSE. THE RELEASED PARTIES MAKE NO WARRANTY
        THAT (I) THE SERVICES WILL MEET YOUR CRITERIAS, (II) THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SAFE, OR
        ERROR-FREE, OR (III) THE QUALITY OF ANY DIGITAL ASSETS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED
        OR OBTAINED BY YOU WILL MEET YOUR EXPECTATIONS.</span></p>
        <p class="c8"><span class="c5">12. Governing Law; Venue and Arbitration</span></p>
        <p class="c6"><span class="c0">You agree to arbitrate any dispute arising out of these Terms or the Services you
        use. Arbitration does not allow you to sue in court or to have a jury trial. You agree to notify each other
        of any dispute in writing within 60 days of the date it arises. </span></p>
        <p class="c6"><span class="c0">In addition, you agree:</span></p>
        <ul class="c23 lst-kix_list_3-0 start">
            <li class="c29"><span class="c0">To attempt to resolve informally before any arbitration request; </span></li>
            <li class="c2"><span class="c0">That any arbitration will occur in Singapore;</span></li>
            <li class="c2"><span class="c0">That any arbitration will be conducted confidentially by a single arbitrator,
            selected by a third-party arbitration forum;</span></li>
            <li class="c2"><span class="c0">That Singapore courts in the United States have exclusive jurisdiction over any
            appeals of an arbitration award and over any suit between the parties not subject to arbitration;</span>
            </li>
            <li class="c2"><span class="c0">That the arbitrator has the power to grant any remedy that would otherwise be
            available in court; and</span></li>
            <li class="c4"><span class="c0">That the parties shall split any arbitration costs and expenses and bear their
            own legal costs and expenses. </span></li>
        </ul>
        <p class="c6"><span class="c0">Any dispute between the parties shall be governed by these Terms of Services and
        &nbsp;shall be governed by and construed in accordance with the law of Singapore, without giving effect to
        any conflict of principles of law which may provide for the application of the law of another
        jurisdiction.</span></p>
        <p class="c6 c19"><span class="c0"></span></p>
        <p class="c6"><span class="c0">You will only be allowed to pursue claims against us on an individual basis, not as a
        plaintiff or class member in any class or representative action or proceeding and you will only be allowed
        to seek relief on an individual basis (including monetary, injunctive and declaratory relief).</span></p>
        <p class="c8"><span class="c5">13. Miscellaneous</span></p>
        <p class="c8"><span class="c12">a. Headings</span></p>
        <p class="c8"><span class="c0">The heading and explanatory text shall be for reference purposes only and shall in no
        way define, limit, construe or describe the scope or extent of such section</span></p>
        <p class="c8"><span class="c12">b. Force Majeure</span></p>
        <p class="c1"><span class="c0">We shall not be liable for any failure or delay resulting from abnormal or unexpected
        circumstances beyond our reasonable control, the consequences of which would have been unavoidable despite
        all efforts to the contrary, including without limitation natural disasters (earthquake, fire, flood or
        other acts of God) , governmental action or acts of terrorism, labour conditions, delays or failures caused
        by problems with another system or network, mechanical breakdown or data-processing failures or where we are
        bound by other legal obligations.</span></p>
        <p class="c8 c19"><span class="c12"></span></p>
        <p class="c8"><span class="c12">c. Amendments</span></p>
        <p class="c6"><span class="c0">We may at any time amend any portion of this Agreement by posting the revised version
        of this Agreement with an updated date for the revision. The changes will take effect, and will be deemed
        accepted by you, the first time you use the Services after the initial posting of the revised Agreement, and
        will apply in the future for transactions initiated after the posting date.</span></p>
        <p class="c8"><span class="c12">d. Survival</span></p>
        <p class="c8"><span class="c0">Once your account or this Agreement has been terminated for any other reason, all
        rights and obligations of the parties which are continuing by their nature will survive such termination.
    </span></p>
        <p class="c8"><span class="c12">e. Waiver and Conflict</span></p>
        <p class="c1"><span class="c0">Wowoo DEX&#39;s failure to exercise or enforce any of the Agreement&#39;s rights or
        provisions shall not constitute a waiver of that right or provision. Where any provision of this Agreement
        shall be adjudged by any court of competent jurisdiction to be unenforceable or invalid, that provision
        shall be limited or eliminated to the minimum necessary extent so that this Agreement otherwise remains in
        full force and effect and shall remain enforceable between the parties.</span></p>
        <p class="c8"><span class="c12">f. Third Party Rights</span></p>
        <p class="c6"><span class="c0">This Agreement is not intended and shall not be construed to create any rights or
        remedies in any other party than you and us and any affiliates each of whom shall be a third-party
        beneficiary of this Agreement, and no other person shall claim any rights as a third-party beneficiary of
        this Agreement. </span></p>
        <p class="c6"><span class="c0">If you have any questions about these Terms, please contact us at&nbsp;support@ondex.io.</span></p>
        <p class="c13 c19"><span class="c22 c27"></span></p>
</div>
