import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { BigNumber } from '@0x/utils';
import { get24hVolumeAction } from '../../../store/actions';
import { Token, StoreState, Volume } from '../../../util/types';
import { getVolume, getBaseToken, getQuoteToken } from '../../../store/selectors';
import { tokenAmountInUnits } from '../../../util/tokens';
import { themeBreakPoints } from '../../../../src/themes/commons';
import { TokenIcon } from '../../common/icons/token_icon';
import { assetDataUtils } from '@0x/order-utils';
import { fixBigNumber } from '../../../util/number_utils';
import { UI_DECIMALS_DISPLAYED_PRICE_ETH } from '../../../common/constants';

const I18n = require('i18n-js');

interface PropsAction {
    get24hVolume: (base: string, quote: string) => any,
}

interface Props {
    baseToken: Token | null,
    quoteToken: Token | null,
    volume: Volume | null
}

const ChartWrapp = styled.div`
    color: ${props => props.theme.componentsTheme.myWalletLinkColor};
    background: ${props => props.theme.componentsTheme.cardBackgroundColor};
    border: 1px solid ${props => props.theme.componentsTheme.cardBorderColor};
    padding: 10px;
    margin: 0 0 0 0;
    font-size: 11px;
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: space-between;
    margin: 0;
`
const Ul = styled.ul`
    padding: 0;
    @media (max-width: ${themeBreakPoints.md}) {
        display: block;
    }
`;

const LI = styled.li`
    margin: 0;
    padding: 0;
    line-height: normal;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
`

const Sp = styled.span`
    margin: 0 10px 0 0;
`

const Spa = styled.span`
    color: #464FFF;
    font-weight: bolder;
`
const Span = styled(Spa) <{ value: number }>`
    color: ${props => props.value >= 0 ? props.theme.componentsTheme.green : props.theme.componentsTheme.red};
`;
const Symbol = styled.span`
    text-transform: uppercase;
`
const TokenIconPair = styled(TokenIcon)`
    position: relative;
    top: -3px;
    margin: 0 10px 0 0;
`
const ASP = styled(Spa)`
    color: ${props => props.theme.componentsTheme.textColorCommon};
`
const syncTime = 60000;

class Charts extends React.Component<PropsAction & Props> {
    public syncInter: number = 0;

    public componentDidMount = () => {
        this.synVolumn();
        this.syncInter = setInterval(() => {
            this.synVolumn();
        }, syncTime);
    }

    public synVolumn() {
        const { baseToken, quoteToken, get24hVolume } = this.props;
        if (baseToken && quoteToken) {
            let baseAssetData = assetDataUtils.encodeERC20AssetData(baseToken.address)
            let quoteAssetData = assetDataUtils.encodeERC20AssetData(quoteToken.address)
            get24hVolume(baseAssetData, quoteAssetData);
        }
    }

    componentWillUnmount() {
        if (this.syncInter)
            clearInterval(this.syncInter);
    }

    public render = () => {
        const { baseToken, quoteToken, volume } = this.props
        const key = (baseToken && quoteToken) ? (baseToken.symbol.toLocaleLowerCase() + '_' + quoteToken.symbol.toLocaleLowerCase()) : '';
        const _key = (baseToken && quoteToken) ? (quoteToken.symbol.toLocaleLowerCase() + '_' + baseToken.symbol.toLocaleLowerCase()) : '';
        const data = volume ? (volume[key] ? volume[key] : volume[_key]) : null;
        const startPrice = (data && baseToken) ? fixBigNumber(new BigNumber(data.startPrice).toFixed(UI_DECIMALS_DISPLAYED_PRICE_ETH)) : 0;
        const endPrice = (data && baseToken) ? fixBigNumber(new BigNumber(data.endPrice).toFixed(UI_DECIMALS_DISPLAYED_PRICE_ETH)) : 0;
        // const startPrice = (data && baseToken) ? tokenAmountInUnits(new BigNumber(data.startPrice), 0, baseToken.displayDecimals) : 0;
        // const endPrice = (data && baseToken) ? tokenAmountInUnits(new BigNumber(data.endPrice), 0, baseToken.displayDecimals) : 0;
        const symbol = quoteToken ? quoteToken.symbol.toUpperCase() : '';
        const _volume = data && data.volume ? data.volume : 0;
        const amount = (baseToken) ? tokenAmountInUnits(new BigNumber(_volume), baseToken.decimals, baseToken.displayDecimals) : 0;
        const change24h = data && data.change24h.toFixed(2).toString() ? data.change24h.toString() : '0';
        const symbolAmount = baseToken ? baseToken.symbol.toUpperCase() : '';
        return (
            <ChartWrapp>
                <Ul>
                    {baseToken && quoteToken && <LI><TokenIconPair symbol={baseToken.symbol} isInline={true} icon={baseToken.icon} primaryColor={baseToken.primaryColor} /><Symbol>{baseToken.symbol}/{quoteToken.symbol}</Symbol></LI>}
                </Ul>
                <Ul>
                    <LI><Sp>{I18n.t('openPrice')}:</Sp><Spa>{startPrice} {symbol}</Spa></LI>
                    <LI><Sp>{I18n.t('closePrice')}:</Sp><Spa>{endPrice} {symbol}</Spa></LI>
                    <LI><Sp>{I18n.t('change24hr')}: </Sp><Span value={+change24h}>{+change24h} {'%'}</Span></LI>
                    <LI><Sp>{I18n.t('volume24hr')}: </Sp> <ASP>{+amount} {symbolAmount}</ASP> </LI>
                </Ul>
            </ChartWrapp>
        );
    };
}

const mapStateToProps = (state: StoreState): Props => {
    return {
        baseToken: getBaseToken(state),
        quoteToken: getQuoteToken(state),
        volume: getVolume(state)
    };
};
const mapDispatchToProps = (dispatch: any): PropsAction => {
    return {
        get24hVolume: (base: string = '', quote: string = '') => dispatch(get24hVolumeAction(base, quote)),
    };

};

const App = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Charts);

export default App;

