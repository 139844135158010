import { BigNumber } from '@0x/utils';
import axios from 'axios';
import { Volume, exCharts, depCharts, Volume24H } from '../util/types';
import { VOLUME_URL, EXCHANGE_CHART_URL, DEPTH_CHART_URL, PRICE_USD_ULR } from '../common/constants';
import { getKnownTokens } from '../util/known_tokens';

const ETH_MARKET_PRICE_API_ENDPOINT = 'https://api.coinmarketcap.com/v1/ticker/ethereum/';

export const getMarketPriceEther = async (): Promise<BigNumber> => {
    try {
        const response = await axios.get(PRICE_USD_ULR);
        if (response && response.data.price_usd) {
            const priceTokenUSD = new BigNumber(response.data.price_usd);
            return priceTokenUSD
        }
    } catch (error) {
        const promisePriceEtherResolved = await fetch(ETH_MARKET_PRICE_API_ENDPOINT);
        if (promisePriceEtherResolved.status === 200) {
            const data = await promisePriceEtherResolved.json();
            if (data && data.length) {
                const item = data[0];
                const priceTokenUSD = new BigNumber(item.price_usd);
                return priceTokenUSD;
            }
        }
    }
    return Promise.reject('Could not get ETH price');
};

export const get24hVolume = async (base: string = '', quote: string = ''): Promise<Volume> => {
    let para = '';
    if (base && quote) {
        para = `&baseAssetData=${base}&quoteAssetData=${quote}`;
    }
    const response = await axios.get(VOLUME_URL + para);
    if (response && response.data && response.data.records) {
        let { records } = response.data;
        let data: Volume = {};
        const knownTokens = getKnownTokens();
        for (let i = 0; i < records.length; i++) {
            const quoteToken = knownTokens.getTokenByAssetData(records[i].takerAssetData)
            const baseToken = knownTokens.getTokenByAssetData(records[i].makerAssetData)
            const endPrice = new BigNumber(records[i].endPrice);
            const startPrice = new BigNumber(records[i].startPrice);
            const change24h = endPrice.minus(startPrice).div(startPrice).multipliedBy(100) || 0;
            const price = new BigNumber(records[i].price || 0);
            const _data: Volume24H = {
                endPrice: records[i].endPrice || 0,
                startPrice: records[i].startPrice || 0,
                volume: records[i].volume || 0,
                takerAssetData: records[i].takerAssetData,
                makerAssetData: records[i].makerAssetData,
                change24h: change24h,
                price: price,
            }
            const key = records[i].pair ? records[i].pair : (baseToken.symbol.toLocaleLowerCase() + '_' + quoteToken.symbol.toLocaleLowerCase());
            data[key] = _data;
        }
        return data;
    }
    return {};
}

export const getExchangeChart = async (pair: string, time: string = '6h', page: string = '50'): Promise<exCharts[]> => {
    const url = EXCHANGE_CHART_URL.replace('{pair}', pair).replace('{time}', time).replace('{page}', page)
    const response = await axios.get(url);
    if (response && response.data && response.data.records) {
        let data = response.data.records;
        for (let i = 0; i < data.length; i++) {
            const x = new Date(data[i].x);
            data[i].x = x;
        }
        return data;
    }
    return [];
}

export const getDepthChart = async (pair: string, time: string = '6h', page: string = '50'): Promise<depCharts[]> => {
    const url = DEPTH_CHART_URL.replace('{pair}', pair).replace('{time}', time).replace('{page}', page)
    const response = await axios.get(url);
    if (response) {
        return [
            { x: 0, y: 0 }
        ]
    }
    return [];
}
