import React, { HTMLAttributes } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getEthAccount, getWeb3State, getLanguage } from '../../../store/selectors';
import { StoreState } from '../../../util/types';
import { CONTACTUS } from '../../../common/constants';
import { themeBreakPoints, themeDimensions } from '../../../themes/commons';
import axios from 'axios';
import { ErrorCard, ErrorIcons, FontSize } from '../../common/error_card';
import { SuccessCard, SuccessIcons } from '../../common/success_card';
import { isMobile } from 'react-device-detect';

const I18n = require('i18n-js');

interface OwnProps extends HTMLAttributes<HTMLSpanElement> {
}

interface StateProps {
    ethAccount: string;
    web3State: string;
    language: string;
}

interface DispatchProps {
}

type Props = StateProps & DispatchProps;

interface State {
    error: string | '';
    success: string | '';
    attachments: string | any;
    firstName: string | '';
    lastName: string | '';
    email: string | '';
    phone: string | '';
    typeSupport: string | '';
    ticketType: string | '';
    numberOfToken: string | '';
    addressSupport: string | '';
    passportNumber: string | '';
    description: string | '';
}

const Wrapper = styled.div`
    background-color:#9fc3e1;
`;

const Content = styled.div`
    text-align: left;
    color: ${props => props.theme.componentsTheme.textColorCommon};
    padding: 10px 5%;
    max-width: ${themeBreakPoints.xxl};
    margin: 0 auto;
`;

const Textarea = styled.textarea`
    width: 100%;
    margin: 5px 0 30px 0;
    border: none;
`
const Input = styled.input`
    width: 100%;
    line-height: 30px;
    border: none;
`
const Radiobt = styled.input`
    width: 20%;
    line-height: 30px;
    height:20px;
`

const FormSection1 = styled.div`
display:  ${isMobile ? 'block' : 'flex'};
justify-content: space-between;
`;
const FormSectionDiv = styled.div`
width: 100%;
`;
const FormSectionDivL = styled.div`
width: 50%;
float: left;
padding-right:1%
`;
const FormSectionDivR = styled.div`
width: 50%;
float: left;
padding-left:1%
`;
const FormSection1Div = styled.div`
    width: ${isMobile ? '100%' : '49%'};
`;
const FormSection2Div = styled.div`
    width: ${isMobile ? '50%' : '25%'};
    float: ${isMobile ? 'left' : 'unset'};
    margin-top: 15px;
`;

const H1 = styled.h1`
    font-size: 36px;
    text-transform: uppercase;
    line-height: 49px;
    letter-spacing: 2px;
    font-weight: 300;
    color: ${props => props.theme.componentsTheme.cardTitleColor};
`;

const P = styled.p`
    margin-bottom: 5px;
`;
const Pfloat = styled.p`
    margin-bottom: 5px;
    float:left
`;
const LabelP = styled.label`
    margin-bottom: 5px;
    float:left
`;
const Button = styled.button`
    line-height: 30px;
    color: white;
    padding: 5px 0px;
    margin-bottom: 30px;
    border-radius: 5px;
    background: #186ab5;
    cursor: pointer;
    border: none;
    width: 100%;
    line-height: 30px;
`;
const Span = styled.span`
    font-size: 14px;
    text-transform: uppercase;
    margin: 0 0 0 20px;
    color: ${props => props.theme.componentsTheme.buttonTextColor};
`;
const DivErr = styled.span`color:red; font-weight:bold`
const DivSuccess = styled.span`color:green; font-weight:bold`

const Des = styled.div`
    text-align: center;
`;

const Number = styled.p`
    opacity: 0.5;
    font-size: 150px;
    margin: 5px 0;
`;
class Contact extends React.Component<Props, State> {
    public readonly state: State = {
        attachments: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        typeSupport: "CUSTOMER SUPPORT",
        ticketType: '',
        numberOfToken: '',
        addressSupport: '',
        passportNumber: '',
        description: '',
        error: '',
        success: '',
    };

    public clearMess = () => {
        setTimeout(() => {
            this.setState({
                error: '',
                success: '',
            });
        }, 5000);
    }

    public onSubmit = async () => {
        const urlContactForm = CONTACTUS;
        const { email, description } = this.state;
        var bodyFormData = new FormData();
        this.setState({
            error: '',
        });
        if (!email) {
            this.setState({
                error: I18n.t('enterYourEmail')
            });
            this.clearMess();
            return;
        }
        if (!description) {
            this.setState({ error: I18n.t('enterYourDes') });
            this.clearMess();
            return
        }

        const isEmail = () => {
            const reg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;/* eslint-disable-line */
            // if(lock === true) return false;
            if (email && email.match(reg))
                return true;
            return false;
        };
        if (!isEmail()) {
            this.setState({ error: I18n.t('emailInvalid') });
            this.clearMess();
            return;
        }

        bodyFormData.append('attachments', this.state.attachments);
        bodyFormData.append('firstName', this.state.firstName);
        bodyFormData.append('lastName', this.state.lastName);
        bodyFormData.append('email', this.state.email);
        bodyFormData.append('phone', this.state.phone);
        bodyFormData.append('typeSupport', this.state.typeSupport);
        bodyFormData.append('ticketType', this.state.ticketType);
        bodyFormData.append('numberOfToken', this.state.numberOfToken);
        bodyFormData.append('addressSupport', this.state.addressSupport);
        bodyFormData.append('passportNumber', this.state.passportNumber);
        bodyFormData.append('description', this.state.description);

        const response = await axios({
            method: "post",
            url: urlContactForm,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        });

        if (response && response.data === "") {
            this.setState({
                error: '',
                attachments: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                ticketType: '',
                numberOfToken: '',
                addressSupport: '',
                passportNumber: '',
                description: '',
                success: I18n.t('submitSuccessfully'),
            });
            this.clearMess();
        }
    }

    public render = () => {
        const { firstName, lastName, email, phone, numberOfToken, addressSupport, passportNumber, description, error, success } = this.state;
        return (
            <Wrapper>
                <Content>
                    <Des>
                        <H1>{I18n.t('customerForm')}<span style={{ color: '#186ab5' }}>{I18n.t('supportForm')}</span></H1>
                        <p>{I18n.t('emailUsAt')} <a style={{ color: '#186ab5' }}>{I18n.t('emailUsAtEmail')}</a></p>
                        <p style={{ color: '#4f4f4f' }}>{I18n.t('customerSupportDes')}</p>
                    </Des>
                    <FormSectionDiv>
                        <FormSectionDivL>
                            <P>{I18n.t('firstName')}</P>
                            <Input value={firstName} onChange={(e) => this.setState({ firstName: e.target.value })} />
                        </FormSectionDivL>
                        <FormSectionDivR>
                            <P>{I18n.t('lastName')}</P>
                            <Input value={lastName} onChange={(e) => this.setState({ lastName: e.target.value })} />
                        </FormSectionDivR>
                        <FormSectionDivL>
                            <P>{I18n.t('email*')}</P>
                            <Input type='email' value={email} onChange={(e) => this.setState({ email: e.target.value })} />
                        </FormSectionDivL>
                        <FormSectionDivR>
                            <P>{I18n.t('phone')}</P>
                            <Input type='number' value={phone} onChange={(e) => this.setState({ phone: e.target.value })} />
                        </FormSectionDivR>
                    </FormSectionDiv>
                    <div style={{ clear: 'both' }}></div>
                    <P>{I18n.t('homeAddress')}</P>
                    <Input value={addressSupport} onChange={(e) => this.setState({ addressSupport: e.target.value })} />
                    <FormSection1>
                        <FormSection1Div>
                            <P>{I18n.t('passportNumber')}</P>
                            <Input value={passportNumber} onChange={(e) => this.setState({ passportNumber: e.target.value })} />
                        </FormSection1Div>
                        <FormSection1Div>
                            <P>{I18n.t('idPicture')}</P>
                            <Input type="file" accept=".gif,.jpg,.png,.doc,.pdf,.csv,.txt" name="filename" id="myFile" onChange={(e) => this.setState({ attachments: e.target && e.target.files ? e.target.files[0] : '' })} />
                        </FormSection1Div>
                    </FormSection1>
                    <P>{I18n.t('detail')}</P>
                    <FormSection1>
                        <FormSection2Div>
                            <Radiobt type="radio" id="swapNep5" name="ticketType" value="Swap (NEP5)" onChange={(e) => this.setState({ ticketType: e.target.value })} />
                            <LabelP htmlFor="swapNep5">{I18n.t('swapNep5')}</LabelP>
                        </FormSection2Div>
                        <FormSection2Div>
                            <Radiobt type="radio" id="wwxRefund" name="ticketType" value="WWX Refund" onChange={(e) => this.setState({ ticketType: e.target.value })} />
                            <LabelP htmlFor="wwxRefund">{I18n.t('wwxRefund')}</LabelP>
                        </FormSection2Div>
                        <FormSection1Div>
                            <Pfloat>{I18n.t('numberOfToken')}</Pfloat>
                            <Input style={{ float: 'right', width: isMobile ? '100%' : '70%'}} value={numberOfToken} onChange={(e) => this.setState({ numberOfToken: e.target.value })} />
                        </FormSection1Div>
                    </FormSection1>

                    <div>
                        <Textarea placeholder={I18n.t('enterYourDetail')} value={description} rows={5} onChange={(e) => this.setState({ description: e.target.value })} >
                        </Textarea>
                    </div>
                    <Button onClick={() => this.onSubmit()}>{I18n.t('submitContact')}</Button>
                    {success ? (
                        <SuccessCard fontSize={FontSize.Large} text={success} />
                    ) : null}
                    {error ? (
                        <ErrorCard fontSize={FontSize.Large} text={error} icon={ErrorIcons.Warning} />
                    ) : null}
                </Content>
            </Wrapper >
        );
    };
}

const mapStateToProps = (state: StoreState): StateProps => {
    return {
        ethAccount: getEthAccount(state),
        web3State: getWeb3State(state),
        language: getLanguage(state)
    };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
    return {
    };
};

const ContactForm = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Contact);

export { ContactForm };
