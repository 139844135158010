import React from 'react';
import styled from 'styled-components';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Div = styled.div``
const H3 = styled.h3``
const P = styled.p``
const Wrap = styled.div``
const Answer = styled.div`
    background: #ddd9d9;
    padding: 10px;
    margin: 0;
    width: 100%
`
const Question = styled.span``

export const vi = <Wrap>
    <ExpansionPanel>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography className={'d'}>
                <Question>Chìa khóa của bạn, tiền điện tử của bạn
                 </Question>
            </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <Answer>
                <P>W-DEX will never have access to your private key. We understand that the concept of a private key is at the beating heart of crypto. But because these coins and tokens are decentralized, keeping private keys safe is nothing short of essential. Losing a PIN need not be a disaster because you can simply ask a bank for a new one, but misplacing a private key can mean that funds remain inaccessible forever.
                </P>
            </Answer>
        </ExpansionPanelDetails>
    </ExpansionPanel>

    <ExpansionPanel>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography className={'d'}>
                <Question>No Hidden Costs
                </Question>
            </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <Answer>
                <P>When you are trading on a decentralized exchange, it essentially means that you are trading peer-to-peer, allowing W-DEX to keep fees at a competitive level. W-DEX will continue to work very hard on creating awareness and promoting mass adoption on decentralized exchange technology, as we believe crypto trading should involve no hidden costs.
                </P>
            </Answer>
        </ExpansionPanelDetails>
    </ExpansionPanel>

    <ExpansionPanel>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography className={'d'}>
                <Question>Secure Trading
                </Question>
            </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <Answer>
                <P>W-DEX eliminates the security flaws that plague centralized exchanges, by allowing users to trade safely, peer-to-peer (P2P). There is no middleman and users are never required to relinquish control of their digital assets. Assets are in fact not kept on the exchange, so there is no longer a single point of failure. 
                </P>
            </Answer>
        </ExpansionPanelDetails>
    </ExpansionPanel>

    <ExpansionPanel>
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography className={'d'}>
                <Question>Industry Leading Decentralized Exchange
                </Question>
            </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <Answer>
                <P>The W-DEX decentralized exchange allows users to trade WWX token, USDT, and many ERC-20. With the primary intention to serve as an industry leading decentralized exchange, W-DEX aims to have an increasing variety of digital assets to be listed on W-DEX, allowing for a wider variety of trading pairs once W-DEX has implemented more blockchains leveraging on future cross-chain solutions. W-DEX is completely non-custodial, which means users will remain in control of their assets at all times. 
                </P>
            </Answer>
        </ExpansionPanelDetails>
    </ExpansionPanel>
</Wrap>
