/* eslint-disable */
import React from "react";
import './Pagination.css';
import styled, { withTheme } from 'styled-components';
import { Theme } from '../../../themes/commons';
interface State {
}

interface Props {
  getData: any;
  currentPage: any;
  limit: any;
  totalPages: any;
  theme: Theme;
}

const AColor = styled.a`color: ${props => props.theme.componentsTheme.aColor};`

class PaginationContainer extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentPage: this.props.currentPage || 1
    };
  }

  getData(page: number) {
    let { getData } = this.props;
    this.setState({ currentPage: page });
    getData(page);
  }

  fill(from: any, to: any) {
    var arr = [];
    for (var i = from; i <= to; i++) {
      arr.push(i)
    }
    return arr;
  }

  render() {
    //
    const { limit, totalPages } = this.props;

    const { currentPage } = this.props;

    // if (currentPage < currentPage) {
    //   currentPage = 1;
    // }

    //const skip = (currentPage - 1) * limit;

    const numPageShow = 2;

    var tmpArr1 = this.fill(1, currentPage);
    tmpArr1 = tmpArr1.slice(numPageShow * -1 - 1);
    var tmpArr2 = this.fill(currentPage + 1, totalPages).slice(0, numPageShow);
    const finalArr = tmpArr1.concat(tmpArr2)
    if (finalArr.length > 0 && finalArr[finalArr.length - 1] < totalPages) {
      if (finalArr[finalArr.length - 1] + 1 < totalPages) {
        finalArr.push('DOT')
      }
      finalArr.push(totalPages)
    }

    var items = finalArr.map((page, index) => {
      if (page === 'DOT') {
        return (<li key={`pagination_${page}`}><AColor href="javascript: void(0);">...</AColor></li>);
      }
      if (totalPages > 1) {
        return (
          <li key={`pagination_${page}`} onClick={() => this.getData(page)}>
            <AColor href="javascript: void(0);" className={currentPage === page ? "active" : ''}>{page}</AColor>
          </li>
        );
      }
    })

    let prevLink: any = '', nextLink: any = '', firstLink: any = '', lastLink: any = '';

    if (totalPages > 1) {
      prevLink = (<li key='pagination_prevLink'><AColor href="javascript: void(0);">Prev</AColor></li>);
      if (currentPage - 1 >= 1) {
        // prevLink = (<li key='pagination_prevLink' onClick={() => this.getData(currentPage - 1)}><AColor href="javascript: void(0);">Prev</AColor></li>);

        prevLink = (
          <ul className={"previous"}>
            <li key='pagination_prevLink' onClick={() => this.getData(currentPage - 1)}>
              <AColor href="javascript: void(0);">
                <span>{'<'}</span>
              </AColor>
            </li>
          </ul>
        );

        if (totalPages > numPageShow * 2 + 1) {
          // firstLink = (<li key='pagination_firstLink' onClick={() => this.getData(1)}><AColor href="javascript: void(0);">First</AColor></li>);
          firstLink = (
            <ul className={"previous"}>
              <li key='pagination_prevLink' onClick={() => this.getData(1)}>
                <AColor href="javascript: void(0);">
                  <span>First</span>
                </AColor>
              </li>
            </ul>
          );
        }
      }
      else {
        // cannot click
        prevLink = (
          <ul className={"previous"}>
            <li key='pagination_prevLink' onClick={() => { }}>
              <AColor href="javascript: void(0);">
                <span style={{ color: '#B2B6C3' }}>{'<'}</span>
              </AColor>
            </li>
          </ul>
        );
      }

      nextLink = (<li key='pagination_nextLink'><AColor href="javascript: void(0);">Next</AColor></li>);
      if (currentPage + 1 <= totalPages) {
        // nextLink = (<li key='pagination_nextLink' onClick={() => this.getData(currentPage + 1)}><AColor href="javascript: void(0);">Next</AColor></li>);

        nextLink = (
          <ul className={"next"}>
            <li key='pagination_nextLink' onClick={() => this.getData(currentPage + 1)}>
              <AColor href="javascript: void(0);">
                <span>{'>'}</span>
              </AColor>
            </li>
          </ul>
        );

        if (totalPages > numPageShow * 2 + 1) {
          // lastLink = (<li key='pagination_lastLink' onClick={() => this.getData(totalPages)}><AColor href="javascript: void(0);">Last</AColor></li>);
          lastLink = (
            <ul className={"next"}>
              <li key='pagination_nextLink' onClick={() => this.getData(totalPages)}>
                <AColor href="javascript: void(0);">
                  <span>Last</span>
                </AColor>
              </li>
            </ul>
          );
        }
      }
      else {
        // cannot click
        nextLink = (
          <ul className={"next"}>
            <li key='pagination_nextLink' onClick={() => { }}>
              <AColor href="javascript: void(0);">
                <span style={{ color: '#B2B6C3' }}>{'>'}</span>
              </AColor>
            </li>
          </ul>
        );
      }

    }

    //
    return (
      <div className="container">
        <nav className={"pagination"}>
          {firstLink}
          {prevLink}
          <ul className={"pages"}>
            {items}
          </ul>
          {nextLink}
          {lastLink}
        </nav>
      </div>
    );
  }
}
const Pagination = withTheme(PaginationContainer);

export { Pagination };
