import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div``
const Div = styled.div``
const Head = styled.h3``
const Bdy = styled.div``

export const en = <Wrap>
    <Div>
        <Head>What are cookies?</Head>
        <Bdy>
            Cookies are text files, containing small amounts of data, which are downloaded to your browser when you visit websites. Cookies can be recognized by the website that downloaded them and this helps websites know if the browsing device has visited them before.
</Bdy>
    </Div>
    <Div>
        <Head>Functional Cookies</Head>
        <Bdy>
            Functional cookies are essential to provide our services. They are used to remember your preferences on our website and to provide an enhanced and personalized experience. The information collected by these cookies is usually anonymized, so we cannot identify you personally. Functional cookies do not track your internet usage or gather the information that could be used for selling advertising, but they do help with providing advertising content. These cookies are usually session cookies which will expire when you close your browsing session.
</Bdy>
    </Div>
    <Div>
        <Head>Advertising Cookies</Head>
        <Bdy>
            Advertising cookies remember that you have visited a website and use that data to provide you advertising which is tailored to your interests. They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of the advertising campaign. The information collected by these cookies may be shared with trusted third-party partners such as advertisers.
</Bdy>
    </Div>
    <Div>
        <Head>Essential Cookies</Head>
        <Bdy>
            Essential cookies are essential to provide our services. Without these cookies, parts of our website will not function. These cookies do not track where you have been on the internet, do not remember preferences beyond your current visit and do not gather information about you that could be used for marketing purposes. These cookies are usually session cookies which will expire when you close your browsing session.

</Bdy>
    </Div>
    <Div>
        <Head>Analytical Performance Cookies</Head>
        <Bdy>
            Analytical performance cookies are used to monitor the performance of the website to determine the number of page views and the number of unique users a website has. Web analytics services may be designed and operated by third parties. The information provided by these cookies allows us to analyze patterns of user behavior and we use that information to enhance user experience or identify areas of the website which may require maintenance. The information is anonymous, cannot be used to identify you, does not contain personal information and is only used for statistical purposes.

</Bdy>
    </Div>
</Wrap>
