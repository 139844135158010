import React from 'react';
import { connect } from 'react-redux';
import { getLanguage } from '../../../store/selectors';
import { StoreState } from '../../../util/types';
import styled from 'styled-components';
import { isMobile } from "react-device-detect";
import { themeBreakPoints } from '../../../../src/themes/commons';

const I18n = require('i18n-js');
interface Memes {
    img: any,
    name: string,
    title: string,
    bio: string
}
interface Props {
    language: string
}

const Content = styled.div`
    margin: 50px 5%;
    color: ${props => props.theme.componentsTheme.textColorCommon};
`
const Head = styled.h1`
    text-align: center
`
const Intro = styled.h4`
    text-align: center
`
const Table = styled.table`
    width: 100%;
`
const Tr = styled.tr`
    vertical-align: top;
`
const Td = styled.td`
    width: ${isMobile ? '100%' : "50%"};
    display: ${isMobile ? 'block' : ""};

    @media (max-width: ${themeBreakPoints.lg}) {
        width: 100%;
        display: block;
    }
`
const MemW = styled.div`
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
`
const MemImage = styled.div`
`
const Image = styled.img`
    width: 150px;
    border-radius: 10px;
`
const MemDes = styled.div`
    padding: 0 10px;
`
const MemDesName = styled.h4`
    color: #3FD3B8;
    padding: 0;
    margin: 0;
    font-weight: bolder;
`
const MemDesTitle = styled.p`
    padding: 6px 0;
    margin: 0;
`
const MemDesBio = styled.div`
    padding: 10px 10px 0 0;
    border-top: 2px solid;
    height: 190px;
    overflow: auto;
    margin: 0 0 30px 0;
`


class _App extends React.Component<Props> {

    public render = () => {
        const memes = {
            ashish: { img: require('../../../assets/members/ashish.png'), name: I18n.t('ashishiDas'), title: I18n.t('projectDirector'), bio: I18n.t('ashishiDasBio') },
            seb: { img: require('../../../assets/members/seb.png'), name: I18n.t('seb'), title: I18n.t('bdm'), bio: I18n.t('sebBio') },
            hoang: { img: require('../../../assets/members/hoang.png'), name: I18n.t('hoangLe'), title: I18n.t('frontEnd'), bio: I18n.t('hoangLeBio') },
            luanDo: { img: require('../../../assets/members/luan_do.png'), name: I18n.t('luanDo'), title: I18n.t('techLead'), bio: I18n.t('luanDoBio') },
            luanPham: { img: require('../../../assets/members/luan_pham.png'), name: I18n.t('luanPham'), title: I18n.t('backend'), bio: I18n.t('luanPhamBio') },
            nam: { img: require('../../../assets/members/nam.png'), name: I18n.t('namNguyen'), title: I18n.t('qc'), bio: I18n.t('namNguyenBio') }
        }
        return (
            <Content>
                <Head>{I18n.t('devTeam')}</Head>
                <Intro>{I18n.t('devTeamIntroduce')}</Intro>
                <Table>
                    <Tr>
                        <Td>{Meme(memes.ashish)}</Td>
                        <Td>{Meme(memes.seb)}</Td>
                    </Tr>
                    <Tr>
                        <Td>{Meme(memes.luanDo)}</Td>
                        <Td>{Meme(memes.luanPham)}</Td>
                    </Tr>
                    <Tr>
                        <Td>{Meme(memes.nam)}</Td>
                        <Td>{Meme(memes.hoang)}</Td>
                    </Tr>
                </Table>
            </Content>

        );
    };
}

const mapStateToProps = (state: StoreState): Props => {
    return {
        language: getLanguage(state)
    };
};

const App = connect(
    mapStateToProps,
    null,
)(_App);
export default App;

const Meme = (data: Memes) => {
    return (
        <MemW>
            <MemImage><Image src={data.img} /></MemImage>
            <MemDes>
                <MemDesName>{data.name}</MemDesName>
                <MemDesTitle>{data.title}</MemDesTitle>
                <MemDesBio>{data.bio}</MemDesBio>
            </MemDes>
        </MemW>
    )
}