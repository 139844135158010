import { BigNumber } from '@0x/utils';

import { UI_DECIMALS_DISPLAYED_ORDER_SIZE } from '../common/constants';

export const padRightSplitted = (
    numBg: BigNumber,
    decimals: number = UI_DECIMALS_DISPLAYED_ORDER_SIZE,
): { num: string; diff: string } => {
    const numBgToFixed = numBg.toFixed(decimals);
    const numBgToString = numBg.toString();

    const decimalPlaces = (numBgToString.split('.')[1] || []).length;

    let diff = '';
    let num = numBgToFixed;
    if (!numBg.isZero() && decimalPlaces < decimals) {
        diff = numBgToFixed.replace(numBgToString, '');
        num = numBgToString;
    }

    return {
        num,
        diff,
    };
};

export const truncateAddress = (address: string) => {
    return `${address.slice(0, 7)}...${address.slice(address.length - 5)}`;
};

export const  fixBigNumber = (value: any): string =>{
    if (!value) { return '0' }
    if (Math.abs(value) < 1.0) {
        var e = parseInt(value.toString().split('e-')[1])
        if (e) {
            value *= Math.pow(10, e - 1)
            value = '0.' + (new Array(e)).join('0') + value.toString().substring(2)
        }
    } else {
        var e = parseInt(value.toString().split('+')[1])
        if (e > 20) {
            e -= 20
            value /= Math.pow(10, e)
            value += (new Array(e + 1)).join('0')
        }
    }
    return value;
}
