import React from 'react';
import { connect } from 'react-redux';

import { STEP_MODAL_DONE_STATUS_VISIBILITY_TIME } from '../../../common/constants';
import { getWeb3Wrapper } from '../../../services/web3_wrapper';
import { unlockCollectible } from '../../../store/blockchain/actions';
import { getEstimatedTxTimeMs, getStepsModalCurrentStep } from '../../../store/selectors';
import { stepsModalAdvanceStep } from '../../../store/ui/actions';
import { sleep } from '../../../util/sleep';
import { Collectible, StepUnlockCollectibles, StoreState } from '../../../util/types';
import { isMobile } from "react-device-detect";

import { BaseStepModal } from './base_step_modal';
import { StepItem } from './steps_progress';
const I18n = require('i18n-js');

interface OwnProps {
    buildStepsProgress: (currentStepItem: StepItem) => StepItem[];
}
interface StateProps {
    estimatedTxTimeMs: number;
    step: StepUnlockCollectibles;
}

interface DispatchProps {
    advanceStep: () => void;
    onUnlockCollectible: (collectible: Collectible) => Promise<string>;
}

type Props = OwnProps & StateProps & DispatchProps;

class UnlockCollectiblesStep extends React.Component<Props> {
    public render = () => {
        const { buildStepsProgress, estimatedTxTimeMs, step } = this.props;
        const { isUnlocked, collectible } = step;
        const collectibleName = collectible.name;
        const meta = isMobile ? 'Metamask' : '...';
        const title =  I18n.t ('sellingCollectibleName', {collectibleName});//`Selling ${collectibleName}`;
        const confirmCaption = isUnlocked? I18n.t('confirmMetamaskLock',{collectibleName}) : I18n.t('confirmMetamaskUnlock',{collectibleName}).replace(meta, 'dApp');
        // const confirmCaption = `Confirm on Metamask to ${
        //     isUnlocked ? 'lock' : 'unlock'
        // } ${collectibleName} for trading on 0x.`;
        const loadingCaption = isUnlocked? I18n.t('lockingTokenSymbol',{collectibleName}) : I18n.t('unlockingTokenSymbol',{collectibleName})
            // ? `Locking ${collectibleName}. You won't be able to use it for trading until you unlock it again`
            // : `Unlocking ${collectibleName}. It will remain unlocked for future trades`;
        const doneCaption = isUnlocked? I18n.t('lockedTokenSymbol',{collectibleName}) : I18n.t('unlockedTokenSymbol',{collectibleName})
            // ? `Locked ${collectibleName}. You won't be able to use it for trading until you unlock it again`
            // : `Unlocked ${collectibleName}. It will remain unlocked for future trades`;
        const errorCaption = isUnlocked? I18n.t('lockingTokenSymbolFailed',{collectibleName}) : I18n.t('unlockingTokenSymbolFailed',{collectibleName})
        //`${isUnlocked ? 'Locking' : 'Unlocking'} ${collectibleName} failed.`;
        const loadingFooterCaption = I18n.t('waitingForConfirmation');
        const doneFooterCaption = !isUnlocked ? I18n.t('tokenSymbolUnlocked',{collectibleName}) : I18n.t('tokenSymbolLocked',{collectibleName})
        //` ${collectibleName} Unlocked!` : ` ${collectibleName} Locked!`;

        return (
            <BaseStepModal
                step={step}
                title={title}
                confirmCaption={confirmCaption}
                loadingCaption={loadingCaption}
                doneCaption={doneCaption}
                errorCaption={errorCaption}
                loadingFooterCaption={loadingFooterCaption}
                doneFooterCaption={doneFooterCaption}
                buildStepsProgress={buildStepsProgress}
                estimatedTxTimeMs={estimatedTxTimeMs}
                runAction={this._unlockCollectibles}
                showPartialProgress={true}
            />
        );
    };

    private readonly _unlockCollectibles = async ({ onLoading, onDone, onError }: any) => {
        const { advanceStep, onUnlockCollectible, step } = this.props;

        try {
            const web3Wrapper = await getWeb3Wrapper();
            const txHash = await onUnlockCollectible(step.collectible);
            onLoading();
            await web3Wrapper.awaitTransactionSuccessAsync(txHash);
            onDone();
            await sleep(STEP_MODAL_DONE_STATUS_VISIBILITY_TIME);
            advanceStep();
        } catch (error) {
            onError(error);
        }
    };
}

const mapStateToProps = (state: StoreState): StateProps => {
    return {
        estimatedTxTimeMs: getEstimatedTxTimeMs(state),
        step: getStepsModalCurrentStep(state) as StepUnlockCollectibles,
    };
};

const UnlockCollectiblesStepContainer = connect(
    mapStateToProps,
    (dispatch: any) => {
        return {
            advanceStep: () => dispatch(stepsModalAdvanceStep()),
            onUnlockCollectible: (collectible: Collectible) => dispatch(unlockCollectible(collectible)),
        };
    },
)(UnlockCollectiblesStep);

export { UnlockCollectiblesStep, UnlockCollectiblesStepContainer };
