import React from 'react';
import styled from 'styled-components/macro';

import { themeBreakPoints, themeDimensions } from '../../../themes/commons';
import { Info } from '@material-ui/icons';

const I18n = require('i18n-js');
const About_01 = require('../../../assets/images/about_01.png');
const About_02 = require('../../../assets/images/about_02.png');
const About_03 = require('../../../assets/images/about_03.png');
const About_04 = require('../../../assets/images/about_04.png');

interface Props {
    prop?: string | null;
}

const Wrapper = styled.div`
    background-color: ${props => props.theme.componentsTheme.cardBackgroundColor};

`;
const Content = styled.div`
    text-align: center;
    color: ${props => props.theme.componentsTheme.textColorCommon};
    padding: 10px 5%;
    max-width: ${themeBreakPoints.xxl};
    margin: 0 auto;
`;
const H1 = styled.h1`
    font-size: 36px;
    margin: 0 0 64px 0;
    text-transform: uppercase;
    line-height: 49px;
    letter-spacing: 2px;
    font-weight: 300;
    color: ${props => props.theme.componentsTheme.cardTitleColor};
`;

const Text = styled.p`
    font-size: 14px;
    padding: 0 20px;
    margin: 0;
    color: #546497;
    @media (max-width: ${themeBreakPoints.lg}) {
        padding: 0 10px;

    }
`;
const BlueText = styled.span`
    color: #1068B4;
`;

const InfoSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 80px;
    @media (max-width: ${themeBreakPoints.md}) {
        flex-direction: column;
    }
`;

const InfoHeading = styled.h4`
    color: #88B4DA;
    font-weight: 700;
    text-align: left;
    margin-bottom: 16px;
`;
const FirstBlock = styled.div`
    margin-right: 32px;
    text-align: left;

    p {
        white-space: pre-wrap;
        padding: 0;
        color: #333333;
        margin-bottom: 16px;
    }
`;
const SecondBlock = styled.div`
    text-align: left;

    p {
        white-space: pre-wrap;
        padding: 0;
        color: #333333;
        margin-bottom: 16px;
    }
`;
export const AboutSection: React.FunctionComponent<Props> = (props) => {
    return (
        <>
            <Wrapper>
                <Content>
                    <H1><BlueText>{I18n.t('aboutWwxToken')}</BlueText></H1> 
                    {/*FIRST BLOCK CONTENT*/}
                    {/*{I18n.t('aboutW')}  {I18n.t('and')} <BlueText>{I18n.t('wowooExchange')}</BlueText> */}
                    <InfoSection>
                        <FirstBlock>
                            <img src={About_01}
                                 alt='about_01' />
                        </FirstBlock>
                        <SecondBlock>
                            <InfoHeading>{I18n.t('aboutHead1')}</InfoHeading>
                            <Text>
                                {I18n.t('aboutContentBlock1')}
                            </Text>
                            <Text>
                                {I18n.t('aboutContentBlock1_2')}
                            </Text>
                        </SecondBlock>
                    </InfoSection>

                    {/*BLOCK 2 */}
                    <InfoSection>
                        <FirstBlock>
                            <InfoHeading>{I18n.t('aboutHead2')}</InfoHeading>
                            <Text>
                                {I18n.t('aboutContentBlock2')}
                            </Text>
                        </FirstBlock>
                        <SecondBlock>
                            <img src={About_02}
                                 alt='about_02' />
                        </SecondBlock>
                    </InfoSection>

                    {/*BLOCK 3 */}
                    <InfoSection>
                        <FirstBlock>
                            <img src={About_03}
                                 alt='about_03' />
                        </FirstBlock>
                        <SecondBlock>
                            <InfoHeading>{I18n.t('aboutHead3')}</InfoHeading>
                            <Text>
                                {I18n.t('aboutContentBlock3')}
                            </Text>

                        </SecondBlock>
                    </InfoSection>


                    {/*BLOCK 4 */}
                    <InfoSection>
                        <FirstBlock>
                            <InfoHeading>{I18n.t('aboutHead4')}</InfoHeading>
                            <Text>
                                {I18n.t('aboutContentBlock4')}
                            </Text>
                        </FirstBlock>
                        <SecondBlock>
                            <img src={About_04}
                                 alt='about_04' />
                        </SecondBlock>
                    </InfoSection>

                    {/*BLOCK 5 */}
                    <InfoSection>
                        <FirstBlock>
                            {/* <Text>
                                {I18n.t('about5_1')}
                            </Text> */}
                            <InfoHeading>{I18n.t('about5_1')}</InfoHeading>
                            <Text>
                                {I18n.t('about5_2')}
                            </Text>
                            <Text dangerouslySetInnerHTML={{ __html: I18n.t('about5_3') }}>
                            </Text>
                            <Text>
                                {I18n.t('about5_4')}
                            </Text>
                            <Text>
                                {I18n.t('about5_5')}
                            </Text>
                            <Text dangerouslySetInnerHTML={{ __html: I18n.t('about5_6') }}>
                            </Text>
                            <Text>
                                {I18n.t('about5_7')}
                            </Text>
                            <Text dangerouslySetInnerHTML={{ __html: I18n.t('about5_8') }}>
                            </Text>
                            <Text>
                                {I18n.t('about5_9')}
                            </Text>
                        </FirstBlock>
                    </InfoSection>
                </Content>
            </Wrapper>
        </>
    );
};

// AboutSection.defaultProps = {
//     name: null,
// };
