import React from 'react';
import { connect } from 'react-redux';
import { getLanguage } from '../../../store/selectors';
import { StoreState } from '../../../util/types';
import styled from 'styled-components';
const I18n = require('i18n-js');

interface Props {
    language: string
}
const Div = styled.div`
    text-align: center;
    font-family: sans-serif;
    display: flex;
    justify-content: center;
    height: 100%;
    flex: 1;
    width: 100%;
    align-content: center;
    flex-direction: column;
    min-height: 500px;
    color: ${props => props.theme.componentsTheme.textColorCommon};
`

const Head = styled.h1`
`

class _App extends React.Component<Props> {

    public render = () => {

        return (
            <Div>
                <Head>{I18n.t('comingSoon')}</Head>
            </Div>
        );
    };
}

const mapStateToProps = (state: StoreState): Props => {
    return {
        language: getLanguage(state)
    };
};

const App = connect(
    mapStateToProps,
    null,
)(_App);
export default App;
