import React from 'react';
import styled from 'styled-components';

const Faqs = styled.div`
`
const Question = styled.h3`
`
const Answer = styled.div`
`
const P = styled.p``

export const vi = <Faqs>
    <Question>Cấu trúc phí là gì?</Question>
    <Answer>
        <P>Phí giao dịch được thanh toán bằng Gas trên blockchain Ethereum và tùy thuộc vào mạng hoặc mức tốc độ bạn chọn, phí có thể khác nhau. Trung bình các khoản phí nên vào khoảng 0,001 USD hoặc 0,0005ETH mỗi giao dịch.<br /></P>
        <P>Sau đó, một số phí giao dịch có thể được thêm vào tùy thuộc vào nền tảng 0x. (Ví dụ: 0,10% cho nhà sản xuất và người nhận)<br /></P>
        <P>Phí nhà sản xuất được thanh toán khi bạn thêm thanh khoản vào sổ đặt hàng của chúng tôi bằng cách đặt lệnh giới hạn dưới giá thị trường để mua và cao hơn giá thị trường để bán.<br /></P>
        <P>Phí Taker được thanh toán khi bạn xóa thanh khoản khỏi sổ đặt hàng của chúng tôi bằng cách đặt bất kỳ đơn hàng nào được thực hiện theo đơn đặt hàng trên sổ đặt hàng.<br /></P>
    </Answer>
</Faqs>
