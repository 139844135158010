import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { getLanguage, getThemeUI } from '../../../store/selectors';
import { StoreState } from '../../../util/types';
import { setLanguage, setThemeUIAction } from '../../../store/actions';
import LanguageService from '../../../i18n';
import { Dropdown, DropdownPositions } from '../../common/dropdown';
import { CardBase } from '../../common/card_base';
import { ChevronDownIcon } from '../../common/icons/chevron_down_icon';

const moment = require('moment');
const day = require('../../../assets/images/day.svg');
const night = require('../../../assets/images/night.svg');
const configs = require('../../../config.json');
const I18n = require('i18n-js');
const flags: any = {
    en: require('../../../assets/flags/en.png'),
    ja: require('../../../assets/flags/ja.png'),
    vi: require('../../../assets/flags/vi.png'),
};

interface PropsAction {
    setThemeUI: (theme: string) => any,
    setLanguages: (lang: string) => any,
}

interface Props {
    theme: string;
    language: string
}

const Content = styled.div`
    padding: 0;
`;

const News = styled.div`
    text-align: center;
    margin: 0 auto;
    color: ${props => props.theme.componentsTheme.myWalletLinkColor};
    display: flex;
    justify-content: center;
`;
const Themes = styled.div`
    display: flex;
    justify-content: space-between;
    margin:0 0 0 10px;
`;

const Langs = styled.div`
`;
const ULT = styled.ul`
    display: inline-flex;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid rgb(133, 142, 157);
`;
const LIT = styled.li <{ active?: boolean }>`
    padding: 5px 10px;
    cursor: pointer;
    background: ${props => props.active === true ? '#fff' : 'transparent'}
`;
const ULL = styled.ul`
    cursor: pointer;
`;
const LIL = styled.li <{ active?: boolean }>`
    cursor: pointer;
    padding: 8px 10px;
    font-size: 14px;
    min-width: 125px;
    text-align: left;
`;
const Select = styled.div`
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    padding: 6px;
    cursor: pointer;
    min-width: 125px;
    text-align: left;
    position: relative;
    color: #999999;
`;
const Options = styled(CardBase)``;

const Img = styled.img`
    margin: 0 5px 0 0;
`;
const Tx = styled.span`
    position: relative;
    top: 3px;
    color: ${props => props.theme.componentsTheme.textColorCommon};
`;

const TxSelected = styled.span`
    position: relative;
    top: 3px;
    color: #FFFFFF;
`;

const ChevronDownIconW = styled.span`
    position: absolute;
    right: 4px;
`;
const Copy = styled.p`
    margin: 0;
    font-size: 14px;
    color: #FFFFFF;
    padding: 20px 0;
    text-align: center;
`;

interface Lang {
    key: string;
    name: string;
}

interface Theme {
    key: string;
    name: string;
}

class Dashboard extends React.Component<Props & PropsAction> {

    public oncChangeTheme = (theme: string) => {
        // const theme = event.target.value
        this.props.setThemeUI(theme);
        scrollTop();
    };

    public onLanguage = (lang: string) => {
        LanguageService.setLanguage(lang);
        this.props.setLanguages(lang);
        moment.locale(lang);
        scrollTop();
    };

    public getSupportLangs = (): Lang[] => {
        let langs = [];
        const languages = configs.languages;
        for (const key in languages) {
            langs.push(languages[key]);
        }
        return langs;
    };

    public getSupportThemes = (): Theme[] => {
        let themes = [];
        const theme = configs.themes;
        for (const key in theme) {
            themes.push(theme[key]);
        }
        return themes;
    };
    public render = () => {
        const { theme, language } = this.props;
        const lang = LanguageService.getLanguage() || language;
        const langs: Lang[] = this.getSupportLangs();
        const languages = configs.languages;
        const body = <Options>
            <ULL>
                {langs.map((row, index) => {
                    return <LIL onClick={() => this.onLanguage(row.key)} key={index}>
                        <Img alt="" width={20} src={flags[row.key]} />
                        <Tx>{row.name}</Tx>
                    </LIL>;
                })}
            </ULL>
        </Options>;
        const header = <Select>
            <Img alt="" width={20} src={flags[lang]} />
            <TxSelected>{languages[lang].name}</TxSelected>
            <ChevronDownIconW><ChevronDownIcon /></ChevronDownIconW>
        </Select>;
        return (
            <Content>
                <News>
                    <Langs>
                        <Dropdown
                            body={body}
                            header={header}
                            horizontalPosition={DropdownPositions.Bottom}
                            shouldCloseDropdownOnClickOutside={true}
                            shouldCloseDropdownOnClickInside={true}
                        />
                    </Langs>
                    {/* <Themes>
                        <ULT>
                            <LIT title={I18n.t('lightMode')} active={theme === LIGHT_THEME} onClick={() => this.oncChangeTheme(LIGHT_THEME)}><img alt="" src={day} /></LIT>
                            <LIT title={I18n.t('darkMode')} active={theme === DARK_THEME} onClick={() => this.oncChangeTheme(DARK_THEME)}><img alt="" src={night} /></LIT>
                        </ULT>
                    </Themes> */}
                </News>
                <Copy>{I18n.t('copyright')}</Copy>
            </Content>
        );
    };
}

const mapStateToProps = (state: StoreState): Props => {
    return {
        theme: getThemeUI(state),
        language: getLanguage(state),
    };
};

const mapDispatchToProps = (dispatch: any): PropsAction => {
    return {
        setThemeUI: (theme: string) => dispatch(setThemeUIAction(theme)),
        setLanguages: (lang: string) => dispatch(setLanguage(lang)),
    };
};

const DashboardApp = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Dashboard);
export default DashboardApp;

const scrollTop = () => {
    window.scrollTo(0, 0);
};
