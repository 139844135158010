import React from 'react';
import { connect } from 'react-redux';
import { BigNumber } from '@0x/utils';
import styled from 'styled-components';
import { getEthAccount, getMarkets, getVolume, getWeb3State } from '../../../store/selectors';
import { CurrencyPair, Filter, Market, StoreState, Volume } from '../../../util/types';
import { changeMarket, get24hVolumeAction } from '../../../store/actions';
import { TokenIcon } from '../../common/icons/token_icon';
import { getKnownTokens } from '../../../util/known_tokens';
import { marketFilters } from '../../../common/markets';
import { tokenAmountInUnits } from '../../../util/tokens';
import { Welcome } from '../common/welcome';
import { BannerOne, BannerTwo } from '../common/banners';

import { GetStated } from '../common/get_started';
import { SeviceEveryone } from '../common/service_everyone';
import { MarketsIndex } from '../common/markets_index';
import { Error, Loading, Locked, NETWORK_ID, NETWORK_NAME, NotInstalled } from '../../../common/constants';
import { isMobile } from 'react-device-detect';
import { AboutSection } from '../common/about';
import { ContactForm } from '../common/contact_form';

const I18n = require('i18n-js');

interface PropsAction {
    changeMarket: (currencyPair: CurrencyPair) => any;
    get24hVolume: () => any,
}

interface Props {
    markets: Market[] | null;
    volume: Volume | null,
    ethAccount: string;
    web3State: string;
}

interface State {
    selectedFilter: Filter;
    search: string;
}

class Dashboard extends React.Component<Props & PropsAction, State> {

    constructor(props: Props & PropsAction) {
        super(props);
        const { get24hVolume } = props;
        get24hVolume();
        this.renderTokenList = this.renderTokenList.bind(this);
    }

    public readonly state: State = {
        selectedFilter: marketFilters[0],
        search: '',
    };

    public goMarket(currencyPair: CurrencyPair) {
        this.props.changeMarket(currencyPair);
    }

    public renderSearch = () => {
        return (
            <p></p>
        );
    };

    public renderRowTokenList = (market: any, index: number) => {
        const { currencyPair, price } = market;
        let { volume } = this.props;
        if (currencyPair) {
            const { base, quote } = currencyPair;
            const key = base.toLocaleLowerCase() + '_' + quote.toLocaleLowerCase();
            const data = volume ? volume[key] : null;
            const change24h = data && data.change24h.toString() ? data.change24h.toFixed(2).toString() : '0';
            const token = getKnownTokens().getTokenBySymbol(base);
            const decimals = token ? token.decimals : 18;
            const displayDecimals = token ? token.displayDecimals : 2;
            const _volume = data && data.volume ? data.volume : 0;
            const amount = tokenAmountInUnits(new BigNumber(_volume), decimals, displayDecimals);
            const endPrice = data && data.endPrice ? data.endPrice : price;
            return (
                <TR key={index.toString()} onClick={() => this.goMarket(currencyPair)}>
                    <TDPairs>
                        <TokenIcon
                            symbol={token.symbol}
                            primaryColor={token.primaryColor}
                            icon={token.icon}
                        />
                        <Span>{base}/{quote}</Span>
                    </TDPairs>
                    <TD>{endPrice ? +endPrice.toString() : '-'}</TD>
                    <TD><Sp value={+change24h}>{endPrice ? change24h + '%' : '-'} </Sp></TD>
                    <TD>{endPrice ? +amount : '-'}</TD>
                </TR>
            );
        }
    };

    public renderTokenList = () => {

        let { markets } = this.props;

        return (
            <MarketBody>
                {!markets && <Loadings>...</Loadings>}
                {markets && <Div>
                    <DivChart>
                        {/* {this.renderChart()} */}
                        {/* {this.renderBidAskChart()} */}
                    </DivChart>
                    <Table>
                        <THead>
                            <TRHead>
                                <THeadPair>{I18n.t('market')}</THeadPair>
                                <TH>{I18n.t('price')}</TH>
                                <TH>{I18n.t('change24hr')}</TH>
                                <TH>{I18n.t('volume24hr')}</TH>
                            </TRHead>
                        </THead>
                        <TBody>
                            {markets.map((market: any, index: number) => this.renderRowTokenList(market, index))}
                        </TBody>
                    </Table>
                </Div>}
            </MarketBody>
        );
    };

    public render = () => {
        const { ethAccount, web3State } = this.props;
        const { ethereum } = window;
        let networkVersion = NETWORK_ID;
        if (ethereum && ethereum.networkVersion) {
            networkVersion = ethereum.networkVersion;
        }
        let bt: string = '...';
        if (web3State === NotInstalled) {
            bt = I18n.t('getChromeExtension');
        } else if (web3State === Locked || (ethereum && !ethereum.selectedAddress)) {
            bt = I18n.t('connectYourWallet');
        } else if (+networkVersion !== +NETWORK_ID) {
            bt = I18n.t('wrongNetWork', { NETWORK_NAME });
        } else if (web3State === Error || web3State === Loading) {
            bt = I18n.t('waitLoadWallet');
        } else if (ethAccount) {
            bt = '';//I18n.t('connected');
        }
        return (
            <Content>
                <Welcome />
                {/*<BannerOne />*/}
                <AboutSection />
                <ContactForm />
                {bt !== '' && (isMobile ? '' : <GetStated />)}
                {bt === '' && (isMobile ? '' : <GetStated />)}
                <MarketsIndex />
                <SeviceEveryone />
                {/*<BannerTwo />*/}
            </Content>
        );
    };
}

const mapStateToProps = (state: StoreState): Props => {
    return {
        markets: getMarkets(state),
        volume: getVolume(state),
        ethAccount: getEthAccount(state),
        web3State: getWeb3State(state),
    };
};

const mapDispatchToProps = (dispatch: any): PropsAction => {
    return {
        changeMarket: (currencyPair: CurrencyPair) => dispatch(changeMarket(currencyPair)),
        get24hVolume: () => dispatch(get24hVolumeAction()),
    };
};

const DashboardApp = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Dashboard);
export default DashboardApp;


const Content = styled.div``;

const Loadings = styled.div`
    text-align: center;
    padding: 300px 0;
`;
const MarketBody = styled.div`
    margin: 100px auto;
    background-color: ${props => props.theme.componentsTheme.background};
    border-radius: 4px;
    border: 1px solid ${props => props.theme.componentsTheme.cardBorderColor};
`;

const Table = styled.table`
    width: 100%;
    color: ${props => props.theme.componentsTheme.myWalletLinkColor};
`;

const THead = styled.thead`
    
`;

const TBody = styled.tbody`
    text-align: center;
`;

const TR = styled.tr`
    :hover {
        background-color: rgba(170, 170, 170, 0.13);
    }
`;

const TRHead = styled.tr`
    color: ${props => props.theme.componentsTheme.myWalletLinkColor};
`;

const TH = styled.th`
    padding: 10px;
`;

const THeadPair = styled(TH)`
    text-align: left;
`;

const TD = styled.td`
    padding: 10px;
    cursor: pointer;
    color: ${props => props.theme.componentsTheme.myWalletLinkColor};
`;

const TDPairs = styled.td`
    padding: 10px;
    cursor: pointer;
    text-align: left;
    text-transform: uppercase;
    display: flex;
    color: ${props => props.theme.componentsTheme.myWalletLinkColor};
`;

const Span = styled.span`
    display: inline-block;
    margin: 5px 0 0 15px;
`;

const Div = styled.div`

`;
const DivChart = styled.div`
    display: flex;
    padding: 10px;
`;
const Sp = styled.span <{ value: number }>`
    color: ${props => props.value >= 0 ? props.theme.componentsTheme.green : props.theme.componentsTheme.red};
`;
