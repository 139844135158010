import React, { HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import { themeBreakPoints } from '../../themes/commons';
import { ERC20_APP_BASE_PATH } from '../../common/constants';
import Setting from '../../components/erc20/common/settings_content_footer';
import { isMobileOnly } from 'react-device-detect';

const Bgg = require('../../assets/images/bg.svg');
const I18n = require('i18n-js');
const config = require('../../config.json');
const COMMINGSOON = `#${ERC20_APP_BASE_PATH}/comming-soon`;
const Term = `#${ERC20_APP_BASE_PATH}/terms`;
const Features = `#${ERC20_APP_BASE_PATH}/features`;
const Policy = `#${ERC20_APP_BASE_PATH}/policy`;
const Cookies = `#${ERC20_APP_BASE_PATH}/cookies`;
const ListAssets = `#${ERC20_APP_BASE_PATH}/list-assets`;
const Tutorial = `#${ERC20_APP_BASE_PATH}/tutorial`;
const Faq = `#${ERC20_APP_BASE_PATH}/faq`;
const Fees = `#${ERC20_APP_BASE_PATH}/fees`;
const MarketsPage = `#${ERC20_APP_BASE_PATH}/market?base=zrx&quote=weth`;
const EARN = `#${ERC20_APP_BASE_PATH}/earn`;

interface Props extends HTMLAttributes<HTMLDivElement> {
}

const FooterWrapper = styled.div`
    /* background: ${props => props.theme.componentsTheme.cardBackgroundColor} url(${Bgg}) no-repeat center;
    background: #000;
    color: #fff; */
    margin:  0;
    color: ${props => props.theme.componentsTheme.textColorCommon};
    background: ${props => props.theme.componentsTheme.footerBackgroundColor};

`;

const Max = styled.div`
    margin: 0 auto;
    max-width: ${themeBreakPoints.xxl}
`;

const Ul = styled.ul`
    list-style: none;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    color: ${props => props.theme.componentsTheme.textColorCommon};
    margin: 0;
    padding: 0;
    @media (max-width: ${themeBreakPoints.sm}) {
        display: block;
    }
    padding: 20px 5% 0;
`;
const Ull = styled(Ul)`
    @media (max-width: ${themeBreakPoints.sm}) {
        display: flex;
    }
`;
const Li = styled.li`
    list-style: none;
    padding: 0;
`;
const Social = styled.div``;
const ListSocial = styled.div`
    max-width: 200px;
`;

const A = styled.a`
    margin: 10px 20px 10px 0;
    display: inline-block;
`;
// const About = styled.div`
//     display: flex;
//     margin: 0;
// `
const Dic = styled.div`
    color: #ffffff;
`;
const He = styled.h3`
    margin: 10px 0;
`;
const Link = styled.a`
    display: block;
    padding: 2px 0;
`;
const Img = styled.img`
    width: 30px;
    height: 30px;
`;
const ImgStore = styled.img`
`;

const P = styled.p`
    text-align: center;
    padding: 50px 5%;
`;
export const Footer: React.FC<Props> = props => {
    const { Telegram, Linkedin, Twitter, Facebook, Instagram, Reddit, Line, GooglePlay, AppleStore } = config.socialNetwork;
    const social = (
        <Social>
            {/* <Dex>DEX</Dex> */}
            <ListSocial>
                {/*{Facebook && <A href={Facebook ? Facebook : '#'} target="_blank">*/}
                {/*    <Img alt="Facebook" src={require('../../assets/images/facebook-light.svg')} />*/}
                {/*</A>}*/}
                {/*{Twitter && <A href={Twitter ? Twitter : '#'} target="_blank">*/}
                {/*    <Img alt="Twitter" src={require('../../assets/images/twitter-light.svg')} />*/}
                {/*</A>}*/}
                {Telegram && <A href={Telegram ? Telegram : '#'} target="_blank">
                    <Img alt="Telegram" src={require('../../assets/images/telegram-light.svg')} />
                </A>}
                {/*{Linkedin && <A href={Linkedin ? Linkedin : '#'} target="_blank">*/}
                {/*    <Img alt="Linkedin" src={require('../../assets/images/linkedin-light.svg')} />*/}
                {/*</A>}*/}
                {/*{Instagram && <A href={Instagram ? Instagram : '#'} target="_blank">*/}
                {/*    <Img alt="Instagram" src={require('../../assets/images/instagram-light.svg')} />*/}
                {/*</A>}*/}
                {/*{Line && <A href={Line ? Line : '#'} target="_blank">*/}
                {/*    <Img alt="Line" src={require('../../assets/images/discord-light.svg')} />*/}
                {/*</A>}*/}
                {/*{Reddit && <A href={Reddit ? Reddit : '#'} target="_blank">*/}
                {/*    <Img alt="Reddit" src={require('../../assets/images/discord-light.svg')} />*/}
                {/*</A>}*/}
            </ListSocial>
        </Social>
    );
    const Exchage = (
        <Dic>
            <He>{I18n.t('exchange')}</He>
            {/* <Link href={Features} onClick={scrollTop}>
                {I18n.t('features')}
            </Link> */}
            <Link href={MarketsPage} onClick={scrollTop}>
                {I18n.t('markets')}
            </Link>
            {/* <Link href={EARN} onClick={scrollTop}>
                {I18n.t('earn')}
            </Link> */}
            {/* <Link href='https://0xtracker.com' target="_blank">
                0xTracker
            </Link> */}
            <Link href={ListAssets} onClick={scrollTop}>
                {I18n.t('listedAssets')}
            </Link>
        </Dic>
    );
    const wallet = (
        <Dic>
            <He>{I18n.t('DEXWallet')}</He>
            {GooglePlay && <Link href={GooglePlay ? GooglePlay : '#'} target="_blank">
                <ImgStore alt="AppStore" src={require('../../assets/images/GooglePlay.png')} />
            </Link>}
            {AppleStore && <Link href={AppleStore ? AppleStore : '#'} target="_blank">
                <ImgStore alt="AppleStore" src={require('../../assets/images/AppStore.png')} />
            </Link>}
        </Dic>
    );
    const developers = (
        <Dic>
            <He>{I18n.t('developers')}</He>
            {/* <Link href='https://gitlab.blockchainlabs.asia/wwx/wwx-dex-frontend' target="_blank">
                {I18n.t('github')}
            </Link> */}
            <Link href='https://developers.ondex.io/' target="_blank">
                {I18n.t('API')}
            </Link>
            {/* <Link href={COMMINGSOON} target="_blank">
                {I18n.t('documentations')}
            </Link> */}
        </Dic>
    );
    const legal = (
        <Dic>
            <He>{I18n.t('legal')}</He>
            <Link href={Policy} onClick={scrollTop}>
                {I18n.t('privacyPolicy')}
            </Link>
            <Link href={Cookies} onClick={scrollTop}>
                {I18n.t('cookiesPolicy')}
            </Link>
            <Link href={Term} onClick={scrollTop}>
                {I18n.t('termConditions')}
            </Link>
            {/* <Link href={COMMINGSOON} onClick={scrollTop}>
                {I18n.t('userAgreement')}
            </Link> */}
            {/* <Link href={COMMINGSOON} onClick={scrollTop}>
                {I18n.t('countryLicense')}
            </Link> */}
            {/* <Link href={COMMINGSOON} onClick={scrollTop}>
                {I18n.t('eSignConsent')}
            </Link> */}
        </Dic>
    );
    const about = (
        <Dic>
            <He>{I18n.t('about')}</He>
            <Link onClick={scrollTop} href={`#${ERC20_APP_BASE_PATH}/dev-team`}>
                {I18n.t('meetTeam')}
            </Link>
            <Link onClick={scrollTop} href={`#${ERC20_APP_BASE_PATH}/contact-us`}>
                {I18n.t('contactUs')}
            </Link>
            <Link href={COMMINGSOON} target="_blank" onClick={scrollTop}>
                {I18n.t('disclosures')}
            </Link>
            <Link href={COMMINGSOON} target="_blank" onClick={scrollTop}>
                {I18n.t('tradingRules')}
            </Link>
        </Dic>
    );
    const support = (
        <Dic>
            <He>{I18n.t('support')}</He>
            {/* {Links(COMMINGSOON, I18n.t('media'))}
            {Links(COMMINGSOON, I18n.t('customerService'))} */}
            {Links(Fees, I18n.t('fees'))}
            {Links(Faq, I18n.t('faq'))}
            {Links(Tutorial, I18n.t('tutorials'))}
        </Dic>
    );
    const network = (
        <Dic>
            {/* <He> &nbsp; </He> */}
            {Telegram && <A href={Telegram ? Telegram : '#'} target="_blank">
                <Img alt="Telegram" src={require('../../assets/images/Telegram.png')} />
            </A>}
            <Setting />
        </Dic>
    );
    const show = (isMobileOnly && window.location.href.indexOf('/market?') > 0) ? false : true;
    return (
        <div>
            {show && <FooterWrapper>
                <Max>
                    {<Ul>
                        <Li>{Exchage}</Li>
                        {/*<Li>{wallet}</Li>*/}
                        {!isMobileOnly && <Li>{developers}</Li>}
                        <Li>{legal}</Li>
                        <Li>{support}</Li>
                        {/* <Li>{network}</Li> */}
                    </Ul>}
                    <Ull>
                        <Li>{social}</Li>
                        <Li><Setting /></Li>
                        <Li>&nbsp;</Li>
                        {/*<Li><SubscribeOurNews/></Li>*/}
                    </Ull>
                </Max>
            </FooterWrapper>}
        </div>
    );
};

const Links = (href: string, text: string, target: string = '') => {
    return (
        <Link href={href} target={target} onClick={scrollTop}>
            {text}
        </Link>
    );
};
const scrollTop = () => {
    // window.scrollTo(0, 0);
};
