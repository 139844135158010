import React from 'react';
/* # eslint-disable */

export const PolicyPage = <div class="Policy">
    <h3 class="c13" id="h.gjdgxs"><span class="c1">Privacy Policy</span></h3>
    <p class="c10"><span class="c4 c0">ONDEX collects information about you from various sources to provide our Site to you, to analyze
    and improve our Site, and to communicate with you. This Privacy Policy (the &ldquo;Privacy Policy&rdquo;)
    covers our treatment of the personally identifiable information (&ldquo;Personal Information&rdquo;) that we
        collect when you access or use our services, currently offered at ONDEX (the &ldquo;Site&rdquo;).</span></p>
    <p class="c10"><span class="c4 c0">We reserve the right to revise, amend or modify this Policy in order to reflect
    changes in law, our practices in the collection and use of personal data, the features on the Site or
        advances in technology at any time and in any manner.</span></p>
    <p class="c10"><span class="c4 c0">This Privacy Policy does not cover the practices of entities we don&rsquo;t own
        or control, or people we don&rsquo;t manage.</span></p>
    <p class="c10"><span class="c4 c0">By using our site, you consent to our Site Privacy Policy herein.</span></p>
    <h2 class="c13" id="h.30j0zll"><span class="c4 c9">1. Who we are</span></h2>
    <p class="c10"><span class="c4 c0">Our Privacy Policy applies to our Site and all services we provide, but excludes
    any applications or services of products that have separate privacy policies that do not incorporate this
    Privacy Policy. For the purposes of data protection laws of Singapore, ONDEX is a data controller (i.e., the
        company who is responsible for, and controls the processing of, your personal data).</span></p>
    <h2 class="c13" id="h.1fob9te"><span class="c4 c9">2. Information we collect and for what purpose</span></h2>
    <ul class="c8 lst-kix_list_7-0 start">
        <li class="c19"><span class="c0">We collect different types of Personal Information from our users and visitors
        to the Site and use this Personal Information internally for a variety of purposes, including, but not
        limited to, business reasons, marketing, site maintenance and statistics, and research and product
            development.</span></li>
        <li class="c20"><span class="c0 c12">Information Automatically Collected</span><span class="c0">&nbsp;&ndash;
        When using our Services, we may also automatically collect other technical information about your use
        and interaction with our Site, which may be collected through cookies, web beacons and other similar
        technology as described in Section 5 (how we use cookies and other tracking technology to collect
        information). We may collect and review information regarding blockchain analytics related to blockchain
            addresses you provide and your interaction with the Ethereum network.</span></li>
        <li class="c18 c26"><span class="c0 c12">Do Not Track</span><span class="c0">&nbsp;&ndash; our Site does not
            respond to Do Not Track signals communicated by your browser.</span></li>
    </ul>
    <h2 class="c13" id="h.3znysh7"><span class="c4 c9">3. How we collect information from you</span></h2>
    <p class="c23" id="h.2et92p0"><span class="c4 c0">To the extent permitted by applicable Data Protection Laws, we
        collect information about you and any other party whose details you provide us with when you: </span></p>
    <ul class="c8 lst-kix_list_6-0 start">
        <li class="c3"><span class="c4 c0">Visit or use our Site;</span></li>
    </ul>
    <ul class="c8 lst-kix_list_9-0 start">
        <li class="c5"><span class="c0">Create an account with our Site;</span></li>
        <li class="c5"><span class="c0">Complete online forms (including call-back requests), participate in surveys,
        post to any forum, download information such as white papers or other publications, or participate in
            any other interactive areas that appear on our Site; </span></li>
        <li class="c5"><span class="c0">Interact with us using social media;</span></li>
        <li class="c5"><span class="c0">Provide us with your contact details when registering to use or access any of
            the services we provide or when you updating those details; and</span></li>
        <li class="c18 c17"><span class="c0">Contact or connect with us either online or offline.</span></li>
    </ul>
    <p class="c10"><span class="c4 c0">We will also collect your information where you only partially complete and/or
    discontinue any information entered on our website and/or other online forms, and may use this information
        to contact you to remind you to complete any outstanding information and/or for marketing. </span></p>
    <p class="c10"><span class="c4 c0">We may also collect information from your devices (including mobile devices) and
    applications used to access and use our Site by you or your users (for example, your device identification
    number and type, location information and connection information such as statistics on your page views,
    traffic to and from the sites, referral URL, ad data, Internet Protocol (IP) address, &nbsp;browsing history
    and web log information). &nbsp;Before we do that, we will ask for your permission. We may use cookies or
        similar technologies to do so (as described in Section 5 below).</span></p>
    <p class="c10"><span class="c4 c0">You are responsible for ensuring that you comply with the applicable data
    protection laws and consent obligations if you intend to give us Personal Information about someone else.
    &nbsp;You must ensure that you have their explicit consent to do so in advance and explain to them how we
    collect, use, disclose and retain their Personal Information or direct them to read our Privacy Policy as
        per required by applicable Data Protection Laws.</span></p>
    <h2 class="c13" id="h.tyjcwt"><span class="c4 c9">4. How we use your information</span></h2>
    <p class="c10"><span class="c4 c0">To the extent permitted by applicable Data Protection Laws, we use your
        information to:</span></p>
    <ul class="c8 lst-kix_list_8-0 start">
        <li class="c3"><span class="c0">Give any information or services that you have requested or ordered;</span></li>
        <li class="c5"><span class="c0">Compare information and verify it with third parties for accuracy;</span></li>
        <li class="c5"><span class="c0">Protect, maintain, provide and improve our Site;</span></li>
        <li class="c5"><span class="c0">Monitor, manage and administer your use of the Site and provide an enhanced,
            personal, user experience;</span></li>
        <li class="c5"><span class="c0">Manage our relationship with you (e.g., customer services and technical
            support);</span></li>
        <li class="c5"><span class="c0">Testing our Site or systems internally to test and improve their safety and
        performance (in these circumstances we would de-identify any information used for such testing purposes
            ;</span></li>
        <li class="c5"><span class="c0">Comply with applicable Know Your Customer and Anti-Money Laundering
            regulations;</span></li>
        <li class="c5"><span class="c0">Provide you with any information that we are required to send you to comply with
            our regulatory or legal obligations;</span></li>
        <li class="c5"><span class="c0">Prevent, Detect, investigate resolve criminal, illegal or prohibited activities
        or otherwise protect our legal rights (including liaison for such purposes with regulators and law
            enforcement agencies);</span></li>
        <li class="c5"><span class="c0">Contact you to see if you would like to participate in our customer research
            (e.g. feedback about how you use our applications, products and services);</span></li>
        <li class="c5"><span class="c0">Perform, monitor statistical analysis and benchmarking (provided that, in such
        circumstances, it is on an aggregated basis that will not be linked back to you or any living
            person);</span></li>
        <li class="c5"><span class="c0">Send advertising, marketing (including in-product messaging) or information
            which may be useful to you; and</span></li>
        <li class="c10 c17"><span class="c4 c0">Send joint content and services to third parties (e,g social media
            providers), with whom you have a separate relationship. </span></li>
    </ul>
    <p class="c10"><span class="c4 c0">In addition to the commercial uses listed above, we may be required to provide
    government authorities with any and all of your Personal Information as necessary to comply with the law. We
    will retain information about you after you withdraw funds from the Site, close your account, if your
    application for an account is declined or if you decide not to proceed with the application to the extent
        required by Data Protection Laws or where we have a legitimate and lawful purpose for doing so.</span></p>
    <p class="c10"><span class="c4 c0">Our Site may contain technology that allowing us to:</span></p>
    <ul class="c8 lst-kix_list_4-0 start">
        <li class="c5"><span class="c0">Check particular information from your device or systems directly relevant to
        your use of the websites, applications or website against our records to ensure that the website
            services are used in accordance with our end-users agreement and to resolve any problems.</span></li>
        <li class="c5"><span class="c0">Obtain information regarding any technical errors or other problems with our
            Site;</span></li>
        <li class="c5"><span class="c0">Gather information about how you use our Site&#39;s features; and</span></li>
        <li class="c17 c18"><span class="c0">Collect statistical information about the operating system and environment
            from which you access our Site.</span></li>
    </ul>
    <h2 class="c13" id="h.3dy6vkm"><span class="c4 c9">5. How we collect information using cookies and other tracking
        technology</span></h2>
    <p class="c21"><span class="c11">A &ldquo;cookie&rdquo; is a string of information which assigns you a unique
    identifier that we store on your computer. Your browser then provides that unique identifier to use each
        time you submit a query to the Site</span><span>. </span><span class="c4 c0">We use cookies to provide
        personalized service, and to help facilitate your access to our Site. Our cookies may be; 1) temporary
        session cookies (cookies identifying and tracking users on our Site that are deleted when you close your
        browser or leave your application or service session; or, 2) Persistent cookies (cookies enabling our Site
        to &quot;remember&quot; who you are and remember your preferences on our Site and which will remain on your
        computer or device after you have closed your browser or leave your session in the applicable
        Service).</span></p>
    <p class="c21 c24"><span class="c4 c0"></span></p>
    <p class="c23"><span class="c0">We use different types of cookies as following:</span></p>
    <ul class="c8 lst-kix_list_1-0 start">
        <li class="c3"><span class="c0 c12">Strictly necessary cookies</span><span class="c0">&nbsp;- These cookies are
        necessary for our Site to function properly as these cookies allow you to access secure areas of our
            Site.</span></li>
        <li class="c5"><span class="c0 c12">Performance cookies and analytics technologies</span><span
            class="c0">&nbsp;- These non-identifying cookies gather statistics on how visitors interact with our
            website which features visitors use most frequently, and whether they receive error messages from
            websites, applications or Site areas.</span></li>
        <li class="c5"><span class="c0 c12">Functionality cookies</span><span class="c0">&nbsp;- These cookies enable
        our Site to remember choices you make (such as your username, language or region) and allow us to
            provide enhanced personalized features.</span></li>
        <li class="c5"><span class="c0 c12">Targeting or advertising cookies</span><span class="c0">&nbsp;&ndash; These
        cookies are used to deliver advertisements that are tailored to your interests. They are also used to
        limit the number of times you see an advertisement, as well as help measure an ad campaign&#39;s
            effectiveness. They are usually placed by advertising networks with our permission. </span></li>
        <li class="c5"><span class="c0 c12">Web beacons and parameter tracking</span><span class="c0">&nbsp;&ndash;
        These web beacons gather limited information that doesn&#39;t identify specific individuals. You
        can&#39;t refuse the use of web beacons. However, because they are used in conjunction with cookies, by
        setting your browser to restrict or block cookies, you can effectively disable these. We use cookies and
        web beacons to count users who visit our Site after clicking through from our advertisements on other
            websites or in emails. </span></li>
        <li class="c5"><span class="c0 c12">IP Address and traffic data</span><span class="c0">&nbsp;&ndash; We keep
        traffic data records that are automatically logged on our servers, such as your IP address, device
        information, the website you visited before our website and the website you visited after you left our
        Site. &nbsp;We also collect some statistics about site, application and service such as access rates,
        page hits and page views. We are unable to identify any individual from traffic data or statistics on
            the site.</span></li>
        <li class="c18 c17" id="h.1t3h5sf"><span class="c0 c12">Google Analytics</span><span class="c0">&nbsp;&ndash;
        Google Analytics uses cookies to assist the Site examine how our services are used by its visitors. The
        information that the cookie generates will be transmitted to and stored on servers in the United States
        by Google. To evaluate your use of the Site, Google will use this information by compiling reports on
        website activity for website operators and providing other reports on website activity and internet
        usage. Google may also transfer this information to third parties if required by law to do so, or if
        such third parties process the information on behalf of Google. Google will not associate your IP
        address with any other data held by Google. You may visit &nbsp;&ldquo;How Google uses data when you use
            our partners&rsquo; sites or apps,&rdquo; (located at </span><span class="c6"><a class="c15"
                href="https://www.google.com/url?q=https://idex.market/www.google.com/policies/privacy/partners&amp;sa=D&amp;ust=1595673556528000&amp;usg=AOvVaw2RBcBiKdG_P-yLjikG3KEv">www.google.com/policies/privacy/partners</a></span><span
                    class="c0">, or any other URL Google may provide from time to time) to find out more.</span></li>
    </ul>
    <h2 class="c13"><span class="c4 c9">6. How we use mobile data</span></h2>
    <p class="c10"><span class="c4 c0">We may obtain information for accessing and using our Site via mobile
    applications that you install on your mobile devices. These mobile applications may be our own, or belong to
    third parties. &nbsp;Where the mobile application belongs to a third party, you must read their privacy
    policy as it applies to your use of that mobile application. We are not responsible for such mobile
        applications by third party and for their use of your Personal Information.</span></p>
    <h2 class="c13" id="h.4d34og8"><span class="c4 c9">7. How we share your information</span></h2>
    <ul class="c8 lst-kix_list_2-0 start">
        <li class="c3"><span class="c0">We intend to maintain confidentiality of your data and information in accordance
        with our legal obligations. We may share your information with, where permitted by applicable law:
        </span></li>
        <li class="c3"><span class="c0">Our corporate family, affiliates and subsidiaries;</span></li>
        <li class="c5"><span class="c0">Our service providers and agents (including their subcontractors) or third
        parties that process information on our behalf (e.g. internet service providers and platform providers,
        payment processing providers and those organizations that we engage to help us send communications to
            you); </span></li>
        <li class="c5"><span class="c0">Partners including system implementers, resellers, value-added resellers,
            independent developers and software vendors; </span></li>
        <li class="c5"><span class="c0">Third parties used to facilitate payment transactions, such as clearing houses,
            clearing systems, financial institutions and transaction beneficiaries; </span></li>
        <li class="c5"><span class="c0">Third parties in which you have a relationship with that third party and have
        consented to send us information (such as social media websites or other third-party applications
            providers); </span></li>
        <li class="c5"><span class="c0">Third parties for marketing purposes;</span></li>
        <li class="c5"><span class="c0">Third party credit reference and fraud prevention agencies;</span></li>
        <li class="c5"><span class="c0">Third parties to enforce our legal rights and satisfy our legal obligations,
        including without limitation any reporting or disclosure obligations under applicable law or regulations
        or subpoena,, court order or other judicial or administrative process, or where we believe in good faith
        that disclosure is legally permissible or otherwise necessary to protect our rights and property, or the
            rights, property or safety of others;</span></li>
        <li class="c5"><span class="c0">Third parties to comply by the payment network rules, regulations and guidelines
            in which transactions are processed; </span></li>
        <li class="c5"><span class="c0">Our professional advisors and auditors for the purpose of seeking professional
            advice or to meet our audit responsibilities;</span></li>
        <li class="c5"><span class="c0">Third parties to transfer information and/or assets in the case of a joint
        venture, collaboration, financing, merger, acquisition, sale, bankruptcy filing, change of legal form or
            other restructuring of companies; </span></li>
        <li class="c5"><span class="c0">Another organization to whom we may transfer our agreement with you; and</span>
        </li>
    </ul>
    <p class="c10"><span class="c4 c0">&#9679; Third parties for any other purpose permitted by applicable law and
        regulation or agreed in this Privacy Policy or disclosed at the time the information is collected. </span>
    </p>
    <h2 class="c13"><span class="c4 c0">If you follow a link to another site or service from our Site, this Privacy
    Policy will no longer apply. We are not responsible for the information handling practices of third-party
    websites or Site and we encourage you to read the privacy notices that appear on those websites or Sites
    </span></h2>
    <h2 class="c13"><span class="c4 c9">8. Legal basis for processing in other jurisdictions</span></h2>
    <p class="c10"><span class="c0 c4">We collect your personal data for the following purposes In the other
        jurisdictions,</span></p>
    <ul class="c8 lst-kix_list_5-0 start">
        <li class="c16"><span class="c0">Where necessary to perform any contract we enter into, or have entered into,
            with you to provide services or access to our Site;</span></li>
        <li class="c16"><span class="c0">Where necessary for our legitimate business interests (or those of a third
            party) and your fundamental rights and interests do not override those interest;</span></li>
        <li class="c16"><span class="c0">Where we may need to comply with a legal or regulatory obligation.</span></li>
    </ul>
    <p class="c14"><span class="c2"></span></p>
    <p class="c10"><span class="c0">Please contact us at </span><span class="c0 c12">support@ondex.io </span><span
        class="c4 c0">If you require details about the specific legal basis that we are relying on to process your
        personal data.</span></p>
    <h2 class="c13" id="h.2s8eyo1"><span class="c4 c9">9. How we store and protect your information</span></h2>
    <p class="c10"><span class="c4 c0">By taking appropriate technical and organizational measures against its
    unauthorized or unlawful processing and its accidental loss, destruction or damage, we will endeavor to keep
    your information secure. We will take commercially reasonable steps to protect your Personal Information but
    we can not guarantee the security of your information that is transmitted via an internet or similar
        connection to our website or to other websites, applications and sites.</span></p>
    <p class="c10"><span class="c4 c0">We store personal data for individuals based in the EU or Switzerland for as long
    as is necessary to fulfil the purposes for which we collect the data (see &quot;Information we collect and
    for what purpose&quot; above), except if required otherwise by Data Protection Laws. Please note that we may
    transfer information, including Personal Information, to a country and jurisdiction which does not have the
    same Data Protection Laws as your jurisdiction and you consent to the transfer of information to Singapore
    or to any other country in which we or our parents, subsidiaries, affiliates or service providers maintain
        facilities and the use and disclosure of information about you as described in this Privacy Policy.</span>
    </p>
    <p class="c10"><span class="c0">If you believe your account has been compromised, please contact us at </span><span
        class="c0 c12">support@ondex.io</span><span class="c4 c0">. </span></p>
    <h2 class="c13" id="h.17dp8vu"><span class="c4 c9">10. Your choices</span></h2>
    <h3 class="c13" id="h.3rdcrjn"><span class="c7">a. Personal Information</span></h3>
    <p class="c10"><span class="c4 c0">You can give us instructions regarding what to do with your Personal Information.
    Users have their Personal Information choices and control. We may have to close your account and/or block
    your access to the Site if we need to collect Personal Information and you fail to provide that data when
    requested. Please carefully review your option because your choices and instructions may result in loss of
        access to the Site and services &nbsp; &nbsp;</span></p>
    <h3 class="c13" id="h.26in1rg"><span class="c7">b. Other information</span></h3>
    <h3 class="c13"><span class="c4 c0">Please contact us at support@ondex.io if you have any questions about your
    Personal Information, our use of this Personal Information, or your rights regarding any of the foregoing.
    </span></h3>
    <h3 class="c13"><span class="c7">c. Your rights in respect to your Personal Information if you are located in the EU
        or Switzerland</span></h3>
    <p class="c10"><span class="c4 c0">If you are located in the EU or Switzerland, you have the following rights in
        respect to the Personal Information that we hold:</span></p>
    <ul class="c8 lst-kix_list_3-0 start">
        <li class="c3"><span class="c0">Right of access &ndash; the right to obtain access to your personal data;</span>
        </li>
        <li class="c5"><span class="c0">Right to rectification &ndash; the right to obtain rectification without undue
            delay of your Personal Information where the data is inaccurate or incomplete;</span></li>
        <li class="c5"><span class="c0">Right of removal &ndash; the right to obtain, without undue delay, the removal
        of your Personal Information in certain circumstances, such as where the data are no longer necessary
            for the purposes for which it was collected or processed;</span></li>
        <li class="c5"><span class="c0">Right to restriction &ndash; the right to restrict the data processing of your
        Personal Information undertaken by us in certain circumstances, such as where you contest the accuracy
            of your Personal Data, for a period in which we can verify the accuracy of that Personal Data; </span>
        </li>
        <li class="c5"><span class="c0">Right to Movability &ndash; the right to movability allows you to move, copy or
            transfer Personal Information easily from one organization to another; and,</span></li>
        <li class="c5"><span class="c0">Right to object &ndash; you have a right to object to data processing based on
            legitimate interests and direct marketing.</span></li>
        <li class="c18 c17"><span class="c0">Right to withdraw consent &ndash; You have the right to cancel your consent
        to our data processing. If you exercise this right, we may not be in a position to offer you
            services.</span></li>
    </ul>
    <p class="c10"><span class="c0">Please contact us at </span><span class="c0 c12">support@ondex.io</span><span
        class="c0">&nbsp;if you wish to exercise one of these rights and we will respond within 30 days of the
        request. If you request access to your personal data, we will provide you with all the information, in
        addition to the data use context, by email. If you request removal of your personal data, we will respond
        with a confirmation of erasure by email within 30 days of the request. If your request is deemed unfounded
        and/or excessive</span><span>, </span><span class="c4 c0">we retain the right under the GDPR to refuse to
        respond to the request or charge a reasonable fee if your request is clearly unfounded, repeated or
        excessive. If we refuse to respond, or charge a reasonable fee, we will give our reasons. If you are not
        satisfied with the reasons, you may complain to the relevant supervisory authority, the Information
        Commissioner&#39;s Office.</span></p>
    <p class="c10"><span class="c0">You can lodge a complaint to your local data protection authority If you feel your
    Personal Information has been inappropriately handled. Further information about how to contact your local
        data protection authority is available at </span><span class="c6"><a class="c15"
            href="https://www.google.com/url?q=http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm&amp;sa=D&amp;ust=1595673556532000&amp;usg=AOvVaw2g7v17YBoOmTThmkS4zXlr">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a></span><span
                class="c4 c0">.</span></p>
    <h3 class="c13" id="h.lnxbz9"><span class="c7">d. Other Choices</span></h3>
    <p class="c10"><span class="c4 c0">Additionally, the browser you are using may provide the ability to control
    cookies or other local data storage types. We do not control these choices, or default settings, which are
        offered by makers of your browser.</span></p>
    <h2 class="c13" id="h.35nkun2"><span class="c4 c9">11. Changes to our privacy policy</span></h2>
    <p class="c10"><span class="c4 c0">We may change our Privacy Policy from time to time. We will update this Privacy
    Policy on our website, so please try to read it when you visit the website (the &lsquo;last modified&rsquo;
        reference tells you when we last updated our Privacy Policy).</span></p>
    <h2 class="c13" id="h.1ksv4uv"><span class="c4 c9">12. Further Information</span></h2>
    <p class="c25"><span class="c0">Please email us at </span><span class="c0 c12">support@ondex.io</span><span
        class="c0">, subject line: &quot;Data Protection Officer&quot; in the subject header if you have any
        queries about how we treat your information, the contents of this notice, your rights under local law, how
        to update your records or how to obtain a copy of the information we hold about you.</span></p>
    <p class="c21 c24"><span class="c2"></span></p>
    <p class="c21 c24"><span class="c2"></span></p>
    <p class="c21 c24"><span class="c4 c9"></span></p>
    <p class="c21 c24"><span class="c2"></span></p>
</div>
