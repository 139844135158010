import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { themeDimensions } from '../../themes/commons';

import { CardBase } from './card_base';

interface Props extends HTMLAttributes<HTMLDivElement> {
    title?: string;
    action?: React.ReactNode;
    children: React.ReactNode;
    minHeightBody?: string;
    heightBody?: string;
    paddingBody?: string;
    titleUp?: boolean;
}

const CardWrapper = styled(CardBase)`
    display: flex;
    flex-direction: column;
    margin-bottom: ${themeDimensions.verticalSeparationSm};
    max-height: 100%;
    border: none;
    &:last-child {
        margin-bottom: 0;
    }
`;

const CardHeader = styled.div`
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.componentsTheme.cardBorderColor};
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: space-between;
    padding: ${themeDimensions.verticalPadding} ${themeDimensions.horizontalPadding};
    background: ${props => props.theme.componentsTheme.cardBackgroundColor};
`;

const CardTitle = styled.h1 <{ upcase: boolean }>`
    color: ${props => props.theme.componentsTheme.aColor};
    /* font-family: "Nunito Sans"; */
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 24px;
    padding: 0;
    margin: 0;
    text-transform: ${props => props.upcase === true ? 'uppercase' : 'normal'};
    font-weight: bolder;
`;

const CardBody = styled.div<{ minHeightBody?: string, heightBody?: string, paddingBody?: string }>`
    margin: 0;
    min-height: ${props => props.minHeightBody};
    overflow-x: auto;
    padding: ${props => props.paddingBody ? props.paddingBody : `${themeDimensions.verticalPadding} ${themeDimensions.horizontalPadding}`} ;
    position: relative;
    height: ${props => props.heightBody ? props.heightBody : 'auto'};
    background-color: ${props => props.theme.componentsTheme.cardBackgroundColor};
`;

CardBody.defaultProps = {
    minHeightBody: '140px',
};

export const Card: React.FC<Props> = props => {
    const { title, titleUp, action, children, minHeightBody, heightBody, paddingBody, ...restProps } = props;
    const upcase = titleUp ? titleUp : false;
    return (
        <CardWrapper {...restProps}>
            {title || action ? (
                <CardHeader>
                    <CardTitle upcase={upcase}>{title}</CardTitle>
                    {action ? action : null}
                </CardHeader>
            ) : null}
            <CardBody paddingBody={paddingBody} minHeightBody={minHeightBody} heightBody={heightBody}>{children}</CardBody>
        </CardWrapper>
    );
};
