import React, { HTMLAttributes } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { getEthAccount, getThemeUI, getWeb3State } from '../../../store/selectors';
import { StoreState } from '../../../util/types';
import { initWallet } from '../../../store/actions';
import { themeBreakPoints, themeDimensions } from '../../../themes/commons';
import { ERC20_APP_BASE_PATH, LIGHT_THEME } from '../../../common/constants';

const Features = `#${ERC20_APP_BASE_PATH}/features/decentralized-exchange-benefits`;

const no_signup = require('../../../assets/images/no_signup_white.svg');
const no_signup_dark = require('../../../assets/images/no_signup_white.svg');

const truly_free_light = require('../../../assets/images/truly_free_light.svg');
const truly_free_dark = require('../../../assets/images/truly_free_dark.svg');

const security_trading_light = require('../../../assets/images/security_trading_light.svg');
const security_trading_dark = require('../../../assets/images/security_trading_dark.svg');

const industry_leading_light = require('../../../assets/images/industry_leading_light.svg');
const industry_leading_dark = require('../../../assets/images/industry_leading_dark.svg');

const key_light = require('../../../assets/images/key-icon-light.svg');
const key_dark = require('../../../assets/images/key-icon-dark.svg');

const walletLight = require('../../../assets/images/wallet-light.svg');
const I18n = require('i18n-js');

interface OwnProps extends HTMLAttributes<HTMLSpanElement> {
}

interface StateProps {
    ethAccount: string;
    web3State: string;
    theme: string;
}

interface DispatchProps {
    onConnectWallet: () => any;
}

type Props = StateProps & DispatchProps;

const News = styled.div`
    text-align: center;
    margin: 0;
    color: ${props => props.theme.componentsTheme.textColorCommon};
    padding: 10px 5%;
    max-width: ${themeBreakPoints.xxl};
    margin: 0 auto;
`;
const H1 = styled.h1`
    font-size: 36px;
    margin: 0 0 50px 0;
    text-transform: uppercase;
    line-height: 49px;
    letter-spacing: 2px;
    font-weight: 300;
    color: ${props => props.theme.componentsTheme.cardTitleColor};
`;

const P = styled.p`
    font-size: 18px;
    padding: 0 20px;
    margin: 0;
    color: #546497;
    @media (max-width: ${themeBreakPoints.lg}) {
        padding: 0 10px;

    }
`;
const How = styled.span`
    color: #1068B4;
`;
const Ul = styled.ul`
    display: flex;
    justify-content: space-between;
    margin: 0;
    color: #1068B4;
    @media (max-width: ${themeBreakPoints.md}) {
        display: block;
    }
`;
const Li = styled.li`
    background: ${props => props.theme.componentsTheme.cardBackgroundColor};
    box-shadow: ${props => props.theme.componentsTheme.boxShadow};
    width: 200px;
    height: 250px;
    margin: 0 5px 20px 5px;
    border-radius: ${themeDimensions.borderRadius}
    @media (max-width: ${themeBreakPoints.md}) {
        width: 100%;
        margin: 0 0 20px 0;
    }
    @media (min-width: ${themeBreakPoints.lg}) {
        width: 25%;
        margin: 0 15px 0 15px;
    }
    transition: transform .2s;
    cursor: pointer;
    &:hover {
        transform: scale(1.02);
        opacity: 1;
        /* transform: rotateX(180deg); */
    }
`;
const Img = styled.img`
    font-size: 150px;
    margin: 30px 0;
`;
const Link = styled.a`
    display: block;
`;

class App extends React.Component<Props> {

    public render = () => {
        const { theme } = this.props;
        return (
            <News>
                <H1><How>{I18n.t('services')}</How> <span>{I18n.t('forEveryone')}</span></H1>
                <Ul>
                    <Li>
                        <Link href={Features}>
                            <Img src={theme === LIGHT_THEME ? key_light : key_dark} />
                            <P>{I18n.t('yourKeysCrypto')}</P>
                        </Link>
                    </Li>
                    <Li>
                        <Link href={Features}>
                            <Img src={theme === LIGHT_THEME ? truly_free_light : truly_free_dark} />
                            <P>{I18n.t('noHiddenCosts')}</P>
                        </Link>
                    </Li>
                    <Li>
                        <Link href={Features}>
                            <Img src={theme === LIGHT_THEME ? security_trading_light : security_trading_dark} />
                            <P>{I18n.t('secureTrading')}</P>
                        </Link>
                    </Li>
                    <Li>
                        <Link href={Features}>
                            <Img src={theme === LIGHT_THEME ? industry_leading_light : industry_leading_dark} />
                            <P>{I18n.t('industryLeading')}</P>
                        </Link>
                    </Li>
                </Ul>
            </News>
        );
    };
}

const mapStateToProps = (state: StoreState): StateProps => {
    return {
        ethAccount: getEthAccount(state),
        web3State: getWeb3State(state),
        theme: getThemeUI(state),
    };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
    return {
        onConnectWallet: () => dispatch(initWallet()),
    };
};

const SeviceEveryone = connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);

export { SeviceEveryone };
