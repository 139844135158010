import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { themeDimensions } from '../../themes/commons';

import { LockIcon } from './icons/lock_icon';
import { MetamaskSideIcon } from './icons/metamask_side_icon';
import { SadIcon } from './icons/sad_icon';
import { WarningIcon } from './icons/warning_icon';
import { InfoIcon } from './icons/info_icon';

interface Props extends HTMLAttributes<HTMLDivElement>, SuccessProps {
    text: string;
}

interface SuccessProps {
    fontSize?: FontSize;
    icon?: SuccessIcons;
    textAlign?: string;
}

export enum SuccessIcons {
    Lock = 1,
    Sad = 2,
    Metamask = 3,
    Warning = 4,
    Info = 5,
}

export enum FontSize {
    Large = 1,
    Medium = 2,
}

const SuccessCardContainer = styled.div<SuccessProps>`
    align-items: center;
    background-color: ${props => props.theme.componentsTheme.successCardBackground};
    border-radius: ${themeDimensions.borderRadius};
    border: 1px solid ${props => props.theme.componentsTheme.successCardBorder};
    color: ${props => props.theme.componentsTheme.successCardText};
    display: flex;
    font-size: ${props => (props.fontSize === FontSize.Large ? '16px' : '14px')};
    line-height: 1.2;
    padding: 10px 15px;
    font-weight:bold;
    ${props => (props.textAlign === 'center' ? 'justify-content: center;' : '')}
`;

const IconContainer = styled.span`
    margin-right: 10px;
`;

const getIcon = (icon: SuccessIcons) => {
    let theIcon: any;

    if (icon === SuccessIcons.Lock) {
        theIcon = <LockIcon />;
    }
    if (icon === SuccessIcons.Metamask) {
        theIcon = <MetamaskSideIcon />;
    }
    if (icon === SuccessIcons.Sad) {
        theIcon = <SadIcon />;
    }
    if (icon === SuccessIcons.Warning) {
        theIcon = <WarningIcon />;
    }
    if (icon === SuccessIcons.Info) {
        theIcon = <InfoIcon />;
    }
    return <IconContainer>{theIcon}</IconContainer>;
};

export const SuccessCard: React.FC<Props> = props => {
    const { text, icon, ...restProps } = props;
    const successIcon = icon ? getIcon(icon) : null;

    return (
        <SuccessCardContainer {...restProps}>
            {successIcon}
            {text}
        </SuccessCardContainer>
    );
};
