import React from 'react';
import Chart from "react-apexcharts";
import styled from 'styled-components';
import { fixBigNumber } from '../../../util/number_utils';
import { UI_DECIMALS_DISPLAYED_PRICE_ETH } from '../../../common/constants';
import { BigNumber } from '@0x/utils';

const I18n = require('i18n-js');
const TexColor = styled.div`
    color: ${props => props.theme.componentsTheme.textColorCommon};
    font-size: 10px;
`
const height = '330px';

interface Props {
    series: Array<any>,
}

export const DepthChart: React.FC<Props> = props => {
    const options = {
        chart: {
            width: '100%',
            background: 'transparent',
            zoom: { enabled: true },
            foreColor: TexColor,
            toolbar: {
                show: true,
                tools: {
                    download: false,
                    zoomin: `<div title="${I18n.t('zoomIn')}"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0z" fill="none"></path><path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg></div>`,
                    zoomout: `<div title="${I18n.t('zoomOut')}"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0z" fill="none"></path> <path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg></div>`,
                    zoom: `<div title="${I18n.t('selectionZoom')}"><svg xmlns="http://www.w3.org/2000/svg" fill="#000000" height="24" viewBox="0 0 24 24" width="24">
                    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M12 10h-2v2H9v-2H7V9h2V7h1v2h2v1z"></path></svg></div>`,
                    pan: `<div title="${I18n.t('panning')}"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" height="24" viewBox="0 0 24 24" width="24"> <defs> <path d="M0 0h24v24H0z" id="a"></path> </defs> <clipPath id="b"> <use overflow="visible" xlink:href="#a"></use> </clipPath> <path clip-path="url(#b)" d="M23 5.5V20c0 2.2-1.8 4-4 4h-7.3c-1.08 0-2.1-.43-2.85-1.19L1 14.83s1.26-1.23 1.3-1.25c.22-.19.49-.29.79-.29.22 0 .42.06.6.16.04.01 4.31 2.46 4.31 2.46V4c0-.83.67-1.5 1.5-1.5S11 3.17 11 4v7h1V1.5c0-.83.67-1.5 1.5-1.5S15 .67 15 1.5V11h1V2.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5V11h1V5.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5z"></path></svg></div>`,
                    reset: `<div title="${I18n.t('resetZoom')}"><svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"> <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path> <path d="M0 0h24v24H0z" fill="none"></path> </svg></div>`
                },
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },

        title: {
            // text: 'Bid & Ask',
            align: 'left',
            style: {
                fontSize: '14px'
            }
        },
        colors: ['#3CB34F', '#FF6534'],
        xaxis: {
            // type: 'datetime',
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            labels: {
                formatter: (value: any) => {
                    const _value = new BigNumber(value).toFixed(UI_DECIMALS_DISPLAYED_PRICE_ETH);
                    return fixBigNumber(_value);
                },
                style: {
                    fontSize: '11px'
                },
                hideOverlappingLabels: true,
                // trim: true,
                rotate: 0,
            }
        },
        yaxis: {
            show: true,
            labels: {
                show: true,
                formatter: (value: any) => {
                    // const _value = new BigNumber(value).toFixed(UI_DECIMALS_DISPLAYED_PRICE_ETH);
                    if (!value) return 0;
                    return +value.toString();
                },
                style: {
                    fontSize: '11px'
                }
            }
        },
        fill: {
            opacity: 0.5,
        },
        tooltip: {
            x: {
                //   format: "yyyy",
            },
            fixed: {
                enabled: false,
                position: 'topRight'
            }
        },
        grid: {
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: false
                }
            },
        },
        range: 0.01
    }
    const { series } = props;
    return (
        <Chart options={options} series={series} type="area" height={height} />
    )
}
