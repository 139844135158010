import socketIO from 'socket.io-client';
import { SOCKET } from '../common/constants';
import { ThunkCreator } from '../util/types';
import { getTradeHistoryFromAPI, getUserHistoryFromAPI } from '../store/ui/actions';
import { getUserOrders } from '../store/relayer/actions';
// import { ordersToUIOrders } from '../util/ui_orders';
// import { getBaseToken, getQuoteToken } from '../store/selectors';
import { getAllOrders } from '../store/relayer/actions';
const SUBSCRIBE = 'SUBSCRIBE';
const UNSUBSCRIBE = 'UNSUBSCRIBE';
const SUBSCRIBE_ERROR = 'SUBSCRIBE_ERROR';

interface SocketData {
    name: string;
    pair: string;
    data: any
}
let socket: any;

class Socket {

    // public  socket: any;

    public  initSocket() {
        socket = socketIO(SOCKET, {
            multiplex: false,
            forceNew: true,
            transports: ['websocket']
            // rejectUnauthorized: false,
            // agent: false,
            // upgrade: false,
        });
    }

    public OnPairSubcribe = (base: string, quote: string, address: string) => (dispatch: any) => {
        // if (!base || !quote || !address) return;
        // this.initSocket();
        let pair = `${base}_${quote}`
        socket.on(pair, (respones: SocketData) => {
            if (respones) {
                dispatch(this.EventProcess(base, quote, respones));
            }
        });
        pair = `${quote}_${base}`;
        socket.on(pair, (respones: SocketData) => {
            if (respones) {
                dispatch(this.EventProcess(base, quote, respones));
            }
        });
        socket.on(SUBSCRIBE_ERROR, (data: any) => {
        });
        socket.on('connect', () => {
            this.subscribeHandle(address);
        });
    }

    public listenSocketUserHistory = (address: string) => (dispatch: any) => {
        if (!address) return;
        //this.initSocket();
        //this.subscribeHandle(address);
        socket.on(address.toLowerCase(), (respones: any) => {
            if (respones) {
                dispatch(getUserHistoryFromAPI(1, 10))
            }
        });
        socket.on('connect', () => {
            this.subscribeHandle(address);
        });
    }

    public EventProcess: ThunkCreator = (base: string, quote: string, respones: SocketData) => (dispatch: any) => {
        if (respones) {
            const { name } = respones;
            // const state = getState();
            // const baseToken = getBaseToken(state) as Token;
            // const quoteToken = getQuoteToken(state) as Token;
            // const _data = ordersToUIOrders([data], baseToken);
            switch (name) {
                case "orderBook":
                case "expiredTime":
                    return dispatch(getAllOrders());
                case "tradeHistory":
                    dispatch(getAllOrders());
                    // return dispatch(addTradeHistory(data));
                    dispatch(getUserHistoryFromAPI(1, 10, base, quote))
                    return dispatch(getTradeHistoryFromAPI(base, quote));
                case "openOrder":
                    dispatch(getAllOrders());
                    return dispatch(getUserOrders());
            }
        }
    }

    public subscribeHandle(address: string) {
        socket.emit(UNSUBSCRIBE);
        socket.emit(SUBSCRIBE, { address: address });
    }

}

export { Socket };
