import React from 'react';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';

import { ReactComponent as LogoSvg } from '../../../assets/icons/erc20_logo.svg';
import { Config } from '../../../common/config';
import { DARK_THEME, ERC20_APP_BASE_PATH, UI_GENERAL_TITLE } from '../../../common/constants';
import { Logo } from '../../common/logo';
import { ToolbarContainer } from '../../common/toolbar';
import { NotificationsDropdownContainer } from '../../notifications/notifications_dropdown';
import { goToHome, goToWallet } from '../../../store/actions';
import { Theme, themeBreakPoints, themeDimensions } from '../../../themes/commons';
import { WalletConnectionContentContainer } from '../account/wallet_connection_content';
// import LanguageDropdown from './languages_dropdown';
import { MarketsDropdownContainer } from './markets_dropdown';
import { getCurrentRoutePath, getLanguage, getThemeUI } from '../../../store/selectors';
import { StoreState } from '../../../util/types';
import { isMobileOnly } from 'react-device-detect';
import { SupportDropdown } from './support_dropdown';
import { MbDropdown } from './mb_dropdown';
import Setting from './settings_theme';

const COMMINGSOON = `#${ERC20_APP_BASE_PATH}/comming-soon`;
const EARN = `#${ERC20_APP_BASE_PATH}/earn`;

const I18n = require('i18n-js');

interface StateProps {
    path: string;
    language: string;
    themes: string;
}

interface DispatchProps {
    onGoToHome: () => any;
    onGoToWallet: () => any;
}

interface OwnProps {
    theme: Theme;
}

type Props = DispatchProps & OwnProps;

const LogoHeader = styled(Logo)`
    height: ${themeDimensions.toolbarHeight};
    /* border-right: 1px solid ${props => props.theme.componentsTheme.borderColorMenuTop}; */
    padding: 0 30px 0 0;
    cursor: pointer;
`;

const LogoSVGStyled = styled(LogoSvg)`
    path {
        fill: ${props => props.theme.componentsTheme.logoERC20Color};
    }
`;

const ImgLogo = styled.img`
    width: 100px;
`;

const MarketsDropdownHeader = styled<any>(MarketsDropdownContainer)`
    /* border-right: 1px solid ${props => props.theme.componentsTheme.borderColorMenuTop}; */
    cursor: pointer;
`;

const WalletDropdown = styled(WalletConnectionContentContainer)`
    /* border-right: 1px solid ${props => props.theme.componentsTheme.borderColorMenuTop}; */
    padding: 0 10px 0 10px;
    cursor: pointer;
    height: ${themeDimensions.toolbarHeight};
    margin: 0;
    /* border-left: 1px solid ${props => props.theme.componentsTheme.borderColorMenuTop}; */
    align-items: center;
    display: flex;
    @media (max-width: ${themeBreakPoints.md}) {
        border-left: none;
        padding: 0;
    }
    @media (min-width: ${themeBreakPoints.md}) {
    }
`;

const Earn = styled.a`
    text-transform: uppercase;
    margin: 0 0 0 20px;
    color: ${props => props.theme.componentsTheme.textColorCommon}; 
`;

const ToolbarContent = (props: Props & StateProps) => {
    const handleLogoClick: React.EventHandler<React.MouseEvent> = e => {
        e.preventDefault();
        props.onGoToHome();
    };
    const generalConfig = Config.getConfig().general;
    const href = window.location.hash || '';
    const { themes } = props;
    const logoDark = require('../../../assets/images/logo-white.png');
    const logoWhite = require('../../../assets/images/logo-color.png');
    const isMarketPage = href.indexOf('/market?') > -1;
    let logeFile = themes === DARK_THEME ? logoDark  : logoWhite;
    logeFile = isMarketPage ? logeFile : logoWhite;
    const logo = generalConfig && generalConfig.icon ? <ImgLogo src={logeFile} /> : <LogoSVGStyled />;
    const startContent = (
        <>
            <LogoHeader
                image={logo}
                onClick={handleLogoClick}
                text={(generalConfig && generalConfig.title) || UI_GENERAL_TITLE}
                textColor={props.theme.componentsTheme.logoERC20TextColor}
            />
            {<MarketsDropdownHeader shouldCloseDropdownBodyOnClick={false} />}
            {/*{!isMarketPage && <Earn href={EARN}>{I18n.t('earn')}</Earn>}*/}
            {/* <SettingDropdowns /> */}
        </>
    );

    // const handleMyWalletClick: React.EventHandler<React.MouseEvent> = e => {
    //     e.preventDefault();
    //     props.onGoToWallet();
    // };
    const endContent = (
        <>
            {!isMobileOnly && <SupportDropdown />}
            {!isMobileOnly && <WalletDropdown />}
            {!isMobileOnly && <NotificationsDropdownContainer />}
            {isMobileOnly && <MbDropdown />}
        </>
    );
    const centerContent = isMarketPage ? <Setting /> : null;
    return <ToolbarContainer startContent={startContent} centerContent={centerContent} endContent={endContent} />;
};

const mapStateToProps = (state: StoreState): StateProps => {
    return {
        path: getCurrentRoutePath(state),
        language: getLanguage(state),
        themes: getThemeUI(state),
    };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
    return {
        onGoToHome: () => dispatch(goToHome()),
        onGoToWallet: () => dispatch(goToWallet()),
    };
};

const ToolbarContentContainer = withTheme(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ToolbarContent),
);

export { ToolbarContent, ToolbarContentContainer };
