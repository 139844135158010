
import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';

const Wrap = styled.div``
const Faqs = styled.div``
const Question = styled.span``
const Answer = styled.div`
    background: #ddd9d9;
    padding: 10px;
    margin: 0;
    width: 100%
`

export const jp =
    <Wrap>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>1. ONDEXとは </Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>Wowooエコシステムによって構築された分散型取引所で、ユーザーが自信を持って取引できるようにし、資金と個人データを完全に制御できるようにします。これは、中本聡が最初にビットコインブロックチ ェーンを構築したときに想定していた方法と同じです。これは、世界中のトレーダーを接続する0xプロトコル の上に構築されています。 トレーダーは、最も安全でシームレスな方法でETHまたはERC20トークンを交換で きます。 ユーザーは秘密鍵を保持し、ウォレットから直接取引できます。 現在、DEXはMetamaskとInfinitoウ ォレットをサポートしています。 機能の数は増え続け、近い将来セキュリティトークンを統合する可能性があります。
                </Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>2．中央集権型取引所と分散型取引所の違いとは </Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div>
                    <Answer>中央集権型取引所は、従来の証券会社や株式市場と同じように機能する取引プラットフォームです。 一元化された暗号交換は、すべてのトランザクションを完全に制御する会社や団体が所有および運営しています。
                    </Answer>
                    <Answer>一元化された暗号交換ユーザーは、自分の交換アカウントのウォレットにある秘密鍵にアクセスできません。 取引は中央当局によって提供および承認されたメカニズムを介してのみ行うことができるため、これにより、 取引所オペレーターの手でユーザーのすべての信頼が得られます。
                    </Answer>
                    <Answer>ONDEXなどの分散型取引所は、暗号通貨のようなブロックチェーン（分散型台帳）テクノロジーを使用してい ます。 このように、分散型取引所は顧客の資金、ポジション、または情報を保持せず、取引注文のマッチング およびルーティング層としてのみ機能します。
                    </Answer>
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>3．メタマスクとは </Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>MetaMaskは、今日のブラウザーで明日の分散Webにアクセスできるようにするブリッジです。 これにより、 完全なEthereumノードを実行しなくても、ブラウザーで直接Ethereum dAppを実行して、Ethereumをできるだけ多くの人々が使いやすくすることができます。
                </Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>4．ONDEX上での取引手数料について </Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>ONDEXは、マーケットテイカーに0.2％、マーケットメーカーに0.1％を課金します。 ユーザーはまた別途トランザクションをブロックチェーンに置くためにガス手数料を支払う必要があります。
                </Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>5．マーケットメーカーとテイカーとは </Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>マーケットメーカーは取引場に新しい注文を出し、別のユーザーがそれらを受注するのを待ちます。 彼らは買い手または売り手のいずれにもなることができます。 マーケットテイカーは、取引場にある既存の注文を見つけて受注することで、本から注文を確定します。
                </Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>6．ガス手数料とは </Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>ガス手数料とは、Ethereumブロックチェーンプラットフォーム上において、トランザクションを正常に実行し たり、契約を締結したりするために必要な手数料のことです。
                </Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>7．取引を成功させるために必要なガス手数料 </Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>ユーザーは、取引を利用するために必要なすべてのガス手数料をカバーする責任があります。 デポジットガス手数料はユーザーから直接支払われます。 ONDEXは、他のすべてのトランザクションと引き出しに対してガス 料金をネットワークに支払い、この料金をユーザーのトランザクションから差し引きます。これには、取り引きのためにテイカーが支払うガス料金と、引き出しのために顧客が支払うガス料金の両方が含まれます。 手数 料は、取引されている資産から引き落とされ、取引および引き出しのために引き出されます。 トークンを引き 出す場合、料金の金額は、過去10回の取引のトークン/ ETH為替レートを使用して計算されます。
                </Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        <Faqs>
            <Question></Question>

        </Faqs>
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>8．ONDEXに預けている資産は安全かどうか </Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div>
                    <Answer>はい、ONDEXウォレット内に保持されているすべてのデジタル資金は、常にユーザーの管理下にあるため安全です。あなたが最初に設定する暗号署名による許可がない限り、ONDEXはあなたの資産を取引、引き出し、またはその他の移動を行うことができません。ONDEXキーは引き出しトランザクションの送信を許可されている唯一のキーであるため、非アクティブ期間の後にユーザーが契約から直接引き出しできるようにするエスケープハッチが含まれています。 これにより、ONDEXサーバーが利用できない場合でも、ユーザーは契約から資金を引き出すことができます。</Answer>
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        {/* <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>9．WWXトークンとは </Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>WWXトークンはユーティリティトークンです。 ユーザーはWWXトークンを使用して取引手数料を支払うことで、大幅な割引等の特典を受けることができます。</Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel> */}
        <ExpansionPanel>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={'d'}>
                    <Question>9．ONDEXにはセキュリティートークンの上場はされるか </Question>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Answer>ONDEXは現在、ST20標準のセキュリティトークンの上場をサポートしています。
                </Answer>
            </ExpansionPanelDetails>
        </ExpansionPanel>

    </Wrap>
