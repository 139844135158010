import React from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import { getMarkets } from '../../../store/selectors';
import { CurrencyPair, Market, StoreState, Filter } from '../../../util/types';
import { changeMarket, goToMarketErc20 } from '../../../store/actions';
const I18n = require('i18n-js');

interface PropsAction {
    changeMarket: (currencyPair: CurrencyPair) => any;
    goToMarketErc20: () => any,
}

interface Props {
    markets: Market[] | null;
}
interface State {
    selectedFilter: Filter;
    search: string;
}

const Content = styled.div`
    margin: 0 auto;
    width: 90%;
`;

const News = styled.div`
    text-align: center;
    margin: 100px auto;
    color: ${props => props.theme.componentsTheme.myWalletLinkColor};
`

const H1 = styled.h1`
`

class Dashboard extends React.Component<Props & PropsAction, State> {

    public render = () => {
        return (
            <Content>
                <News>
                    <H1>{I18n.t('aboutUs')}</H1>
                </News>
            </Content>
        );
    };
}

const mapStateToProps = (state: StoreState): Props => {
    return {
        markets: getMarkets(state),
    };
};

const mapDispatchToProps = (dispatch: any): PropsAction => {
    return {
        changeMarket: (currencyPair: CurrencyPair) => dispatch(changeMarket(currencyPair)),
        goToMarketErc20: () => dispatch(goToMarketErc20()),
    };
};

const DashboardApp = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Dashboard);
export default DashboardApp;
