import React from 'react';
import { connect } from 'react-redux';
import { getLanguage } from '../../../store/selectors';
import { StoreState } from '../../../util/types';
import styled from 'styled-components';
import { en } from './fees/en';
import { vi } from './fees/vi';
import { jp } from './fees/jp';
const I18n = require('i18n-js');

interface Props {
    language: string
}


const Wrap = styled.div`
    margin: 20px 5%;
    text-align: left;
    color: ${props => props.theme.componentsTheme.textColorCommon};
    min-height: 500px;
`

const Head = styled.h1`
    text-align: center;
`

class _App extends React.Component<Props> {

    public render = () => {
        let lang = en;
        switch (this.props.language) {
            case 'ja':
                lang = jp;
                break;
            case 'vi':
                lang = vi;
                break;
        }
        return (
            <Wrap>
                <Head>{I18n.t('fees')}</Head>
                {lang}
            </Wrap>

        );
    };
}

const mapStateToProps = (state: StoreState): Props => {
    return {
        language: getLanguage(state)
    };
};

const App = connect(
    mapStateToProps,
    null,
)(_App);
export default App;
